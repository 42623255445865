import * as React from "react";
import { Redirect } from "react-router-dom";
import { LoginApi } from "./api/LoginApi";
import { TokenUpdateResult } from "../library/openapi/models";
import { ApiResponse } from "./api/runtime";
import { Token } from "./Token";
import { User } from "./User";

type Props = {
  children: React.ReactElement;
};
const loginApi = new LoginApi();

const Auth: React.FC<Props> = (props) => {
  // if (Token.instance.token) {
    
    // console.log("変更前" + Token.instance.token);

  //   loginApi
  //     .updateTokenRaw()
  //     .then((result: ApiResponse<TokenUpdateResult>) => {
  //       result.value().then((value: TokenUpdateResult) => {
  //         //token再格納する場合はCO外す
  //         Token.instance.token = value.accessToken;
  //       });
  //     })
  //     .catch((error) => {
  //       //401が帰ってきたらtokenの認証（最長12H）が切れてるか、何かしらのエラーなのでloginへ戻る

  //       if (User.getInstance.getUserId() || User.getInstance.getUserName()) {
  //         User.getInstance.deleteUserInfo();
  //       }

  //       if (Token.instance.token || Token.instance.updateToken) {
  //         Token.instance.deleteToken();
  //         Token.instance.deleteUpdateToken();
  //       }

  //       console.log("認証失敗、または認証期限切れの為ログアウト");

  //       return <Redirect to={"/login"} />;
  //     });
  // }

  if (Token.instance.token) {
    return props.children;
  } else {
    return <Redirect to={"/login"} />
  }

  // Token.instance.token ? props.children : <Redirect to={'/login'} />;
};

export default Auth;
