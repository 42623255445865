export class Token {
  private static singleton: Token;
  private constructor() {}

  public static get instance() {
    if (this.singleton === undefined) {
      this.singleton = new Token();
    }

    return this.singleton;
  }

  public set token(token: string | null) {
    if (token !== null) {
      sessionStorage.setItem("token", token);
    } else {
      sessionStorage.removeItem("token");
    }
  }

  public get token() {
    return sessionStorage.getItem("token");
  }

  public deleteToken() {
    return sessionStorage.removeItem("token");
  }

  //   =======updateToken=======

  public set updateToken(token: string | null) {
    if (token !== null) {
      sessionStorage.setItem("updateToken", token);
    } else {
      sessionStorage.removeItem("updateToken");
    }
  }

  public get updateToken() {
    return sessionStorage.getItem("updateToken");
  }

  public deleteUpdateToken() {
    return sessionStorage.removeItem("updateToken");
  }
}
