import * as React from 'react';

export interface IPagenationProps extends React.HTMLAttributes<HTMLDivElement> {
  totalCount: number;
  pageCount: number;
  page: number;
  size: number;
  onPageChange: (next: number) => void;
}

export const Pagenation = (props: IPagenationProps) => {
  const {totalCount, pageCount, page, size, onPageChange, children, ...divProps} = props;

  return (
      <div {...divProps}>
          <p className="text-right">{`全${totalCount}件`}</p>
          {children}
          <PagerButtons pageCount={pageCount} currentPage={page} onPageChange={onPageChange} />
      </div>
  );
}

interface IPagerButtonsProps {
  pageCount: number;
  currentPage: number;
  onPageChange: (next: number) => void;
}
const PagerButtons = (props: IPagerButtonsProps) => {
  const {pageCount, currentPage, onPageChange} = props;

  const pagerButtons: React.ReactNode[] = [];
  for (let i = 0; i < pageCount; i++) {
    const button = (
      <button
        type="button"
        className="page-link"
        onClick={() => onPageChange(i)}
      >
          {i + 1}
      </button>
    );

    if (i === currentPage) {
      pagerButtons.push((
        <li className="page-item active d-inline" aria-current="page">
          {button}
        </li>
      ))
    }
    else {
      pagerButtons.push((
        <li className="page-item d-inline">
          {button}
        </li>
      ))
    }
  }

  return (
    <ul className="pagenation d-flex align-items-center">
      <li className="page-item d-inline">
        <button
          type="button"
          className="page-link"
          onClick={() => onPageChange(0)}
        >
          最初
        </button>
      </li>
      <li className="page-item d-inline">
        <button
          type="button"
          className="page-link"
          disabled={currentPage <= 0}
          onClick={() => onPageChange(currentPage - 1)}
        >
          前へ
        </button>
      </li>
      {pagerButtons}
      <li className="page-item d-inline">
        <button
          type="button"
          className="page-link"
          disabled={currentPage === (pageCount - 1)}
          onClick={() => onPageChange(currentPage + 1)}
        >
          次へ
        </button>
      </li>
      <li className="page-item d-inline">
        <button
          type="button"
          className="page-link"
          onClick={() => onPageChange(pageCount - 1)}
        >
          最後
        </button>
      </li>
    </ul>
  )
}