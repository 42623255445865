import * as React from "react"

/**
 * 日付をフォーマットする
 * @param  {Date}   date     日付
 * @param  {String} [format] フォーマット
 * @return {String}          フォーマット済み日付
 */
export const formatDate = (date:Date | null | undefined, format?:string) => {
 
  if(!date){
return "";
  }

  if (!format)
    // format = 'YYYY/MM/DD hh:mm:ss.SSS';
    format = 'YYYY/MM/DD hh:mm:ss';
  format = format.replace(/YYYY/g, date.getFullYear().toString());
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
  format = format.replace(/DD/g, ('0' + date.getDate()).slice(-2));
  format = format.replace(/hh/g, ('0' + date.getHours()).slice(-2));
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
  if (format.match(/S/g)) {
    var milliSeconds = ('00' + date.getMilliseconds()).slice(-3);
    var length = format.match(/S/g)?.length ?? 0;
    for (var i = 0; i < length; i++)
      format = format.replace(/S/, milliSeconds.substring(i, i + 1));
  }
  return format;
};