/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    ChunkedListBase,
    ChunkedListBaseFromJSON,
    ChunkedListBaseFromJSONTyped,
    ChunkedListBaseToJSON,
    UpsStatus,
    UpsStatusFromJSON,
    UpsStatusFromJSONTyped,
    UpsStatusToJSON,
    UpsStatusLogChunkAllOf,
    UpsStatusLogChunkAllOfFromJSON,
    UpsStatusLogChunkAllOfFromJSONTyped,
    UpsStatusLogChunkAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpsStatusLogChunk
 */
export interface UpsStatusLogChunk {
    /**
     * 
     * @type {number}
     * @memberof UpsStatusLogChunk
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof UpsStatusLogChunk
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof UpsStatusLogChunk
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof UpsStatusLogChunk
     */
    pageCount: number;
    /**
     * 
     * @type {Array<UpsStatus>}
     * @memberof UpsStatusLogChunk
     */
    chunk: Array<UpsStatus>;
}

export function UpsStatusLogChunkFromJSON(json: any): UpsStatusLogChunk {
    return UpsStatusLogChunkFromJSONTyped(json, false);
}

export function UpsStatusLogChunkFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsStatusLogChunk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalCount': json['totalCount'],
        'pageCount': json['pageCount'],
        'chunk': ((json['chunk'] as Array<any>).map(UpsStatusFromJSON)),
    };
}

export function UpsStatusLogChunkToJSON(value?: UpsStatusLogChunk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'totalCount': value.totalCount,
        'pageCount': value.pageCount,
        'chunk': ((value.chunk as Array<any>).map(UpsStatusToJSON)),
    };
}


