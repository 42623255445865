import 'bootstrap/dist/css/bootstrap.min.css';
import { useApiResponse } from 'Components/api/ApiWrapper';
import { DeleteFileRequest, FileApi, GetUploadedFileListRequest } from 'Components/api/FileApi';
import { PageTitle } from 'Components/api/PageTitle';
import { VoidApiResponse } from 'Components/api/runtime';
import { UserRole } from 'Components/api/UserRole';
import { useSasDownloader, useSasUploader } from 'Components/api/useSas';
import { FileDropzone, filesizeLimitMB } from 'Components/FileDropzone';
import { FileUploadCard } from 'Components/FileUploadCard';
import { Pagenation } from 'Components/Pagenation';
import { ProgressBar } from 'Components/ProgressBar';
import { RoleFilter } from 'Components/RoleFilter';
import { SasDownloadButton } from 'Components/SasDownloadButton';
import { FileSasUpload } from 'library/openapi/models';
import { UploadedFileProperty } from 'library/openapi/models/UploadedFileProperty';
import { UploadedFilePropertyListChunk } from 'library/openapi/models/UploadedFilePropertyListChunk';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export const fileUpdownRoute = "/File";

export const FileUpDown = (props: RouteComponentProps) => {
    const [files, setFiles] = React.useState<File[]>([]);

    const onFileDetect = (detects: File[]) => {
        if (detects.length > 10) {
            alert("同時にドロップできるのは10件までです");
            return;
        }
        const next = [...files, ...detects];
        setFiles(next);
    }
    const onSizeOver = (f: File) => {
        alert(`ファイルサイズは${filesizeLimitMB}MBまでです。\n${f.name}はアップロードできません`);
    }

    return (
        <RoleFilter requirementRoles={[UserRole.ADMIN]} divElementInsert={true}>
            <FileDropzone
                onFileDetect={onFileDetect}
                onSizeOver={onSizeOver}
                className="vh-100 position-fixed"
                style={{width: "85vw", left: 0, top: "3rem"}}
            >
            </FileDropzone>
            <PageTitle title="ファイル一覧" />
            <FileTable uploadFiles={files} />
        </RoleFilter>
    )
}

const fileApi = new FileApi();
const tableHeaders = [
    "ファイル名",
    "UP日時",
    "UPユーザー名",
    "最終DL日時",
    "最終DLユーザー名",
    ""
]

const isDate = (data: any): data is Date => !!data.getFullYear;
const loadDateTimeObject = (data: Date | string): Date => {
    if (isDate(data)) {
        return data;
    }
    else {
        return new Date(Date.parse(data));
    }
}
const toDateStr = (date: Date | undefined | null): string => {
    if (!date) {
        return "-";
    }

    return `${date.getFullYear()}/${`00${date.getMonth() + 1}`.slice(-2)}/${`00${date.getDate()}`.slice(-2)} ${`00${date.getHours()}`.slice(-2)}:${`00${date.getMinutes()}`.slice(-2)}:${`00${date.getSeconds()}`.slice(-2)}`;
}

interface IFileTableProps extends React.HTMLAttributes<HTMLTableElement> {
    uploadFiles: File[];
}
const FileTable = (props: IFileTableProps) => {
    const {uploadFiles, ...tableProps} = props;

    const [page, setPage] = React.useState(0);

    const [tableLoading, tableData, tableDataLoader] = useApiResponse<GetUploadedFileListRequest, UploadedFilePropertyListChunk>(
        fileApi.getUploadedFileListRaw.bind(fileApi),
        React.useCallback((res) => alert("データ取得取得"), [])
    );

    React.useEffect(() => {
        tableDataLoader({
            size: 50,
            page: page
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    if (tableLoading || !tableData) {
        return <span>now loading...</span>
    }


    const chunk: Array<UploadedFileProperty> = tableData.chunk;



    return (
        <Pagenation
            totalCount={tableData.totalCount + uploadFiles.length}
            pageCount={tableData.pageCount}
            page={tableData.page ?? 0}
            size={tableData.size}
            onPageChange={setPage}
        >
            <table className="table table-sm table-hover" {...tableProps}>
                <thead className="bg-info">
                    <tr>
                        {tableHeaders.map((h) => <th key={`header ${h}`}>{h}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {uploadFiles.map((f, idx) => <TableUploadFileRow file={f} key={`${idx}th upload row`} />)}
                    {chunk.map((data, idx) => <TableRow fileProperty={data} key={`${idx}th row`} />)}
                </tbody>
            </table>
        </Pagenation>
    )
}

interface ITableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    fileProperty: UploadedFileProperty;
    uploaded?: boolean;
}
const TableRow = (props: ITableRowProps) => {
    const {fileProperty, uploaded, ...rowProps} = props;

    const [deleted, setDeleted] = React.useState(false);
    const [downloaded, setDownloaded] = React.useState(false);

    const [deleting, none, startDelete] = useApiResponse(
        React.useCallback(async (prm: DeleteFileRequest) => {
            const result = await fileApi.deleteFileRaw(prm);
            setDeleted(true);   // 正常終了したら完了フラグを立てる
            return result;
        }, []),
        React.useCallback((res: Response) => {
            if (res.status === 404) {
                alert("対象が見つかりませんでした");
            }
            else {
                alert("削除失敗");
            }
        }, [])
    );

    if (deleted) {
        return <React.Fragment></React.Fragment>;
    }

    const uploadedDateTime = loadDateTimeObject(fileProperty.uploadedDateTime);
    let lastDownloadedDateTime: Date | null = null;
    if (fileProperty.lastDownloadedDateTime) {
        lastDownloadedDateTime = loadDateTimeObject(fileProperty.lastDownloadedDateTime);
    }

    const onDeleteClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        const agree = window.confirm(`${fileProperty.filename}を削除します。よろしいですか？`);
        if (!agree) {
            return;
        }

        startDelete({id: fileProperty.id});
    }

    const onDownloadCompleted = (file: File) => {
        setDownloaded(true);
    }

    return (
        <tr {...rowProps}>
            <td>{fileProperty.filename}</td>
            <td>{uploaded ? "just now" : toDateStr(uploadedDateTime)}</td>
            <td>{fileProperty.uploadedUserName}</td>
            <td>{downloaded ? "just now" : toDateStr(lastDownloadedDateTime)}</td>
            <td>{downloaded ? "you" : (fileProperty.lastDownloadedUserName ?? "-")}</td>
            <td>
                <div className="d-flex align-items-center">
                    <SasDownloadButton
                        fileId={fileProperty.id}
                        onComplete={onDownloadCompleted}
                        className="btn btn-sm btn-primary mr-2"
                    >
                        DL
                    </SasDownloadButton>
                    <button
                        disabled={deleting}
                        onClick={onDeleteClick}
                        className="btn btn-sm btn-danger"
                    >
                        削除
                    </button>
                </div>
            </td>
        </tr>
    )
}

interface ITableUploadFileRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    file: File;
}
const TableUploadFileRow = (props: ITableUploadFileRowProps) => {
    const {file, ...rowProps} = props;

    const [sasInfo, setSasInfo] = React.useState<FileSasUpload | null>(null);
    const [dummyProperty, setDummyProperty] = React.useState<UploadedFileProperty | null>(null);

    const {progressing, progressPercentage, starter} = useSasUploader(setSasInfo);

    React.useEffect(() => {
        if (!sasInfo) {
            return;
        }

        setDummyProperty({
            id: sasInfo.id!,
            filename: file.name,
            uploadedDateTime: new Date(),
            uploadedUserName: "you"
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sasInfo]);

    React.useEffect(() => {
        starter(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (progressing) {
        return (
            <tr {...rowProps}>
                <td>{file.name}</td>
                <td colSpan={tableHeaders.length - 1}>
                    <ProgressBar percentage={progressPercentage} className="w-100 h-100 m-2" />
                </td>
            </tr>
        );
    }

    if (!dummyProperty) {
        return (
            <tr {...rowProps}>
                <td>{file.name}</td>
                <td colSpan={tableHeaders.length - 1}></td>
            </tr>
        );
    }

    return <TableRow fileProperty={dummyProperty} uploaded={true} {...rowProps} />;
}