/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from './runtime';
import {
    DeviceInfoListChunk,
    DeviceInfoListChunkFromJSON,
    DeviceInfoListChunkToJSON,
    FileResponse,
    FileResponseFromJSON,
    FileResponseToJSON,
    InfoFilter,
    InfoFilterFromJSON,
    InfoFilterToJSON,
    UpsDetail,
    UpsDetailFromJSON,
    UpsDetailToJSON,
    UpsSearchFilter,
    UpsSearchFilterFromJSON,
    UpsSearchFilterToJSON,
    UpsStatusLogChunk,
    UpsStatusLogChunkFromJSON,
    UpsStatusLogChunkToJSON,
    UpsSummaryChunk,
    UpsSummaryChunkFromJSON,
    UpsSummaryChunkToJSON,
} from '../../library/openapi/models';

export interface ClearStatusRequest {
    manageSerial: number;
}

export interface GetDeviceInfoListRequest {
    page: number;
    size?: number;
    filter?: InfoFilter;
}

export interface GetDeviceInfoListFileRequest {
    filter?: InfoFilter;
    limit?: number | null;
}

export interface GetUpsRequest {
    manageSerial: number;
}

export interface GetUpsListRequest {
    page: number;
    size?: number;
    filter?: UpsSearchFilter;
}

export interface GetUpsListFileRequest {
    filter?: UpsSearchFilter;
    limit?: number | null;
}

export interface GetUpsStatusLogRequest {
    manageSerial: number;
    page: number;
    size?: number;
}

export interface GetUpsStatusLogFileRequest {
    manageSerial: number;
}

/**
 * 
 */
export class UpsApi extends runtime.BaseAPI {

    /**
     * UPS状態の初期化
     */
    async clearStatusRaw(requestParameters: ClearStatusRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling clearStatus.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/refresh/{manageSerial}`.replace(`{${"manageSerial"}}`, encodeURIComponent(String(requestParameters.manageSerial))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * UPS状態の初期化
     */
    async clearStatus(requestParameters: ClearStatusRequest): Promise<void> {
        await this.clearStatusRaw(requestParameters);
    }

       /**
     * UPS関連情報一覧
     */
    async getDeviceInfoListRaw(requestParameters: GetDeviceInfoListRequest): Promise<runtime.ApiResponse<DeviceInfoListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getDeviceInfoList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;

            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area; }
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId; }
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial; }
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName; }
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber; }

        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/info/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => DeviceInfoListChunkFromJSON(jsonValue));
    }

        /**
     * UPS関連情報一覧
     */
    async getDeviceInfoList(requestParameters: GetDeviceInfoListRequest): Promise<DeviceInfoListChunk> {
        const response = await this.getDeviceInfoListRaw(requestParameters);
        return await response.value();
    }

        /**
     * UPS関連情報一覧
     */
    async getDeviceInfoListFileRaw(requestParameters: GetDeviceInfoListFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;
            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area;}
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId;}
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial;}
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName;}
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber;}
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/info/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * UPS関連情報一覧
     */
    async getDeviceInfoListFile(requestParameters: GetDeviceInfoListFileRequest): Promise<FileResponse> {
        const response = await this.getDeviceInfoListFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * UPSの詳細
     */
    async getUpsRaw(requestParameters: GetUpsRequest): Promise<runtime.ApiResponse<UpsDetail>> {
        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getUps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/{manageSerial}`.replace(`{${"manageSerial"}}`, encodeURIComponent(String(requestParameters.manageSerial))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsDetailFromJSON(jsonValue));
    }

    /**
     * UPSの詳細
     */
    async getUps(requestParameters: GetUpsRequest): Promise<UpsDetail> {
        const response = await this.getUpsRaw(requestParameters);
        return await response.value();
    }

    /**
     * UPS一覧の取得
     */
    async getUpsListRaw(requestParameters: GetUpsListRequest): Promise<runtime.ApiResponse<UpsSummaryChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getUpsList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;
            
            if(requestParameters.filter.stationName){
                queryParameters['filter.stationName'] = requestParameters.filter.stationName ;
            }

            if(requestParameters.filter.stationNumber){
                queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber ;
            }

            if(requestParameters.filter.onlyNotReceived){
                queryParameters['filter.onlyNotReceived'] = requestParameters.filter.onlyNotReceived ;
            }

            if(requestParameters.filter.onlyError){
                queryParameters['filter.onlyError'] = requestParameters.filter.onlyError ;
            }

            if(requestParameters.filter.upsManageSerial){
                queryParameters['filter.upsManageSerial'] = requestParameters.filter.upsManageSerial ;
            }

        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/monitoring/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsSummaryChunkFromJSON(jsonValue));
    }

    /**
     * UPS一覧の取得
     */
    async getUpsList(requestParameters: GetUpsListRequest): Promise<UpsSummaryChunk> {
        const response = await this.getUpsListRaw(requestParameters);
        return await response.value();
    }

    /**
     * UPS一覧の取得
     */
    async getUpsListFileRaw(requestParameters: GetUpsListFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;

            if(requestParameters.filter.stationName){
                queryParameters['filter.stationName'] = requestParameters.filter.stationName ;
            }

            if(requestParameters.filter.stationNumber){
                queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber ;
            }

            if(requestParameters.filter.onlyNotReceived){
                queryParameters['filter.onlyNotReceived'] = requestParameters.filter.onlyNotReceived ;
            }

            if(requestParameters.filter.onlyError){
                queryParameters['filter.onlyError'] = requestParameters.filter.onlyError ;
            }

        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/monitoring/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * UPS一覧の取得
     */
    async getUpsListFile(requestParameters: GetUpsListFileRequest): Promise<FileResponse> {
        const response = await this.getUpsListFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * 対象UPSの受信ログ
     */
    async getUpsStatusLogRaw(requestParameters: GetUpsStatusLogRequest): Promise<runtime.ApiResponse<UpsStatusLogChunk>> {
        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getUpsStatusLog.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getUpsStatusLog.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/log/{manageSerial}/{page}`.replace(`{${"manageSerial"}}`, encodeURIComponent(String(requestParameters.manageSerial))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsStatusLogChunkFromJSON(jsonValue));
    }

    /**
     * 対象UPSの受信ログ
     */
    async getUpsStatusLog(requestParameters: GetUpsStatusLogRequest): Promise<UpsStatusLogChunk> {
        const response = await this.getUpsStatusLogRaw(requestParameters);
        return await response.value();
    }

    /**
     * 対象UPSの受信ログ
     */
    async getUpsStatusLogFileRaw(requestParameters: GetUpsStatusLogFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getUpsStatusLogFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/ups/log/{manageSerial}/export`.replace(`{${"manageSerial"}}`, encodeURIComponent(String(requestParameters.manageSerial))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * 対象UPSの受信ログ
     */
    async getUpsStatusLogFile(requestParameters: GetUpsStatusLogFileRequest): Promise<FileResponse> {
        const response = await this.getUpsStatusLogFileRaw(requestParameters);
        return await response.value();
    }

}
