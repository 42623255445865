import * as React from "react";
import { LockedUpsHistoryListChunk } from "library/openapi/models";
import { Pagenation } from "./Pagination";
import { formatDate } from "./myClass/formatAnyDate";
import "../css/Common.css";

interface Props {
  upsLogChunk: LockedUpsHistoryListChunk | undefined | null;
  clickPager: any;
}

const getErrorCss = (
  isCommercialPowerError: boolean | null | undefined,
  isUpsError: boolean | null | undefined,
  isBatteryError: boolean | null | undefined
): string => {
  let result = "";

  if (isCommercialPowerError || isUpsError || isBatteryError) {
    result = "bg-danger";
  }

  return result;
};

const cssForIsError = (isError: boolean | null | undefined) => {
  if (isError) {
    return "text-danger font-weight-bold";
  } else {
    return "";
  }
};

export const TableBind: React.FC<Props> = (props) => {
  if (!props.upsLogChunk?.chunk) {
    return <div>データなし</div>;
  }

  //ページングイベントハンドラ
  const clickPagerEvent = (page: number) => {
    props.clickPager(page);
  };

  //rowデータ生成
  const rows = props.upsLogChunk.chunk.map((ups, index) => (
    <tr key={index}>
      <td>{formatDate(ups.receivedDate)}</td>
      <td>{ups.trigger}</td>
      <td className={cssForIsError(ups.isCommercialPowerError)}>
        {ups.isCommercialPowerError == undefined ? "" : (ups.isCommercialPowerError? "異常" : "正常")}
      </td>
      <td className={cssForIsError(ups.isUpsError)}>
        {(ups.isUpsError == undefined || ups.isUpsError == null) ? "" : (ups.isUpsError ? "異常" : "正常")}
      </td>
      <td className={cssForIsError(ups.isBatteryError)}>
        {ups.isBatteryError == undefined ? "" : (ups.isBatteryError ? "異常" : "正常")}
      </td>
      <td>{ups.passedDays}</td>
      <td>{ups.statusCode}</td>
      <td>{ups.seqNumber}</td>
    </tr>
  ));

  //ページャー生成
  const pager = () => {
    const pagenation = new Pagenation(
      props.upsLogChunk?.page ?? 0,
      props.upsLogChunk?.pageCount ?? 0
    );

    let pagerNumbers: number[] = pagenation.pageNumbers();

    let pagerElement: JSX.Element[] = [];

    let prevPage: number = (props.upsLogChunk?.page ?? 0) - 1;
    let nextPage: number = (props.upsLogChunk?.page ?? 0) + 1;

    if (prevPage < 0) {
      prevPage = 0;
    }

    if (nextPage >= (props.upsLogChunk?.pageCount ?? 0)) {
      nextPage = (props.upsLogChunk?.pageCount ?? 1) - 1;
    }

    pagerNumbers.forEach((page) => {
      if (page == (props.upsLogChunk?.page ?? 0)) {
        pagerElement.push(
          <li className="page-item active" aria-current="page">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      } else {
        pagerElement.push(
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      }
    });

    return (
      <ul className="pagination">
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(0)}
          >
            最初
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(prevPage)}
          >
            前へ
          </button>
        </li>
        {pagerElement}
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(nextPage)}
          >
            次へ
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() =>
              clickPagerEvent((props.upsLogChunk?.pageCount ?? 1) - 1)
            }
          >
            最後
          </button>
        </li>
      </ul>
    );
  };

  const footerElement = pager();

  return (
    <table className="table table-sm table-hover">
      <thead className="bg-info">
        <tr>
          <th>受信日時</th>
          <th>
            トリガー
          </th>
          <th>商用電源状態</th>
          <th>UPS状態</th>
          <th>
            SIGFOX電池状態
          </th>
          <th>経過日数</th>
          <th>
            受信文字列
          </th>
          <th>
          シーケンス番号
        </th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <tfoot>
        <tr>
          <td colSpan={7}>{footerElement}</td>
        </tr>
      </tfoot>
    </table>
  );
};
