import { fileUpdownRoute } from "pages/FileUpDown";
import { passwordUpdatePath } from "pages/PasswordUpdate";
import { stationManagePath } from "pages/StationManage";
import { userManagePath } from "pages/UserManage";
import { UserRegister } from "pages/UserRegister";
import * as React from "react";
import { Link, useHistory } from "react-router-dom";
import { UserRole } from "./api/UserRole";
import Logout from "./Logout";
import { RoleFilter } from "./RoleFilter";
import { useUserInfo } from "./UserInfoContext";


const Menu: React.FC = () => {
  const {info: userInfo} = useUserInfo();
  const history = useHistory();
  const onPasswordChangeClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    history.push(passwordUpdatePath(userInfo.id));
  }
  const onToUserManagePageLinkClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    history.push(userManagePath);
  }
  const onToStationManagePageLinkClick = (ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    history.push(stationManagePath);
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <Link to="/" className="navbar-brand">
        UQ UPS
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          {/*<li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Menu
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <Link to="/" className="dropdown-item">
                受信一覧
              </Link>
              <Link to="/StationList" className="dropdown-item">
                設置局一覧
              </Link>
            </div>
          </li>
  */}
          <li className="nav-item">
            <Link className="nav-link" to="/">
              受信一覧
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/MoniterForErrorStation">
              監視モニタ
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/AllStationList">
              局一覧
            </Link>
          </li>
          <li className="nav-item">
            <Link className="nav-link" to="/AllDeviceList">
              デバイス一覧
            </Link>
          </li>
          <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <li className="nav-item">
              <Link className="nav-link" to={fileUpdownRoute}>
                ファイル
              </Link>
            </li>
          </RoleFilter>
          <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <li className="nav-item">
              <div className="dropdown">
                <button className="btn nav-link dropdown-toggle" style={{cursor: "default"}} type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  管理
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{cursor: "pointer"}}>
                  <a className="dropdown-item" onClick={onToUserManagePageLinkClick}>ユーザー</a>
                  <a className="dropdown-item" onClick={onToStationManagePageLinkClick}>基地局</a>
                </div>
              </div>
            </li>
          </RoleFilter>
        </ul>
        {/* <ul>
            <li>
              <Link to="/">Top</Link>
            </li>
            <li>
              <Link to="/Page1">Page1</Link>
            </li>
            <li>
              <Link to="/Page2">Page2</Link>
            </li>
          </ul> */}
        <div className="ml-auto text-right">
          <h6>{`ようこそ ${userInfo.name}`}</h6>
          <button className="btn btn-sm btn-info" onClick={onPasswordChangeClick}>PW変更</button>
        </div>
        <div className="ml-3 my-2 my-lg-0 ">
          <Logout />
        </div>
      </div>
    </nav>
  );
};

export default Menu;
