import { useApiResponse } from 'Components/api/ApiWrapper';
import { PageTitle } from 'Components/api/PageTitle';
import { UserApi } from 'Components/api/UserApi';
import { UserRole } from 'Components/api/UserRole';
import { RoleFilter } from 'Components/RoleFilter';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';

export const userRegisterPath = "/user/registeration";

const userApi = new UserApi();

export const UserRegister = (props: RouteComponentProps) => {
    const [tempDataLoading, tempData, tempDataLoader] = useApiResponse(
        userApi.getPreDataRaw.bind(userApi),
        React.useCallback((err: Response) => alert("仮データを取得できませんでした"), [])
    );


    const [isAdmin, setIsAdmin] = React.useState(false);
    const onAdminCheckClick = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        setIsAdmin(!isAdmin);
    }

    const [userId, setUserId] = React.useState("");
    const onUserIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setUserId(ev.currentTarget.value);
    }

    const [userName, setUserName] = React.useState("");
    const onUserNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setUserName(ev.currentTarget.value);
    }

    const [saveClicked, setSaveClicked] = React.useState(false);    // 一度しか押せなくする

    const [userRegistrationProcessing, _none, callRegister] = useApiResponse(
        userApi.registerUserRaw.bind(userApi),
        React.useCallback((err: Response) => {
            if (err.status === 409) {
                alert(`ユーザーID:${userId} は既に使用されています`)
            }
            else {
                alert("登録に失敗しました。最初からやり直してください")
            }
        }, [userId]),
        React.useCallback((data) => {
            setSaveClicked(true);
        }, [])
    );

    React.useEffect(() => {
        tempDataLoader({});
    }, []);

    
    const onSaveClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!userId) {
            alert("ユーザーIDは必須です");
            return;
        }
        else if (!userName) {
            alert("ユーザー名は必須です");
            return;
        }
        callRegister({
            userRegistrationRequest: {
                id: userId,
                name: userName,
                otpKey: tempData!.otpKey,
                isAdmin
            }
        });
    }

    let saveButton = <button className="btn btn-sm btn-primary" onClick={onSaveClick}>保存</button>;
    if (saveClicked && !userRegistrationProcessing) {
        saveButton = <p className="text-danger">ユーザーIDと仮パスワードはログインに必要です。必ず控えてください。
        仮パスワードの有効期限は24時間です。</p>;
    }
    else if (userRegistrationProcessing) {
        saveButton = <span>saving...</span>;
    }

    return (
        <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <PageTitle title="ユーザー作成" className="d-inline-block" />
            <button
                type="button"
                className="btn btn-sm btn-outline-secondary"
                onClick={props.history.goBack}
            >
                前ページ
            </button>
            <div className="container">
                <div className="row">
                    <div className="col-2 bg-info border">
                        ユーザーID
                    </div>
                    <div className="col-2 border">
                        <input className="form-control form-control-sm" onChange={onUserIdChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 bg-info border">
                        ユーザー名
                    </div>
                    <div className="col-2 border">
                        <input className="form-control form-control-sm" onChange={onUserNameChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 bg-info border">
                        仮パスワード
                    </div>
                    <div className="col-2 border">
                        {tempData?.otpKey ?? "now loading..."}
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 bg-info border">
                        管理者フラグ
                    </div>
                    <div className="col-2 border">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" value="" id="adminCheckBox" checked={isAdmin} onClick={onAdminCheckClick} />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <div className="text-right">
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        </RoleFilter>
    )
}