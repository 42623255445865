import * as React from "react";
// import logo from './logo.svg';
// import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
} from "react-router-dom";
import "./App.css";
// import history from './history';

// function App() {
//   return (
//     // <div className="App">
//     //   <header className="App-header">
//     //     <img src={logo} className="App-logo" alt="logo" />
//     //     <p>
//     //       Edit <code>src/App.js</code> and save to reload.
//     //     </p>
//     //     <a
//     //       className="App-link"
//     //       href="https://reactjs.org"
//     //       target="_blank"
//     //       rel="noopener noreferrer"
//     //     >
//     //       Learn React
//     //     </a>
//     //   </header>
//     // </div>
//     // <div>
//     <React.Fragment>
//       hello,World!!
//       <Blog />
//       </React.Fragment>
//     // </div>
//   );
// }
import Login from "./Login";
import Auth from "./Components/Auth";

// const App: React.FC = () => {
//   return (
//     <BrowserRouter>
//       <Switch>
//         <Route exact path="/login" component={Login} />
//         <Redirect from="/" to="/login" />
//         <Auth>
//           <Switch>
//             <Route exact path="/Hello1"><Hello1 name="�e�X�^�[" /></Route>
//           </Switch>
//         </Auth>
//       </Switch>
//     </BrowserRouter>
//   );
// };

import Content from "./Components/ContentRouter";
import { PasswordUpdate, passwordUpdatePath } from "pages/PasswordUpdate";
import { UserInfoProvider } from "Components/UserInfoContext";

interface Props {}
const App: React.FC = ({}: Props) => {
  return (
    <UserInfoProvider>
      <BrowserRouter>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route path={passwordUpdatePath()} component={PasswordUpdate} />

          <Auth>
            <Route component={Content} />
          </Auth>
        </Switch>
        
      </BrowserRouter>
    </UserInfoProvider>
  );
};

export default App;
