/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface UploadedFileProperty
 */
export interface UploadedFileProperty {
    /**
     * 
     * @type {number}
     * @memberof UploadedFileProperty
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileProperty
     */
    filename: string;
    /**
     * 
     * @type {Date}
     * @memberof UploadedFileProperty
     */
    uploadedDateTime: Date;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileProperty
     */
    uploadedUserName: string;
    /**
     * 
     * @type {Date}
     * @memberof UploadedFileProperty
     */
    lastDownloadedDateTime?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof UploadedFileProperty
     */
    lastDownloadedUserName?: string;
}

export function UploadedFilePropertyFromJSON(json: any): UploadedFileProperty {
    return UploadedFilePropertyFromJSONTyped(json, false);
}

export function UploadedFilePropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadedFileProperty {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'filename': json['filename'],
        'uploadedDateTime': (new Date(json['uploadedDateTime'])),
        'uploadedUserName': json['uploadedUserName'],
        'lastDownloadedDateTime': !exists(json, 'lastDownloadedDateTime') ? undefined : (json['lastDownloadedDateTime'] === null ? null : new Date(json['lastDownloadedDateTime'])),
        'lastDownloadedUserName': !exists(json, 'lastDownloadedUserName') ? undefined : json['lastDownloadedUserName'],
    };
}

export function UploadedFilePropertyToJSON(value?: UploadedFileProperty | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'filename': value.filename,
        'uploadedDateTime': (value.uploadedDateTime.toISOString()),
        'uploadedUserName': value.uploadedUserName,
        'lastDownloadedDateTime': value.lastDownloadedDateTime === undefined ? undefined : (value.lastDownloadedDateTime === null ? null : value.lastDownloadedDateTime.toISOString()),
        'lastDownloadedUserName': value.lastDownloadedUserName,
    };
}


