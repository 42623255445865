import { useApiResponse } from 'Components/api/ApiWrapper';
import { PageTitle } from 'Components/api/PageTitle';
import { UserApi } from 'Components/api/UserApi';
import { Token } from 'Components/Token';
import { User } from 'Components/User';
import { useUserInfo } from 'Components/UserInfoContext';
import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

export const passwordUpdatePath = (userId?: string) =>`/user/password/update/${userId ?? ":userId"}`;

const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)[a-zA-Z\d]{8,}$/;

const userApi = new UserApi();
export const PasswordUpdate = () => {
    const history = useHistory();
    const match = useRouteMatch<{userId: string}>();
    const {setter: userInfoSetter} = useUserInfo();

    const [updating, loginData, passwordUpdater] = useApiResponse(
        userApi.updatePasswordRaw.bind(userApi),
        React.useCallback((err: Response) => {
            if (err.status === 400) {
                err.json().then(obj => alert(obj.reason + "\n" + obj.hint));
            }
            else if (err.status === 404) {
                alert(`旧パスワードが一致しません`);
            }
            else {
                alert(`失敗しました code=${err.status}`);
            }
        }, [])
    );
    
    React.useEffect(() => {
        if (!loginData) {
            return;
        }

        User.getInstance.setUserId(loginData.userInfo.id);
        User.getInstance.setUserName(loginData.userInfo.name);
        Token.instance.token = loginData.accessToken;
        Token.instance.updateToken = loginData.updateToken;

        userInfoSetter({
            id: loginData.userInfo.id,
            name: loginData.userInfo.name,
            roles: loginData.userInfo.roles
        });

        history.push("");
    }, [loginData, history]);

    const [oldPassword, setOldPassword] = React.useState("");
    const onOldPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setOldPassword(ev.currentTarget.value);
    }

    const [newPassword, setNewPassword] = React.useState("");
    const onNewPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setNewPassword(ev.currentTarget.value);
    }

    const [confirmNewPassword, setConfirmNewPassword] = React.useState("");
    const onConfirmNewPasswordChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmNewPassword(ev.currentTarget.value);
    }

    const onSaveClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (oldPassword === newPassword) {
            alert("同じパスワードは設定できません");
            return;
        }

        if (!passwordRegex.test(newPassword)) {
            alert("パスワードは半角英小文字大文字数字を全て一つずつ含めた8文字以上である必要があります");
            return;
        }
        if (newPassword !== confirmNewPassword) {
            alert("新パスワードが確認用と一致しません");
            return;
        }

        passwordUpdater({
            id: match.params.userId,
            passwordUpdateRequest: {
                oldPassword,
                newPassword
            }
        });
    }

    let saveButton = <button className="btn btn-sm btn-primary" onClick={onSaveClick}>保存</button>;
    if (updating) {
        saveButton = <span>saving...</span>;
    }

    if (!match.params.userId) {
        alert("不正なURL");
        history.push("");
        return <React.Fragment></React.Fragment>;
    }

    return (
        <React.Fragment>
            <PageTitle title="パスワード更新" />
            <div className="container">
                <div className="row">
                    <div className="col-2 bg-info border">
                        旧パスワード
                    </div>
                    <div className="col-2 border">
                        <input type="password" className="form-control" onChange={onOldPasswordChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 bg-info border">
                        新パスワード
                    </div>
                    <div className="col-2 border">
                        <input type="password" className="form-control" onChange={onNewPasswordChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-2 bg-info border">
                        新パスワード(確認)
                    </div>
                    <div className="col-2 border">
                        <input type="password" className="form-control" onChange={onConfirmNewPasswordChange} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        <div className="text-right">
                            {saveButton}
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}