import { useApiResponse } from 'Components/api/ApiWrapper';
import { PageTitle } from 'Components/api/PageTitle';
import { UserApi } from 'Components/api/UserApi';
import { UserRole } from 'Components/api/UserRole';
import { Pagenation } from 'Components/Pagenation';
import { RoleFilter } from 'Components/RoleFilter';
import { PasswordResetResult, UserInfo, UserInfoListChunk } from 'library/openapi/models';
import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { userRegisterPath } from './UserRegister';

export const userManagePath = "/user/manage";

export const UserManage = (props: RouteComponentProps) => {
    

    const toUserCreatePage = React.useCallback((ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        console.log(`move to ${userRegisterPath}`);
        props.history.push(userRegisterPath);
    }, [props.history]);

    return (
        <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <PageTitle title="ユーザー管理" />
            <div className="container">
                <div className="text-right">
                    <button className="btn btn-sm btn-success" onClick={toUserCreatePage}>追加</button>
                </div>
                <br />
                <UserList />
            </div>
        </RoleFilter>
    )
}


const tableHeaders = [
    "ユーザーID",
    "ユーザー名",
    "管理者フラグ",
    ""
];

const PAGESIZE = 50;
const userApi = new UserApi();
const UserList = () => {
    const [userListLoading, userList, userListLoader] = useApiResponse(
        userApi.getUserListRaw.bind(userApi),
        React.useCallback((err: Response) => {
            alert("取得失敗");
        }, [])
    );

    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        userListLoader({
            size: PAGESIZE,
            page
        });
    }, [page, userListLoader]);

    const onDeleteClick = () => {
        setPage(0);
        userListLoader({
            size: PAGESIZE,
            page: 0
        });
    }
    
    if (!userList) {
        return <span>now loading...</span>
    }

    return (
        <Pagenation
            onPageChange={setPage}
            page={page}
            pageCount={userList.pageCount}
            totalCount={userList.totalCount}
            size={PAGESIZE}
        >
            <table className="table table-sm table-hover">
                <thead className="bg-info">
                    <tr>
                        {tableHeaders.map((h, idx) => <th key={`header ${idx}=${h}`}>{h}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {userList.chunk.map((data, idx) => <TableRow user={data} onDeleteClick={onDeleteClick} key={`${idx}th row`} />)}
                </tbody>
            </table>
        </Pagenation>
    )
}

interface ITableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    user: UserInfo;
    onDeleteClick: () => void;
}
const TableRow = (props: ITableRowProps) => {
    const {user, onDeleteClick, ...rowProps} = props;

    const [userDeleting, _none1, userDeleteStart] = useApiResponse(
        userApi.deleteUserRaw.bind(userApi),
        React.useCallback((err: Response) => {
            if (err.status === 404) {
                alert("対象のユーザーIDは見つかりませんでした");
            }
            else {
                alert("削除失敗");
            }
        }, []),
        React.useCallback((data) => {
            onDeleteClick();
        }, [onDeleteClick])
    );

    const [passwordResetting, resetData, passwordResetStart] = useApiResponse(
        userApi.resetPasswordRaw.bind(userApi),
        React.useCallback((err: Response) => {
            if (err.status === 404) {
                alert("対象のユーザーIDは見つかりませんでした");
            }
            else {
                alert("削除失敗");
            }
        }, []),
        React.useCallback((data: PasswordResetResult) => {
            alert(`仮パスワードは「${data.otpKey}」です。(有効期限24時間)\nログイン後、再設定を行ってください`);
        }, [])
    );

    const onDeleteButtonClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!window.confirm(`${user.id}を削除します。本当によろしいですか？`)) {
            return;
        }
        userDeleteStart({
            id: user.id
        });
    }

    const onPasswordResetButtonClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        passwordResetStart({
            id: user.id
        });
    }

    let passwordResetButton = <button className="btn btn-sm btn-warning ml-1" onClick={onPasswordResetButtonClick}>PW初期化</button>;
    if (passwordResetting) {
        passwordResetButton = <span className="ml-2">processing...</span>;
    }
    if (!passwordResetting && resetData) {
        passwordResetButton = <span className="ml-2">{resetData.otpKey}</span>
    }

    return (
        <tr {...rowProps}>
            <td>{user.id}</td>
            <td>{user.name}</td>
            <td>
                <div className="form-check">
                    <input className="form-check-input" type="checkbox" value="" id="adminCheckBox" checked={user.roles.includes(UserRole.ADMIN)} />
                </div>
            </td>
            <td>
                {userDeleting ? <span>deleting...</span> : <button className="btn btn-sm btn-danger" onClick={onDeleteButtonClick}>削除</button>}
                {passwordResetButton}
            </td>
        </tr>
    )
}