import * as React from "react";
import { User } from "./User";
import { Token } from './Token';
import { Link} from "react-router-dom";

const Logout = () => {
  const Click = React.useCallback(() => {

    if (User.getInstance.getUserId() || User.getInstance.getUserName()) {
      User.getInstance.deleteUserInfo();
    }

    if (Token.instance.token || Token.instance.updateToken) {
      Token.instance.deleteToken();
      Token.instance.deleteUpdateToken();
    }

    window.location.reload();
  }, []);

  return <Link to="/" onClick={Click}>ログアウト</Link>;
};

export default Logout;
