/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    StationDetail,
    StationDetailFromJSON,
    StationDetailFromJSONTyped,
    StationDetailToJSON,
    Ups,
    UpsFromJSON,
    UpsFromJSONTyped,
    UpsToJSON,
    UpsDetailAllOf,
    UpsDetailAllOfFromJSON,
    UpsDetailAllOfFromJSONTyped,
    UpsDetailAllOfToJSON,
    UpsStatus,
    UpsStatusFromJSON,
    UpsStatusFromJSONTyped,
    UpsStatusToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpsDetail
 */
export interface UpsDetail {
    /**
     * 
     * @type {string}
     * @memberof UpsDetail
     */
    deviceId: string;
    /**
     * 
     * @type {number}
     * @memberof UpsDetail
     */
    manageSerial: number;
    /**
     * 
     * @type {UpsStatus}
     * @memberof UpsDetail
     */
    firstStatusLog: UpsStatus;
    /**
     * 
     * @type {UpsStatus}
     * @memberof UpsDetail
     */
    lastStatusLog: UpsStatus;
    /**
     * 
     * @type {Array<StationDetail>}
     * @memberof UpsDetail
     */
    stations: Array<StationDetail>;
}

export function UpsDetailFromJSON(json: any): UpsDetail {
    return UpsDetailFromJSONTyped(json, false);
}

export function UpsDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsDetail {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': json['deviceId'],
        'manageSerial': json['manageSerial'],
        'firstStatusLog': UpsStatusFromJSON(json['firstStatusLog']),
        'lastStatusLog': UpsStatusFromJSON(json['lastStatusLog']),
        'stations': ((json['stations'] as Array<any>).map(StationDetailFromJSON)),
    };
}

export function UpsDetailToJSON(value?: UpsDetail | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'manageSerial': value.manageSerial,
        'firstStatusLog': UpsStatusToJSON(value.firstStatusLog),
        'lastStatusLog': UpsStatusToJSON(value.lastStatusLog),
        'stations': ((value.stations as Array<any>).map(StationDetailToJSON)),
    };
}


