/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from './runtime';
import {
    ErrorStationListChunk,
    ErrorStationListChunkFromJSON,
    ErrorStationListChunkToJSON,
    FailedResponse,
    FailedResponseFromJSON,
    FailedResponseToJSON,
    FileResponse,
    FileResponseFromJSON,
    FileResponseToJSON,
    FileUpload,
    FileUploadFromJSON,
    FileUploadToJSON,
    InfoFilter,
    InfoFilterFromJSON,
    InfoFilterToJSON,
    LockUpsRequest,
    LockUpsRequestFromJSON,
    LockUpsRequestToJSON,
    LockedUpsHistoryListChunk,
    LockedUpsHistoryListChunkFromJSON,
    LockedUpsHistoryListChunkToJSON,
    StationDetailWithUps,
    StationDetailWithUpsFromJSON,
    StationDetailWithUpsToJSON,
    StationFilter,
    StationFilterFromJSON,
    StationFilterToJSON,
    StationInfoListChunk,
    StationInfoListChunkFromJSON,
    StationInfoListChunkToJSON,
    StationListChunk,
    StationListChunkFromJSON,
    StationListChunkToJSON,
    StationMemo,
    StationMemoFromJSON,
    StationMemoToJSON,
    StationName,
    StationNameFromJSON,
    StationNameToJSON,
    UpdateStationVendorRequest,
    UpdateStationVendorRequestFromJSON,
    UpdateStationVendorRequestToJSON,
    UpsDetail,
    UpsDetailFromJSON,
    UpsDetailToJSON,
} from '../../library/openapi/models';

export interface DeleteLockedUpsHistoryRequest {
    manageSerial: number;
}

export interface DeleteStationRequest {
    stationID: number;
}

export interface GetErrorStationListRequest {
    page: number;
    size?: number;
    filter?: InfoFilter;
}

export interface GetErrorStationListFileRequest {
    filter?: InfoFilter;
    limit?: number | null;
}

export interface GetLockedUpsRequest {
    stationID: number;
    manageSerial: number;
}

export interface GetLockedUpsHistoryRequest {
    stationID: number;
    page: number;
    manageSerial: number;
    size?: number;
    isErrorOnly?: boolean;
}

export interface GetLockedUpsHistoryFileRequest {
    stationID: number;
    manageSerial: number;
    limit?: number | null;
}

export interface GetStationRequest {
    stationID: number;
}

export interface GetStationInfoListRequest {
    page: number;
    size?: number;
    filter?: InfoFilter;
}

export interface GetStationInfoListFileRequest {
    filter?: InfoFilter;
    limit?: number | null;
}

export interface GetStationListRequest {
    page: number;
    size?: number;
    filter?: StationFilter;
}

export interface GetStationListFileRequest {
    filter?: StationFilter;
}

export interface ImportStationsRequest {
    fileUpload: FileUpload;
}

export interface LockUpsOperationRequest {
    stationID: number;
    lockUpsRequest: LockUpsRequest;
}

export interface ReadUpsRequest {
    stationID: number;
    manageSerial: number;
}

export interface UnlockUpsRequest {
    stationID: number;
    manageSerial: number;
}

export interface UpdateStationMemoRequest {
    stationID: number;
    stationMemo: StationMemo;
}

export interface UpdateStationNameRequest {
    stationID: number;
    stationName: StationName;
}

export interface UpdateStationVendorOperationRequest {
    stationID: number;
    updateStationVendorRequest: UpdateStationVendorRequest;
}

/**
 * 
 */
export class StationApi extends runtime.BaseAPI {

        /**
     * 局に紐付けられているUPSの履歴消去
     */
    async deleteLockedUpsHistoryRaw(requestParameters: DeleteLockedUpsHistoryRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling deleteLockedUpsHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/history`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 局に紐付けられているUPSの履歴消去
     */
    async deleteLockedUpsHistory(requestParameters: DeleteLockedUpsHistoryRequest): Promise<void> {
        await this.deleteLockedUpsHistoryRaw(requestParameters);
    }

        /**
     * 局削除
     */
    async deleteStationRaw(requestParameters: DeleteStationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling deleteStation.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 局削除
     */
    async deleteStation(requestParameters: DeleteStationRequest): Promise<void> {
        await this.deleteStationRaw(requestParameters);
    }

    /**
     * 異常状態の基地局一覧の取得
     */
    async getErrorStationListRaw(requestParameters: GetErrorStationListRequest): Promise<runtime.ApiResponse<ErrorStationListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getErrorStationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;

            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area;}
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId;}
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial;}
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName;}
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber;}

        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/monitoring/errors/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => ErrorStationListChunkFromJSON(jsonValue));
    }

    /**
     * 異常状態の基地局一覧の取得
     */
    async getErrorStationList(requestParameters: GetErrorStationListRequest): Promise<ErrorStationListChunk> {
        const response = await this.getErrorStationListRaw(requestParameters);
        return await response.value();
    }

        /**
     * 異常状態の基地局一覧の取得
     */
    async getErrorStationListFileRaw(requestParameters: GetErrorStationListFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;
            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area;}
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId;}
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial;}
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName;}
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber;}
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/monitoring/errors/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * 異常状態の基地局一覧の取得
     */
    async getErrorStationListFile(requestParameters: GetErrorStationListFileRequest): Promise<FileResponse> {
        const response = await this.getErrorStationListFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * 局に紐付けられているUPS情報の取得
     */
    async getLockedUpsRaw(requestParameters: GetLockedUpsRequest): Promise<runtime.ApiResponse<UpsDetail>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling getLockedUps.');
        }

        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getLockedUps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsDetailFromJSON(jsonValue));
    }

    /**
     * 局に紐付けられているUPS情報の取得
     */
    async getLockedUps(requestParameters: GetLockedUpsRequest): Promise<UpsDetail> {
        const response = await this.getLockedUpsRaw(requestParameters);
        return await response.value();
    }

        /**
     * 局に紐付けられているUPSの履歴取得
     */
    async getLockedUpsHistoryRaw(requestParameters: GetLockedUpsHistoryRequest): Promise<runtime.ApiResponse<LockedUpsHistoryListChunk>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling getLockedUpsHistory.');
        }

        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getLockedUpsHistory.');
        }

        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getLockedUpsHistory.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.isErrorOnly !== undefined) {
            queryParameters['isErrorOnly'] = requestParameters.isErrorOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/history/{stationID}/{page}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))).replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LockedUpsHistoryListChunkFromJSON(jsonValue));
    }

    /**
     * 局に紐付けられているUPSの履歴取得
     */
    async getLockedUpsHistory(requestParameters: GetLockedUpsHistoryRequest): Promise<LockedUpsHistoryListChunk> {
        const response = await this.getLockedUpsHistoryRaw(requestParameters);
        return await response.value();
    }

        /**
     * 局に紐付けられているUPSの履歴取得
     */
    async getLockedUpsHistoryFileRaw(requestParameters: GetLockedUpsHistoryFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling getLockedUpsHistoryFile.');
        }

        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling getLockedUpsHistoryFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/history/{stationID}/export`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * 局に紐付けられているUPSの履歴取得
     */
    async getLockedUpsHistoryFile(requestParameters: GetLockedUpsHistoryFileRequest): Promise<FileResponse> {
        const response = await this.getLockedUpsHistoryFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * 対象の局情報取得
     */
    async getStationRaw(requestParameters: GetStationRequest): Promise<runtime.ApiResponse<StationDetailWithUps>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling getStation.');
        }

        const queryParameters: runtime.HTTPQuery = {};
        
        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StationDetailWithUpsFromJSON(jsonValue));
    }

    /**
     * 対象の局情報取得
     */
    async getStation(requestParameters: GetStationRequest): Promise<StationDetailWithUps> {
        const response = await this.getStationRaw(requestParameters);
        return await response.value();
    }

     /**
     * 基地局関連情報一覧
     */
    async getStationInfoListRaw(requestParameters: GetStationInfoListRequest): Promise<runtime.ApiResponse<StationInfoListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getStationInfoList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;

            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area;}
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId;}
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial;}
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName;}
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber;}

        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/info/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StationInfoListChunkFromJSON(jsonValue));
    }

    /**
     * 基地局関連情報一覧
     */
    async getStationInfoList(requestParameters: GetStationInfoListRequest): Promise<StationInfoListChunk> {
        const response = await this.getStationInfoListRaw(requestParameters);
        return await response.value();
    }

        /**
     * 基地局関連情報一覧
     */
    async getStationInfoListFileRaw(requestParameters: GetStationInfoListFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            // queryParameters['filter'] = requestParameters.filter;
            if(requestParameters.filter.area){queryParameters['filter.area'] = requestParameters.filter.area;}
            if(requestParameters.filter.deviceId){queryParameters['filter.deviceId'] = requestParameters.filter.deviceId;}
            if(requestParameters.filter.manageSerial){queryParameters['filter.manageSerial'] = requestParameters.filter.manageSerial;}
            if(requestParameters.filter.stationName){queryParameters['filter.stationName'] = requestParameters.filter.stationName;}
            if(requestParameters.filter.stationNumber){queryParameters['filter.stationNumber'] = requestParameters.filter.stationNumber;}
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/info/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * 基地局関連情報一覧
     */
    async getStationInfoListFile(requestParameters: GetStationInfoListFileRequest): Promise<FileResponse> {
        const response = await this.getStationInfoListFileRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getStationListRaw(requestParameters: GetStationListRequest): Promise<runtime.ApiResponse<StationListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getStationList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            if(requestParameters.filter.name){
                queryParameters['filter.name'] = requestParameters.filter.name ;
            }

            if(requestParameters.filter.number){
                queryParameters['filter.number'] = requestParameters.filter.number;
            }
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/list/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StationListChunkFromJSON(jsonValue));
    }

    /**
     */
    async getStationList(requestParameters: GetStationListRequest): Promise<StationListChunk> {
        const response = await this.getStationListRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async getStationListFileRaw(requestParameters: GetStationListFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = StationFilterToJSON(requestParameters.filter);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/list/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     */
    async getStationListFile(requestParameters: GetStationListFileRequest): Promise<FileResponse> {
        const response = await this.getStationListFileRaw(requestParameters);
        return await response.value();
    }
    
    /**
     * 選択可能なベンダ一覧
     */
    async getVendorListRaw(): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/vendor/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * 選択可能なベンダ一覧
     */
    async getVendorList(): Promise<Array<string>> {
        const response = await this.getVendorListRaw();
        return await response.value();
    }

    /**
     * 基地局インポート
     */
    async importStationsRaw(requestParameters: ImportStationsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileUpload === null || requestParameters.fileUpload === undefined) {
            throw new runtime.RequiredError('fileUpload','Required parameter requestParameters.fileUpload was null or undefined when calling importStations.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileUploadToJSON(requestParameters.fileUpload),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 基地局インポート
     */
    async importStations(requestParameters: ImportStationsRequest): Promise<void> {
        await this.importStationsRaw(requestParameters);
    }

    /**
     * UPSの設置
     */
    async lockUpsRaw(requestParameters: LockUpsOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling lockUps.');
        }

        if (requestParameters.lockUpsRequest === null || requestParameters.lockUpsRequest === undefined) {
            throw new runtime.RequiredError('lockUpsRequest','Required parameter requestParameters.lockUpsRequest was null or undefined when calling lockUps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LockUpsRequestToJSON(requestParameters.lockUpsRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * UPSの設置
     */
    async lockUps(requestParameters: LockUpsOperationRequest): Promise<void> {
        await this.lockUpsRaw(requestParameters);
    }

    /**
     * 指定のUPSが設置可能か確認する
     */
    async readUpsRaw(requestParameters: ReadUpsRequest): Promise<runtime.ApiResponse<UpsDetail>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling readUps.');
        }

        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling readUps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UpsDetailFromJSON(jsonValue));
    }

    /**
     * 指定のUPSが設置可能か確認する
     */
    async readUps(requestParameters: ReadUpsRequest): Promise<UpsDetail> {
        const response = await this.readUpsRaw(requestParameters);
        return await response.value();
    }

    /**
     * UPSの設置解除
     */
    async unlockUpsRaw(requestParameters: UnlockUpsRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling unlockUps.');
        }

        if (requestParameters.manageSerial === null || requestParameters.manageSerial === undefined) {
            throw new runtime.RequiredError('manageSerial','Required parameter requestParameters.manageSerial was null or undefined when calling unlockUps.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.manageSerial !== undefined) {
            queryParameters['manageSerial'] = requestParameters.manageSerial;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/ups/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * UPSの設置解除
     */
    async unlockUps(requestParameters: UnlockUpsRequest): Promise<void> {
        await this.unlockUpsRaw(requestParameters);
    }

        /**
     * メモの更新
     */
    async updateStationMemoRaw(requestParameters: UpdateStationMemoRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling updateStationMemo.');
        }

        if (requestParameters.stationMemo === null || requestParameters.stationMemo === undefined) {
            throw new runtime.RequiredError('stationMemo','Required parameter requestParameters.stationMemo was null or undefined when calling updateStationMemo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/{stationID}/memo`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StationMemoToJSON(requestParameters.stationMemo),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * メモの更新
     */
    async updateStationMemo(requestParameters: UpdateStationMemoRequest): Promise<void> {
        await this.updateStationMemoRaw(requestParameters);
    }

    /**
     * 局名変更
     */
    async updateStationNameRaw(requestParameters: UpdateStationNameRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling updateStationName.');
        }

        if (requestParameters.stationName === null || requestParameters.stationName === undefined) {
            throw new runtime.RequiredError('stationName','Required parameter requestParameters.stationName was null or undefined when calling updateStationName.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/{stationID}/name`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StationNameToJSON(requestParameters.stationName),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 局名変更
     */
    async updateStationName(requestParameters: UpdateStationNameRequest): Promise<void> {
        await this.updateStationNameRaw(requestParameters);
    }

    /**
     * 局情報の更新
     */
    async updateStationVendorRaw(requestParameters: UpdateStationVendorOperationRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.stationID === null || requestParameters.stationID === undefined) {
            throw new runtime.RequiredError('stationID','Required parameter requestParameters.stationID was null or undefined when calling updateStationVendor.');
        }

        if (requestParameters.updateStationVendorRequest === null || requestParameters.updateStationVendorRequest === undefined) {
            throw new runtime.RequiredError('updateStationVendorRequest','Required parameter requestParameters.updateStationVendorRequest was null or undefined when calling updateStationVendor.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/station/{stationID}`.replace(`{${"stationID"}}`, encodeURIComponent(String(requestParameters.stationID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateStationVendorRequestToJSON(requestParameters.updateStationVendorRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * 局情報の更新
     */
    async updateStationVendor(requestParameters: UpdateStationVendorOperationRequest): Promise<void> {
        await this.updateStationVendorRaw(requestParameters);
    }

}
