/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface StationMemo
 */
export interface StationMemo {
    /**
     * 
     * @type {string}
     * @memberof StationMemo
     */
    memo?: string;
}

export function StationMemoFromJSON(json: any): StationMemo {
    return StationMemoFromJSONTyped(json, false);
}

export function StationMemoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationMemo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
    };
}

export function StationMemoToJSON(value?: StationMemo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'memo': value.memo,
    };
}


