import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { useApiResponse } from "../Components/api/ApiWrapper";
import {
  StationApi,
  GetStationInfoListRequest,
  GetStationInfoListFileRequest,
} from "../Components/api/StationApi";
import { TableBind } from "../Components/AllStationListTable";
import { isNaNModified } from "../Components/myClass/checkValue";
import { DownloadExcel } from "../Components/ExcelExport";
import "../css/Common.css";

/* 
局一覧
*/

interface Props extends RouteComponentProps {}

const timer = 60000;
let autoReloader: NodeJS.Timeout;
let intervalId: NodeJS.Timeout[] = new Array<NodeJS.Timeout>();

const AllStation: React.FC<Props> = ({ history }: Props) => {
  const [isAutoReload, ToggleisAutoReload] = React.useState<boolean>(false);
  const [selectArea, setSelectArea] = React.useState<string>("");
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [searchClickCount, setSearchClickCount] = React.useState(0);

  const stationApi = new StationApi();

  const onGetListError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("データ取得失敗");
  };

  const [nowListLoading, allStationList, loader] = useApiResponse(
    stationApi.getStationInfoListRaw.bind(stationApi),
    onGetListError
  );

  const [nowDownload, listDownload, downloadLoader] = useApiResponse(
    stationApi.getStationInfoListFileRaw.bind(stationApi),
    onGetListError
  );

  React.useEffect(() => {
    loader({ page: 0, size: 50 });
  }, []);

  const reqParam: GetStationInfoListRequest = { page: 0, size: 50 };

  if (selectArea) {
    if (!reqParam.filter) {
      reqParam.filter = {};
    }
    reqParam.filter.area = selectArea;
  }

  if (searchValue) {
    if (!reqParam.filter) {
      reqParam.filter = {};
    }
    reqParam.filter.deviceId = searchValue;
    reqParam.filter.stationName = searchValue;
    reqParam.filter.stationNumber = searchValue;

    if (!isNaNModified(searchValue)) {
      reqParam.filter.manageSerial = Number(searchValue);
    }
  }

  const getStationList = (page: number = 0) => {
    const param: GetStationInfoListRequest = { ...reqParam };
    param.page = page;
    loader(param);
  };

  const onChangeSelectArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectArea(event.target.value);
  };

  const onChangeSearchValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleSearchOnClick = () => {
    loader(reqParam);
    setSearchClickCount(searchClickCount + 1);
  };

  //詳細クリックイベント
  function onClickDetail(stationID: number) {
    history.push(`/StationDetail3/${stationID}`);
  }

  //#region 自動更新機能 =========================
  const [reloader, setReloader] = React.useState<NodeJS.Timeout>();
  const refReloader = React.useRef(reloader);

  function startAutoreload() {
    autoReloader = setInterval(() => {
      loader(reqParam);
    }, timer);
    setReloader(autoReloader);
    intervalId.push(autoReloader);
  }

  function stopAutoReload() {
    if (reloader) {
      clearInterval(reloader);
    }
  }

  //自動更新機能
  React.useEffect(() => {
    if (isAutoReload) {
      stopAutoReload();
      startAutoreload();
    } else {
      stopAutoReload();
    }
  }, [isAutoReload, searchClickCount]);

  //アンマウント時に自動更新機能をOFF
  React.useEffect(() => {
    return () => {
      for (var item of intervalId) {
        clearInterval(item);
      }
    };
  }, []);

  const handleToggleisAutoReload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      ToggleisAutoReload(event.target.checked);
      // loader(reqParam);
    },
    [isAutoReload]
  );

  //#endregion =========================

  const handleRefleshOnClick = () => {
    // reloadメソッドによりページをリロード
    // window.location.reload();
    loader(reqParam);
  };

  //excel DL
  const DownLoadExcel = React.useCallback(() => {
    let exportParam: GetStationInfoListFileRequest = {};
    if (selectArea) {
      if (!exportParam.filter) {
        exportParam.filter = {};
      }
      exportParam.filter.area = selectArea;
    }

    if (searchValue) {
      if (!exportParam.filter) {
        exportParam.filter = {};
      }
      exportParam.filter.deviceId = searchValue;
      exportParam.filter.stationName = searchValue;
      exportParam.filter.stationNumber = searchValue;

      if (!isNaNModified(searchValue)) {
        exportParam.filter.manageSerial = Number(searchValue);
      }
    }

    downloadLoader(exportParam);
  }, [listDownload, selectArea, searchValue]);

  React.useEffect(() => {
    if (listDownload) {
      if (listDownload.filename && listDownload.binary) {
        DownloadExcel({
          fileName: listDownload.filename,
          binary: listDownload.binary,
        });
      }
    }
  }, [listDownload]);

  return (
    <React.Fragment>
      <h4 className="p-3 font-wight-bold">
        局一覧
        <button
          className="btn btn-sm btn-primary shadow-sm px-3 ml-3 search-width1"
          onClick={handleRefleshOnClick}
        >
          更新
        </button>
        <div className="form-check form-check-inline ml-4 small d-none">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkAlertOnly"
            checked={isAutoReload}
            onChange={handleToggleisAutoReload}
          />
          <label className="form-check-label" htmlFor="checkAlertOnly">
            自動更新
          </label>
        </div>
      </h4>

      <div className="container-flex mx-5">
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="d-flex">
              <label
                htmlFor=""
                className="col-form-label col-form-label-sm search-width1"
              >
                エリア
              </label>
              <select
                name="selectArea"
                id="selectArea"
                className="form-control form-control-sm"
                onChange={onChangeSelectArea}
              >
                <option value="" selected>
                  すべて
                </option>
                <option value="関東">関東</option>
                <option value="中部">中部</option>
                <option value="関西">関西</option>
              </select>
            </div>
            <div className="d-flex ml-3">
              <input
                id="input_AnySearch"
                className="form-control form-control-sm"
                type="text"
                value={searchValue}
                onChange={onChangeSearchValue}
                placeholder="局番 局名 ﾃﾞﾊﾞｲｽ管理番号 ﾃﾞﾊﾞｲｽID"
              />
            </div>
            <div className="d-flex ml-3">
              <button
                className="btn btn-sm btn-primary shadow-sm px-3"
                onClick={handleSearchOnClick}
              >
                検索
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4 d-flex justify-content-end">
          <div className="d-flex flex-row">
            <small className="mr-3">
              全{allStationList?.totalCount ?? 0}件
            </small>
            <small>
              <button
                className="btn btn-sm btn-success shadow-sm py-0"
                onClick={DownLoadExcel}
              >
                {nowDownload ? "ダウンロード中･･･" : "EXCEL出力"}
              </button>
            </small>
          </div>
        </div>
        <div className="d-flex flex-row">
          {nowListLoading ? (
            "now loading..."
          ) : (
            <TableBind
              allStationListChunk={allStationList!}
              clickDetail={onClickDetail}
              clickPager={getStationList}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AllStation;
