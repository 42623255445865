/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    ChunkedListBase,
    ChunkedListBaseFromJSON,
    ChunkedListBaseFromJSONTyped,
    ChunkedListBaseToJSON,
    LockedUpsHistory,
    LockedUpsHistoryFromJSON,
    LockedUpsHistoryFromJSONTyped,
    LockedUpsHistoryToJSON,
    LockedUpsHistoryListChunkAllOf,
    LockedUpsHistoryListChunkAllOfFromJSON,
    LockedUpsHistoryListChunkAllOfFromJSONTyped,
    LockedUpsHistoryListChunkAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface LockedUpsHistoryListChunk
 */
export interface LockedUpsHistoryListChunk {
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistoryListChunk
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistoryListChunk
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistoryListChunk
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistoryListChunk
     */
    pageCount: number;
    /**
     * 
     * @type {Array<LockedUpsHistory>}
     * @memberof LockedUpsHistoryListChunk
     */
    chunk: Array<LockedUpsHistory>;
}

export function LockedUpsHistoryListChunkFromJSON(json: any): LockedUpsHistoryListChunk {
    return LockedUpsHistoryListChunkFromJSONTyped(json, false);
}

export function LockedUpsHistoryListChunkFromJSONTyped(json: any, ignoreDiscriminator: boolean): LockedUpsHistoryListChunk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalCount': json['totalCount'],
        'pageCount': json['pageCount'],
        'chunk': ((json['chunk'] as Array<any>).map(LockedUpsHistoryFromJSON)),
    };
}

export function LockedUpsHistoryListChunkToJSON(value?: LockedUpsHistoryListChunk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'totalCount': value.totalCount,
        'pageCount': value.pageCount,
        'chunk': ((value.chunk as Array<any>).map(LockedUpsHistoryToJSON)),
    };
}


