/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface LockedUpsHistory
 */
export interface LockedUpsHistory {
    /**
     * 
     * @type {Date}
     * @memberof LockedUpsHistory
     */
    receivedDate?: Date;
    /**
     * イベント文字�E
     * @type {string}
     * @memberof LockedUpsHistory
     */
    trigger?: string;
    /**
     * 
     * @type {boolean}
     * @memberof LockedUpsHistory
     */
    isCommercialPowerError?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof LockedUpsHistory
     */
    isUpsError?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof LockedUpsHistory
     */
    isBatteryError?: boolean;
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistory
     */
    passedDays?: number;
    /**
     * 
     * @type {string}
     * @memberof LockedUpsHistory
     */
    statusCode?: string;
    /**
     * 
     * @type {number}
     * @memberof LockedUpsHistory
     */
    seqNumber?: number | null;
}

export function LockedUpsHistoryFromJSON(json: any): LockedUpsHistory {
    return LockedUpsHistoryFromJSONTyped(json, false);
}

export function LockedUpsHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LockedUpsHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'receivedDate': !exists(json, 'receivedDate') ? undefined : (new Date(json['receivedDate'])),
        'trigger': !exists(json, 'trigger') ? undefined : json['trigger'],
        'isCommercialPowerError': !exists(json, 'isCommercialPowerError') ? undefined : json['isCommercialPowerError'],
        'isUpsError': !exists(json, 'isUpsError') ? undefined : json['isUpsError'],
        'isBatteryError': !exists(json, 'isBatteryError') ? undefined : json['isBatteryError'],
        'passedDays': !exists(json, 'passedDays') ? undefined : json['passedDays'],
        'statusCode': !exists(json, 'statusCode') ? undefined : json['statusCode'],
        'seqNumber': !exists(json, 'seqNumber') ? undefined : json['seqNumber'],
    };
}

export function LockedUpsHistoryToJSON(value?: LockedUpsHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'receivedDate': value.receivedDate === undefined ? undefined : (value.receivedDate.toISOString()),
        'trigger': value.trigger,
        'isCommercialPowerError': value.isCommercialPowerError,
        'isUpsError': value.isUpsError,
        'isBatteryError': value.isBatteryError,
        'passedDays': value.passedDays,
        'statusCode': value.statusCode,
        'seqNumber': value.seqNumber,
    };
}


