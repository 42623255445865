/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface PasswordResetResult
 */
export interface PasswordResetResult {
    /**
     * TOTPの共通鍵
     * @type {string}
     * @memberof PasswordResetResult
     */
    otpKey: string;
}

export function PasswordResetResultFromJSON(json: any): PasswordResetResult {
    return PasswordResetResultFromJSONTyped(json, false);
}

export function PasswordResetResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordResetResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'otpKey': json['otpKey'],
    };
}

export function PasswordResetResultToJSON(value?: PasswordResetResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'otpKey': value.otpKey,
    };
}


