/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "./runtime";
import {
  FailedResponse,
  FailedResponseFromJSON,
  FailedResponseToJSON,
  LockoutResult,
  LockoutResultFromJSON,
  LockoutResultToJSON,
  LoginRequest,
  LoginRequestFromJSON,
  LoginRequestToJSON,
  LoginResult,
  LoginResultFromJSON,
  LoginResultToJSON,
  TokenUpdateResult,
  TokenUpdateResultFromJSON,
  TokenUpdateResultToJSON,
} from "../../library/openapi/models";

export interface LoginOperationRequest {
  loginRequest: LoginRequest;
}

/**
 *
 */
export class LoginApi extends runtime.BaseAPI {
  /**
   * ログイン
   */
  async loginRaw(
    requestParameters: LoginOperationRequest
  ): Promise<runtime.ApiResponse<LoginResult>> {
    if (
      requestParameters.loginRequest === null ||
      requestParameters.loginRequest === undefined
    ) {
      throw new runtime.RequiredError(
        "loginRequest",
        "Required parameter requestParameters.loginRequest was null or undefined when calling login."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    try {
      const response = await this.request({
        path: `/api/login`,
        method: "POST",
        headers: headerParameters,
        query: queryParameters,
        body: LoginRequestToJSON(requestParameters.loginRequest),
      });

      return new runtime.JSONApiResponse(response, (jsonValue) =>
        LoginResultFromJSON(jsonValue)
      );
    } catch (e) {
      return new runtime.JSONApiResponse(e, (jsonValue) =>
        LoginResultFromJSON(jsonValue)
      );
    }
    
  }

  /**
   * ログイン
   */
  async login(requestParameters: LoginOperationRequest): Promise<LoginResult> {
    const response = await this.loginRaw(requestParameters);
    return await response.value();
  }

  /**
   * トークン更新
   */
  async updateTokenRaw(): Promise<runtime.ApiResponse<TokenUpdateResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    // if (this.configuration && this.configuration.accessToken) {
    //   const token = this.configuration.accessToken;
    //   const tokenString =
    //     typeof token === "function" ? token("JWT", []) : token;

    //   if (tokenString) {
    //     headerParameters["Authorization"] = `Bearer ${tokenString}`;
    //   }
    // }

    if(sessionStorage.getItem('updateToken')){
      const token = sessionStorage.getItem('updateToken');
      const tokenString = token;

      if (tokenString) {
        headerParameters["Authorization"] = `Bearer ${tokenString}`;
      }
    }

    const response = await this.request({
      path: `/api/login`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters,
    });

    return new runtime.JSONApiResponse(response, (jsonValue) =>
      TokenUpdateResultFromJSON(jsonValue)
    );
  }

  /**
   * トークン更新
   */
  async updateToken(): Promise<TokenUpdateResult> {
    const response = await this.updateTokenRaw();
    return await response.value();
  }
}
