/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface FileUpload
 */
export interface FileUpload {
    /**
     * 
     * @type {string}
     * @memberof FileUpload
     */
    filename: string;
    /**
     * Base64 encoded binary
     * @type {string}
     * @memberof FileUpload
     */
    binary: string;
}

export function FileUploadFromJSON(json: any): FileUpload {
    return FileUploadFromJSONTyped(json, false);
}

export function FileUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'binary': json['binary'],
    };
}

export function FileUploadToJSON(value?: FileUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'binary': value.binary,
    };
}


