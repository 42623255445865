/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface FileResponse
 */
export interface FileResponse {
    /**
     * 
     * @type {string}
     * @memberof FileResponse
     */
    filename?: string;
    /**
     * Base64 encoded binary
     * @type {string}
     * @memberof FileResponse
     */
    binary?: string;
}

export function FileResponseFromJSON(json: any): FileResponse {
    return FileResponseFromJSONTyped(json, false);
}

export function FileResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'filename': !exists(json, 'filename') ? undefined : json['filename'],
        'binary': !exists(json, 'binary') ? undefined : json['binary'],
    };
}

export function FileResponseToJSON(value?: FileResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'filename': value.filename,
        'binary': value.binary,
    };
}


