/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    StationDetail,
    StationDetailFromJSON,
    StationDetailFromJSONTyped,
    StationDetailToJSON,
    StationDetailWithUpsAllOf,
    StationDetailWithUpsAllOfFromJSON,
    StationDetailWithUpsAllOfFromJSONTyped,
    StationDetailWithUpsAllOfToJSON,
    Ups,
    UpsFromJSON,
    UpsFromJSONTyped,
    UpsToJSON,
} from './';

/**
 * 
 * @export
 * @interface StationDetailWithUps
 */
export interface StationDetailWithUps {
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    stationId: number;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    stationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    stationName: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    sectorId: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    terminalType: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    channelType: number;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    area?: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    upsInstalledCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    upsInstalledYear?: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    upsInstalledMonth?: number;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    auAdjoined?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    auName?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    phase?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    perfecture?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    municipality?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    building?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    stationStyle?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    powerSupply?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    stationType?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    sectorOrOmni?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    vendor2Plus?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StationDetailWithUps
     */
    cRanFlag?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    keyOwner?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    governmentOffice?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    ups1?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    ups2?: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    ups3?: string;
    /**
     * UPSベンダ
     * @type {string}
     * @memberof StationDetailWithUps
     */
    vendor?: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithUps
     */
    priority?: number | null;
    /**
     * 行動計画
     * @type {string}
     * @memberof StationDetailWithUps
     */
    actionScedule?: string;
    /**
     * 
     * @type {Ups}
     * @memberof StationDetailWithUps
     */
    ups?: Ups;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithUps
     */
    memo?: string;
}

export function StationDetailWithUpsFromJSON(json: any): StationDetailWithUps {
    return StationDetailWithUpsFromJSONTyped(json, false);
}

export function StationDetailWithUpsFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationDetailWithUps {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stationId': json['stationId'],
        'stationNumber': json['stationNumber'],
        'stationName': json['stationName'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'sectorId': json['sectorId'],
        'terminalType': json['terminalType'],
        'channelType': json['channelType'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'upsInstalledCount': !exists(json, 'upsInstalledCount') ? undefined : json['upsInstalledCount'],
        'upsInstalledYear': !exists(json, 'upsInstalledYear') ? undefined : json['upsInstalledYear'],
        'upsInstalledMonth': !exists(json, 'upsInstalledMonth') ? undefined : json['upsInstalledMonth'],
        'auAdjoined': !exists(json, 'auAdjoined') ? undefined : json['auAdjoined'],
        'auName': !exists(json, 'auName') ? undefined : json['auName'],
        'phase': !exists(json, 'phase') ? undefined : json['phase'],
        'perfecture': !exists(json, 'perfecture') ? undefined : json['perfecture'],
        'municipality': !exists(json, 'municipality') ? undefined : json['municipality'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'building': !exists(json, 'building') ? undefined : json['building'],
        'stationStyle': !exists(json, 'stationStyle') ? undefined : json['stationStyle'],
        'powerSupply': !exists(json, 'powerSupply') ? undefined : json['powerSupply'],
        'stationType': !exists(json, 'stationType') ? undefined : json['stationType'],
        'sectorOrOmni': !exists(json, 'sectorOrOmni') ? undefined : json['sectorOrOmni'],
        'vendor2Plus': !exists(json, 'vendor2Plus') ? undefined : json['vendor2Plus'],
        'cRanFlag': !exists(json, 'cRanFlag') ? undefined : json['cRanFlag'],
        'keyOwner': !exists(json, 'keyOwner') ? undefined : json['keyOwner'],
        'governmentOffice': !exists(json, 'governmentOffice') ? undefined : json['governmentOffice'],
        'ups1': !exists(json, 'ups1') ? undefined : json['ups1'],
        'ups2': !exists(json, 'ups2') ? undefined : json['ups2'],
        'ups3': !exists(json, 'ups3') ? undefined : json['ups3'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'actionScedule': !exists(json, 'actionScedule') ? undefined : json['actionScedule'],
        'ups': !exists(json, 'ups') ? undefined : UpsFromJSON(json['ups']),
        'memo': !exists(json, 'memo') ? undefined : json['memo'],
    };
}

export function StationDetailWithUpsToJSON(value?: StationDetailWithUps | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stationId': value.stationId,
        'stationNumber': value.stationNumber,
        'stationName': value.stationName,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'sectorId': value.sectorId,
        'terminalType': value.terminalType,
        'channelType': value.channelType,
        'area': value.area,
        'upsInstalledCount': value.upsInstalledCount,
        'upsInstalledYear': value.upsInstalledYear,
        'upsInstalledMonth': value.upsInstalledMonth,
        'auAdjoined': value.auAdjoined,
        'auName': value.auName,
        'phase': value.phase,
        'perfecture': value.perfecture,
        'municipality': value.municipality,
        'address': value.address,
        'building': value.building,
        'stationStyle': value.stationStyle,
        'powerSupply': value.powerSupply,
        'stationType': value.stationType,
        'sectorOrOmni': value.sectorOrOmni,
        'vendor2Plus': value.vendor2Plus,
        'cRanFlag': value.cRanFlag,
        'keyOwner': value.keyOwner,
        'governmentOffice': value.governmentOffice,
        'ups1': value.ups1,
        'ups2': value.ups2,
        'ups3': value.ups3,
        'vendor': value.vendor,
        'priority': value.priority,
        'actionScedule': value.actionScedule,
        'ups': UpsToJSON(value.ups),
        'memo': value.memo,
    };
}


