import { faDivide } from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useApiResponse } from "../Components/api/ApiWrapper";
import {
  StationApi,
  GetLockedUpsHistoryRequest,
  GetLockedUpsHistoryFileRequest,
  UpdateStationMemoRequest,
} from "../Components/api/StationApi";
import { UpsApi } from "../Components/api/UpsApi";
import { TableBind } from "../Components/LockedUPSHistoryTable";
import { formatDate } from "../Components/myClass/formatAnyDate";
import { DownloadExcel } from "../Components/ExcelExport";
import "../css/Common.css";
import { RoleFilter } from "Components/RoleFilter";
import { UserRole } from "Components/api/UserRole";

interface paramDesign {
  stationID: string | undefined;
}

interface Props extends RouteComponentProps<paramDesign> {}

const StationDetail3: React.FC<Props> = ({ match, history }: Props) => {
  const stationApi = new StationApi();
  const upsApi = new UpsApi();

  const stationID = match.params.stationID ? parseInt(match.params.stationID, 10) : undefined;

  const onGetStationInfoError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("局情報取得失敗");
  };

  const onGetDeviceInfoError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("デバイス情報取得失敗");
  };

  const onGetUPSLogError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("UPSログ取得取得失敗");
  };

  const onUPSLogResetError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("UPSログの初期化に失敗");
  };

  const onUPSUnlockError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    if (err.status === 409) {
      alert("デバイス解除失敗：対象のデバイスは設置されていません");
    } else {
      alert("デバイスの解除に失敗");
    }
  };

  const onUpdateMemoError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("メモの保存に失敗しました");
  };

  const [isErrorOnly, SetIsErrorOnly] = React.useState(false);

  //局情報取得
  const [nowLoadingForStation, stationInfo, stationLoader] = useApiResponse(
    stationApi.getStationRaw.bind(stationApi),
    onGetStationInfoError
  );

  //デバイス情報取得
  const [nowLoadingForDevice, DeviceInfo, DeviceLoader] = useApiResponse(
    upsApi.getUpsRaw.bind(upsApi),
    onGetDeviceInfoError
  );

  //UPSログ取得
  const [nowLoadingForUPSLog, UPSLog, UPSLogLoader] = useApiResponse(
    stationApi.getLockedUpsHistoryRaw.bind(stationApi),
    onGetUPSLogError
  );

  //UPS受信状態初期化
  const [
    nowLoadingForUPSLogReset,
    UPSLogReset,
    UPSLogResetLoader,
  ] = useApiResponse(
    stationApi.deleteLockedUpsHistoryRaw.bind(stationApi),
    onUPSLogResetError
  );

  //UPSの解除
  const [nowLoadingForUPSUnlock, UPSUnlock, UPSUnlockLoader] = useApiResponse(
    stationApi.unlockUpsRaw.bind(stationApi),
    onUPSUnlockError
  );

  //UPSログDL
  const [nowDownload, listDownload, downloadLoader] = useApiResponse(
    stationApi.getLockedUpsHistoryFileRaw.bind(stationApi),
    onGetUPSLogError
  );

  //メモ欄
  const [memo, setMemo] = React.useState<string>();
  const [nowLoadingUpdateMemo, updateMemo, UpdateMemoLoader] = useApiResponse(
    stationApi.updateStationMemoRaw.bind(stationApi),
    onUpdateMemoError
  );

  React.useEffect(() => {
    if (stationID) {
      stationLoader({
        stationID
      });
    }
  }, []);

  //局にデバイスIDが紐づいていれば実行
  React.useEffect(() => {
    if (stationInfo?.ups?.manageSerial && stationID) {
      DeviceLoader({ manageSerial: Number(stationInfo?.ups?.manageSerial) });
      UPSLogLoader({
        stationID,
        manageSerial: Number(stationInfo?.ups?.manageSerial),
        page: 0,
        size: 10,
        isErrorOnly: isErrorOnly,
      });
    }
  }, [stationInfo?.ups?.manageSerial, isErrorOnly]);

  //受信状態の初期化実行
  const handleClearUPSStatus = React.useCallback(() => {
    if (!window.confirm("受信状態を初期化します。よろしいですか？")) {
      return;
    }

    if (stationInfo?.ups?.manageSerial) {
      UPSLogResetLoader({ manageSerial: stationInfo?.ups?.manageSerial });

      //ログ再取得
      if (stationID) {
        // UPSLogLoader({
        //   stationNumber: stationInfo?.stationNumber,
        //   manageSerial: Number(stationInfo?.ups?.manageSerial),
        //   channel: match.params.channel
        //     ? Number(match.params.channel)
        //     : undefined,
        //   page: 0,
        //   size: 10,
        // });

        // window.location.reload();
        history.go(0);
      }
    } else {
      alert("デバイス管理番号を取得できなかったため、初期化に失敗しました");
    }
  }, [stationInfo?.ups?.manageSerial]);

  //UPSの紐づけ解除実行
  const handleUnlockUpsRaw = React.useCallback(() => {
    if (!window.confirm("デバイスを解除します。よろしいですか？")) {
      return;
    }

    if (stationID && stationInfo?.ups?.manageSerial) {
      UPSUnlockLoader({
        stationID,
        manageSerial: stationInfo?.ups?.manageSerial,
      });

      //局情報取得し直し
      if (stationID) {
        // stationLoader({
        //   stationNumber: match.params.stationNum,
        //   channel: match.params.channel
        //     ? Number(match.params.channel)
        //     : undefined,
        // });
        // window.location.reload();
        history.go(0);
      }
    } else {
      alert(
        "局番 または デバイス管理番号を取得できなかったため、初期化に失敗しました"
      );
    }
  }, [stationInfo?.ups?.manageSerial]);

  //デバイス設置ページへ
  const clickSetDevice = () => {
    history.push(
      `/SetDevice/${stationID}`
    );
  };

  //UPSログのページング用
  const PagingUPSLog = (page: number = 0) => {
    if (stationID && stationInfo?.ups?.manageSerial) {
      const param: GetLockedUpsHistoryRequest = {
        stationID,
        manageSerial: Number(stationInfo?.ups?.manageSerial),
        page: page,
        size: 10,
      };

      UPSLogLoader(param);
    }
  };

  //excel DL
  const DownLoadExcel = React.useCallback(() => {
    if (stationInfo?.ups?.manageSerial && stationID) {
      let exportParam: GetLockedUpsHistoryFileRequest = {
        stationID,
        manageSerial: Number(stationInfo?.ups?.manageSerial)
      };
      downloadLoader(exportParam);
    } else {
      alert("デバイス管理番号の取得に失敗しました。ダウンロードを中止します");
    }
  }, [listDownload, stationInfo?.ups?.manageSerial]);

  React.useEffect(() => {
    if (listDownload) {
      if (listDownload.filename && listDownload.binary) {
        DownloadExcel({
          fileName: listDownload.filename,
          binary: listDownload.binary,
        });
      }
    }
  }, [listDownload]);

  //メモ欄の制御
  React.useEffect(() => {
    setMemo(stationInfo?.memo);
  }, [stationInfo?.memo]);

  const saveMemo = React.useCallback(() => {
    if (stationInfo?.stationNumber && stationID) {
      let putParam: UpdateStationMemoRequest = {
        stationID,
        stationMemo: { memo: memo },
      };

      UpdateMemoLoader(putParam);
    }
  }, [memo, stationInfo?.stationNumber]);

  const onChangeMemo = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let limitedMemo = event.target.value;

    if (limitedMemo.length <= 200) {
      setMemo(limitedMemo);
    }
  };

  //NGのみ表示チェックボックス制御
  const handleToggleIsErrorOnly = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      SetIsErrorOnly(event.target.checked);
      // loader(reqParam);
    },
    [isErrorOnly]
  );

  return (
    <React.Fragment>
      <h4 className="m-3 font-wight-bold d-inline-block">基地局詳細</h4>
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={history.goBack}
      >
        前ページ
      </button>

      <div className="container mx-auto">
        <div className="row">
          {/* 左画面 */}
          <div className="col-12 col-sm-12 col-md-7 col-lg-7">
            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">エリア：</label>
              </div>
              <div className="col-auto">
                <label>
                  {nowLoadingForStation ? "now loading..." : stationInfo?.area}
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 ">
                <label className="font-weight-bold">局番：</label>
              </div>
              <div className="col-9 col-sm-9 col-md-3 col-lg-3">
                <label>{stationInfo?.stationNumber}</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3 ">
                <label className="font-weight-bold">局名：</label>
              </div>
              <div className="col-9 col-sm-9 col-md-3 col-lg-3">
                <label>{stationInfo?.stationName}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">緯度/経度：</label>
              </div>
              <div className="col-auto">
                <label>
                  {stationInfo?.latitude}&emsp;{stationInfo?.longitude}
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">UPS設置台数：</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label>{stationInfo?.upsInstalledCount}</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">セクタ/オムニ：</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label>{stationInfo?.sectorOrOmni}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">UPS設置年月：</label>
              </div>
              <div className="col-auto">
                <label>
                  {stationInfo?.upsInstalledYear}/
                  {stationInfo?.upsInstalledMonth}
                </label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">au併設：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.auAdjoined}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">市区町村：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.municipality}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">住所詳細：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.address}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">物件名：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.building}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">受電：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.powerSupply}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">局形態：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.stationStyle}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">局種別：</label>
              </div>
              <div className="col-auto">
                <label>{stationInfo?.stationType}</label>
              </div>
            </div>

            <div className="form-row">
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">端子タイプ：</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label>{stationInfo?.terminalType}</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label className="font-weight-bold">UPSベンダ名：</label>
              </div>
              <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                <label>{stationInfo?.vendor}</label>
              </div>
            </div>
          </div>
          {/* 右画面 */}
          <div className="col-12 col-sm-12 col-md-5 col-lg-5">
            <div className="form-row">
              <div className="col-12">
                <div className="card border-primary">
                  <div className="card-header py-1">デバイス情報</div>
                  <div className="card-body py-1">
                    <div className="form-row">
                      <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                        <label className="font-weight-bold">管理番号：</label>
                      </div>
                      <div className="col-auto">
                        <label>{stationInfo?.ups?.manageSerial}</label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                        <label className="font-weight-bold">デバイスID：</label>
                      </div>
                      <div className="col-auto">
                        <label>{stationInfo?.ups?.deviceId}</label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                        <label className="font-weight-bold">
                          初回受信日時：
                        </label>
                      </div>
                      <div className="col-auto">
                        <label>
                          {formatDate(DeviceInfo?.firstStatusLog?.receivedDate)}
                        </label>
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                        <label className="font-weight-bold">
                          最終受信日時：
                        </label>
                      </div>
                      <div className="col-auto">
                        <label>
                          {formatDate(DeviceInfo?.lastStatusLog?.receivedDate)}
                        </label>
                      </div>
                    </div>

                    <RoleFilter requirementRoles={[UserRole.ADMIN]}>
                      <div className="form-row mt-1">
                        <div className="col-12">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger shadow-sm"
                            onClick={handleClearUPSStatus}
                            disabled={stationInfo?.ups?.deviceId ? false : true}
                            // disabled={true}
                          >
                            {nowLoadingForUPSLogReset
                              ? "初期化中..."
                              : "受信状態を初期化"}
                          </button>
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            type="button"
                            className="btn btn-sm btn-danger shadow-sm"
                            onClick={handleUnlockUpsRaw}
                            disabled={stationInfo?.ups?.deviceId ? false : true}
                            // disabled={true}
                          >
                            {nowLoadingForUPSUnlock
                              ? "デバイス解除中..."
                              : "デバイスを解除する"}
                          </button>
                        </div>
                        <div className="col-12 mt-2">
                          <button
                            type="button"
                            className="btn btn-sm btn-success shadow-sm"
                            onClick={clickSetDevice}
                            disabled={stationInfo?.ups?.deviceId ? true : false}
                            // disabled={true}
                          >
                            デバイスを設定する
                          </button>
                        </div>
                      </div>
                    </RoleFilter>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="card border-info">
                  <div className="card-header py-1">
                    基地局 メモ&nbsp;
                    <small className="text-muted">200文字以内</small>
                  </div>
                  <div className="card-body  py-1">
                    <div className="form-row">
                      <div className="col-12">
                        <textarea
                          name="t_memo"
                          id="t_memo"
                          placeholder="メモ欄"
                          maxLength={200}
                          className="form-control form-control-sm"
                          rows={3}
                          onChange={onChangeMemo}
                          value={memo}
                        ></textarea>
                      </div>
                      <div className="col-12 text-right mt-2">
                        <button
                          className="btn btn-sm btn-primary shadow-sm py-0"
                          onClick={saveMemo}
                          disabled={nowLoadingUpdateMemo}
                        >
                          {nowLoadingUpdateMemo ? "保存中..." : "保存"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 画面下 UPSログ */}
          {UPSLog ? (
            <div className="col-12 ">
              <div className="row mt-3">
                <div className="col">
                  <small className="form-check form-check-inline">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="CboxIsErrorOnly"
                      checked={isErrorOnly}
                      onChange={handleToggleIsErrorOnly}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="CboxIsErrorOnly"
                    >
                      NGのみ表示
                    </label>
                  </small>
                </div>
                <div className="col-auto ml-auto mr-3">
                  <small>全{UPSLog?.totalCount ?? 0}件</small>
                </div>
                <div className="col-auto ml-auto">
                  <small>
                    <button
                      className="btn btn-sm btn-success shadow-sm py-0"
                      onClick={DownLoadExcel}
                    >
                      {nowDownload ? "ダウンロード中･･･" : "EXCEL出力"}
                    </button>
                  </small>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {nowLoadingForUPSLog ? (
                    "now loading..."
                  ) : (
                    <TableBind upsLogChunk={UPSLog} clickPager={PagingUPSLog} />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default StationDetail3;
