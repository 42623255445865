import { useApiResponse } from 'Components/api/ApiWrapper';
import { PageTitle } from 'Components/api/PageTitle';
import { Configuration } from 'Components/api/runtime';
import { StationApi } from 'Components/api/StationApi';
import { UserRole } from 'Components/api/UserRole';
import { filesizeLimit } from 'Components/FileDropzone';
import { XHRResponse, XHRWrapper } from 'Components/myClass/XHRWrapper';
import { RoleFilter } from 'Components/RoleFilter';
import { Token } from 'Components/Token';
import { useUserInfo } from 'Components/UserInfoContext';
import { FailedResponse } from 'library/openapi/models';
import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

export const stationImportPath = "/station/manage/import";

const isFailedResponse = (obj: any): obj is FailedResponse => {
    return !!obj?.reason;
}

const promiseLikeFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (ev) => {
            if (typeof reader.result === "string") {
                resolve(reader.result);
            }
            else if (reader.result !== null) {
                resolve(btoa(String.fromCharCode(...new Uint8Array(reader.result))));
            }
        }
        reader.readAsArrayBuffer(file);
    })
}

export const StationImport = () => {
    const history = useHistory();

    const [file, setFile] = React.useState<File | null>(null);
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

    const [uploadPercentage, setUploadPercentage] = React.useState<number>(0);
    const [uploadCompleted, setUploadCompleted] = React.useState(false);
    const [resultMsg, setResultMsg] = React.useState("");

    const [stationApi, onComplete] = React.useMemo(() => {
        if (!file) {
            return [undefined, undefined];
        }

        const xhr = new XHRWrapper<FailedResponse | void>(true);
        xhr.onProgress = (ev) => {
            const percent = (ev.loaded / ev.total) * 100;
            console.log(`uploading...(${percent}%)`);
            setUploadPercentage(percent);
        }

        xhr.authorizeHeader = {
            schema: "Bearer",
            key: Token.instance.token ?? ""
        }

        const sendWrapper = async (input: string, init?: RequestInit | undefined): Promise<any> => {
            const data: any = init!.body;
            await xhr.send(input, "POST", JSON.parse(data)); // このAPIでだけ成立する真似しないこと
            return xhr.response;
        }

        const api = new StationApi(new Configuration({
            fetchApi: sendWrapper
        }));

        const responseHandler = () => {
            const xhrResponse = xhr.response;

            setUploadCompleted(true);
            if (xhrResponse.ok) {
                setResultMsg("インポート完了");
            }
            else if (xhrResponse.status === 400 && isFailedResponse(xhrResponse.body)) {
                const msgData = xhrResponse.body;
                let msg = msgData.reason;
                if (msgData.hint) {
                    msg = msg + `\n${msgData.hint}`;
                }

                alert(msg);
                setResultMsg(msg);
            }
            else if (xhrResponse.status === 401) {
                alert('認証期限切れです');
                window.location.href = '/Login';
            }
            else {
                const msg = `インポート失敗 code=${xhrResponse.status}`;
                alert(msg);
                setResultMsg(msg);
            }
        }

        return [api, responseHandler];
    }, [file]);

    
    

    React.useEffect(() => {
        if (acceptedFiles.length <= 0) {
            return;
        }
        if (acceptedFiles.length > 1) {
            alert("一度に複数のファイルはインポートできません");
            return;
        }
        if (acceptedFiles[0].size > filesizeLimit) {
            alert("ファイルサイズが大きすぎます。2つ以上に分割してからインポートしてください");
            return;
        }

        setFile(acceptedFiles[0]);
    }, [acceptedFiles]);

    let pageContent: React.ReactNode;
    if (!file) {
        pageContent = (
            <div {...getRootProps({style: {width: "89vw", height: "90vh"}})}>
                <input {...getInputProps()} />
                <h1>クリックもしくはドロップでファイルアップロード</h1>
            </div>
        );
    }
    else {
        pageContent = (
            <React.Fragment>
                <FileUploader file={file} api={stationApi!} onComplete={onComplete!} />
                <div className="container">
                    <div className="row">
                        {`${file.name}をアップロードしています...`}
                    </div>
                    <div className="row">
                        {`アップロード中...(${uploadPercentage}%)`}
                    </div>
                    {uploadPercentage !== 100 ? undefined : (
                        <React.Fragment>
                            <div className="row">
                                アップロード完了
                            </div>
                            <div className="row">
                                インポート処理中...
                            </div>
                        </React.Fragment>
                    )}
                    {!resultMsg ? undefined : (
                        <div className="row">
                            {resultMsg}
                        </div>
                    )}
                </div>
            </React.Fragment>
        )
    }

    return (
        <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <PageTitle title="基地局インポート" className="d-inline-block" />
                <button
                    type="button"
                    className="btn btn-sm btn-outline-secondary"
                    onClick={history.goBack}
                >
                    前ページ
                </button>
                {pageContent}
        </RoleFilter>
    )
}

interface IFileUploaderProps {
    api: StationApi;
    file: File;
    onComplete: () => void;
}

const FileUploader = (props: IFileUploaderProps) => {
    const {api, file, onComplete} = props;

    // const [uploading, _none, uploadStart] = useApiResponse(
    //     api.importStationsRaw.bind(api),
    //     React.useCallback((err: Response) => {
    //         if (err.status === 400) {
    //             err.json().then(v => onComplete(false, `${v.reason}\n${v.hint}`));
    //         }
    //         else if (err.status === 409) {
    //             alert("重複しているもしくは既に登録されている局番があります。データを確認してください");
    //         }
    //         else {
    //             alert(`インポート失敗 code=${err.status}`);
    //         }
    //     }, [onComplete]),
    //     React.useCallback((data) => {
    //         onComplete(true, "インポート完了");
    //     }, [onComplete])
    // )

    React.useEffect(() => {
        promiseLikeFileToBase64(file).then(v => api.importStationsRaw({
            fileUpload: {
                filename: file.name,
                binary: v
            }
        })
        .then(v => onComplete())
        .catch(v => onComplete()));
    }, [file]);

    return <React.Fragment></React.Fragment>;
}