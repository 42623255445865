import React from 'react';
import { useDropzone } from 'react-dropzone';

export interface IFileDropzoneProps extends React.HTMLAttributes<HTMLDivElement> {
    onFileDetect: (files: File[]) => void;
    placeholder?: string;
    onSizeOver?: (file: File) => void;
    zoneMinHeight?: string;
}

export const filesizeLimitMB = 10;
export const filesizeLimit = filesizeLimitMB * 1024 * 1024;
export const FileDropzone = (props: IFileDropzoneProps) => {
    const {onFileDetect, placeholder, onSizeOver, zoneMinHeight, children, ...divProps} = props;
    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({noClick: true});

    React.useEffect(() => {
        const detected: File[] = [];
        for (const f of acceptedFiles) {
            if (f.size > filesizeLimit) {
                if (onSizeOver) {
                    onSizeOver(f);
                }
                continue;
            }

            detected.push(f);
        }
        onFileDetect(detected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedFiles]);

    const zoneProps = getRootProps({
        style: {
            width: "100%",
            height: "100%",
            outline: "none"
        }
    });

    return (
        <div {...divProps}>
            <div {...zoneProps}>
                <input {...getInputProps()} />
                <p>{placeholder}</p>
                {children}
            </div>
        </div>
    )
}