import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { useApiResponse } from "../Components/api/ApiWrapper";
import {
  StationApi,
  GetLockedUpsHistoryRequest,
  GetLockedUpsHistoryFileRequest,
} from "../Components/api/StationApi";
import { Station,UpsDetail } from "../library/openapi/models";
import { isNaNModified } from "../Components/myClass/checkValue";
import "../css/Common.css";
import history from 'history/createBrowserHistory';

interface Params {
  stationID: string | undefined;
}

interface Props extends RouteComponentProps<Params> {}

const SetDevice: React.FC<Props> = ({ match, history, location }: Props) => {

  const stationApi = new StationApi();
  const stationID: number | undefined = match.params.stationID ? parseInt(match.params.stationID, 10) : undefined;

  const onGetStationInfoError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("局情報取得失敗");
  };

  const onSetDeviceError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    if(err.status === 404){
      alert("対象のデバイスが見つかりません");
    }
    else if(err.status === 409){
      alert("設置済みデバイスです");
    }
    else{
      console.log(err);
      alert("検索失敗");
    }
  };

  const onSearchedDeviceError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    if(err.status === 404){
      alert("存在しないデバイスです");
    }
    else if(err.status === 409){
      alert("設置済みデバイスです");
    }
    else{
      console.log(err);
      alert("検索失敗");
    }
  };



    //局情報取得
    const [nowLoadingForStation, stationInfo, stationLoader] = useApiResponse(
      stationApi.getStationRaw.bind(stationApi),
      onGetStationInfoError
    );

    //局番検索
    const [nowLoadingForDevice, searchedDevice, searchDeviceLoader] = useApiResponse(
      stationApi.readUpsRaw.bind(stationApi),
      onSearchedDeviceError
    );

    //デバイス登録
    const [nowLoadingForSetDevice, setDeviceInfo, setDeviceLoader] = useApiResponse(
      stationApi.lockUpsRaw.bind(stationApi),
      onSetDeviceError
    );


    const [manageIdForSearch,setManageIdForSearch] = React.useState<string>("");


    //初回マウント時、局情報読み込み
    React.useEffect(() => {
      if (stationID) {
        stationLoader({
          stationID
        });
      }
    }, []);


  //デバイス設置イベント
  const setDeviceToStation = React.useCallback(() => {
    if (!stationID) {
      return;
    }

    if(!searchedDevice?.manageSerial){
      return alert("デバイスが選択されていません");
    }

    setDeviceLoader({stationID,  lockUpsRequest:{manageSerial:searchedDevice.manageSerial}});
    alert("デバイス設置完了");
    history.goBack();
    
  },[searchedDevice?.manageSerial]);


  //検索値変更
  const onChangeManageIdForSearch = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setManageIdForSearch(event.target.value);      
    },
    [manageIdForSearch]
  );

  //デバイス検索イベント
  const onClickSearch = React.useCallback(() =>{
    if (isNaNModified(manageIdForSearch)) {
      alert("デバイス管理番号は数値で入力して下さい");
      return;
    }

    if(stationID){
      searchDeviceLoader({stationID,  manageSerial:Number(manageIdForSearch)});
    }
    
  },[manageIdForSearch]);

  return (
    <React.Fragment>
      <h4 className="m-3 font-wight-bold d-inline-block">デバイス設置</h4>
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={history.goBack}
      >
        前ページ
      </button>

      <div className="container">
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="card border-primary">
              <div className="card-header py-1">基地局選択</div>
              <div className="card-body">
                <div className="d-flex flex-wrap">
                  <label className="w-Fixed-10em" htmlFor="">
                    設置局番号：
                  </label>
                  <label htmlFor="" className="">
                    {nowLoadingForStation ? "now loading..." : stationInfo?.stationNumber}
                  </label>
                </div>
                <div className="d-flex flex-wrap">
                  <label className="w-Fixed-10em" htmlFor="">
                    設置基地局名：
                  </label>
                  <label htmlFor="">{stationInfo?.stationName}</label>
                </div>
                <div className="d-flex flex-wrap">
                  <label className="w-Fixed-10em" htmlFor="">
                    セクタ番号：
                  </label>
                  <label htmlFor="">{stationInfo?.sectorId}</label>
                </div>
                <div className="d-flex flex-wrap">
                  <label className="w-Fixed-10em" htmlFor="">
                    CH区分：
                  </label>
                  <label htmlFor="">{stationInfo?.channelType}</label>
                </div>
                <div className="d-flex flex-wrap">
                  {" "}
                  <label className="w-Fixed-10em" htmlFor="">
                    端子タイプ：
                  </label>
                  <label htmlFor="">{stationInfo?.terminalType}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
            <div className="card border-primary">
              <div className="card-header py-1">デバイス選択</div>
              <div className="card-body">
                <div className="d-flex flex-wrap mb-4">
                  <label htmlFor="" className="w-Fixed-10em">
                    管理番号
                  </label>
                  <input
                    type="number"
                    className=" form-control form-control-sm mr-4 w-Fixed-15em"
                    value={manageIdForSearch}
                    onChange={onChangeManageIdForSearch}
                    placeholder="管理番号"
                  />
                  <button type="button" className="btn btn-sm btn-primary px-3" onClick={onClickSearch}>
                  検索
                  </button>
                </div>
                <div className="d-flex flex-row-reverse mb-3">
                <button type="button" className="btn btn-sm btn-primary"
                disabled={searchedDevice?.manageSerial ? false:true}
                onClick={setDeviceToStation}>
                {nowLoadingForSetDevice ? "設置処理中･･･":"デバイス設置"}
                  </button>
                </div>
                <div className="d-flex flex-wrap">
                  <label htmlFor="" className="w-Fixed-10em">
                    デバイスID：
                  </label>
                  <label htmlFor="">{nowLoadingForDevice? "now loading..." : searchedDevice?.deviceId}</label>
                </div>
                <div className="d-flex flex-wrap">
                  <label htmlFor="" className="w-Fixed-10em">
                    管理番号：
                  </label>
                  <label htmlFor="">{nowLoadingForDevice? "now loading..." : searchedDevice?.manageSerial}</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SetDevice;
