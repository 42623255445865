import * as React from "react";
import { StationInfoListChunk } from "library/openapi/models";
import { Pagenation } from "./Pagination";
import { formatDate } from "./myClass/formatAnyDate";
import "../css/Common.css";

interface Props {
  allStationListChunk: StationInfoListChunk | undefined;
  clickDetail: any;
  clickPager: any;
}

const cssForIsError = (isErrorStr: string | undefined) =>{
    if(isErrorStr == "異常"){
      return "text-danger font-weight-bold"
    }
    else{
      return ""
    }
} 


export const TableBind: React.FC<Props> = (props) => {
  if (!props.allStationListChunk?.chunk) {
    return <div>データなし</div>;
  }

  //詳細ボタンクリックイベントハンドラ
  const clickEvent = (stationID: number) => {
    props.clickDetail(stationID);
  };

  //ページングイベントハンドラ
  const clickPagerEvent = (page: number) => {
    props.clickPager(page);
  };

  //rowデータ生成
  const rows = props.allStationListChunk.chunk.map((station, index) => (
    <tr
      key={index}
    >
      <td>
        <button
          className="btn btn-sm btn-primary shadow-sm py-0"
          onClick={() => clickEvent(station.stationId)}
        >
          詳細
        </button>
      </td>
      <td>{station.no}</td>
      <td>{station.area}</td>
      <td>{station.stationNumber}</td>
      <td>{station.stationName}</td>
      <td>{station.manageSerial}</td>
      <td>{station.trigger}</td>
      <td className={cssForIsError(station.commercialPower)}>{station.commercialPower}</td>
      <td className={cssForIsError(station.upsError)}>{station.upsError}</td>
      <td className={cssForIsError(station.batteryStatus)}>{station.batteryStatus}</td>
      <td>{formatDate(station.lastReceived)}</td>
      <td>{station.upsVendor}</td>
      <td>{station.lqi }</td>

    </tr>
  ));

  //ページャー生成
  const pager = () => {
    const pagenation = new Pagenation(
      props.allStationListChunk?.page ?? 0,
      props.allStationListChunk?.pageCount ?? 0
    );

    let pagerNumbers: number[] = pagenation.pageNumbers();

    let pagerElement: JSX.Element[] = [];

    let prevPage: number = (props.allStationListChunk?.page ?? 0) - 1;
    let nextPage: number = (props.allStationListChunk?.page ?? 0) + 1;

    if (prevPage < 0) {
      prevPage = 0;
    }

    if (nextPage >= (props.allStationListChunk?.pageCount ?? 0)) {
      nextPage = (props.allStationListChunk?.pageCount ?? 1) - 1;
    }

    pagerNumbers.forEach((page) => {
      if (page == (props.allStationListChunk?.page ?? 0)) {
        pagerElement.push(
          <li className="page-item active" aria-current="page">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      } else {
        pagerElement.push(
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      }
    });

    return (
      <ul className="pagination">
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(0)}
          >
            最初
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(prevPage)}
          >
            前へ
          </button>
        </li>
        {pagerElement}
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(nextPage)}
          >
            次へ
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() =>
              clickPagerEvent((props.allStationListChunk?.pageCount ?? 1) - 1)
            }
          >
            最後
          </button>
        </li>
      </ul>
    );
  };

  const footerElement = pager();

  return (
    <table className="table table-sm table-hover">
      <thead className="bg-info">
        <tr>
          <th></th>
          <th>NO</th>
          <th>エリア</th>
          <th>局番</th>
          <th>局名</th>
          <th>デバイス<br/>管理番号</th>
          <th>トリガー</th>
          <th>商用電源_状態</th>
          <th>UPS状態</th>
          <th>SIGFOX電池状態</th>
          <th>最新受信時刻</th>
          <th>UPSベンダー</th>
          <th>LQI</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <tfoot>
        <tr>
          <td colSpan={9}>{footerElement}</td>
        </tr>
      </tfoot>
    </table>
  );
};
