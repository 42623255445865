/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface StationFilter
 */
export interface StationFilter {
    /**
     * 
     * @type {string}
     * @memberof StationFilter
     */
    number?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StationFilter
     */
    name?: string | null;
}

export function StationFilterFromJSON(json: any): StationFilter {
    return StationFilterFromJSONTyped(json, false);
}

export function StationFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'number': !exists(json, 'number') ? undefined : json['number'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function StationFilterToJSON(value?: StationFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'number': value.number,
        'name': value.name,
    };
}


