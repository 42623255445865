/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface FileSasUpload
 */
export interface FileSasUpload {
    /**
     * 
     * @type {number}
     * @memberof FileSasUpload
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof FileSasUpload
     */
    container: string;
    /**
     * 
     * @type {string}
     * @memberof FileSasUpload
     */
    path: string;
    /**
     * 
     * @type {string}
     * @memberof FileSasUpload
     */
    uri: string;
}

export function FileSasUploadFromJSON(json: any): FileSasUpload {
    return FileSasUploadFromJSONTyped(json, false);
}

export function FileSasUploadFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileSasUpload {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'container': json['container'],
        'path': json['path'],
        'uri': json['uri'],
    };
}

export function FileSasUploadToJSON(value?: FileSasUpload | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'container': value.container,
        'path': value.path,
        'uri': value.uri,
    };
}


