import * as React from 'react';
import { useSasDownloader } from './api/useSas';
import { ProgressBar } from './ProgressBar';

export interface ISasDownloadButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    fileId: number;
    onComplete?: (f: File) => void;
}

export const SasDownloadButton = (props: ISasDownloadButtonProps) => {
    const {fileId, onComplete, onClick, ...buttonProps} = props;

    const {progressing, progressPercentage, starter} = useSasDownloader(React.useCallback((f: File) => {
        download(f);
        if (onComplete) {
            onComplete(f);
        }
    }, [onComplete]));

    const onDownloadClick = (ev: React.MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        starter(fileId);
        if (onClick) {
            onClick(ev);
        }
    }

    const relayButtonProps: React.HtmlHTMLAttributes<HTMLButtonElement> = {...buttonProps, onClick: onDownloadClick};

    return (
        <div>
            {progressing ? <ProgressBar percentage={progressPercentage} style={{width: "3rem"}} /> : (
                <button {...relayButtonProps}></button>
            )}
        </div>
    )
}

const download = (file: File) => {
    const url = URL.createObjectURL(file);
    const a = document.createElement("a");
    a.id = `${file.name} downloader link`;

    document.body.appendChild(a);
    a.download = file.name;
    a.href = url;
    a.click();
    a.remove();
    URL.revokeObjectURL(url);
}