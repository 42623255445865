/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface FileSasResponse
 */
export interface FileSasResponse {
    /**
     * 
     * @type {string}
     * @memberof FileSasResponse
     */
    uri: string;
    /**
     * 
     * @type {string}
     * @memberof FileSasResponse
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof FileSasResponse
     */
    container: string;
    /**
     * 
     * @type {string}
     * @memberof FileSasResponse
     */
    path: string;
    /**
     * 
     * @type {number}
     * @memberof FileSasResponse
     */
    filesize: number;
}

export function FileSasResponseFromJSON(json: any): FileSasResponse {
    return FileSasResponseFromJSONTyped(json, false);
}

export function FileSasResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FileSasResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'uri': json['uri'],
        'filename': json['filename'],
        'container': json['container'],
        'path': json['path'],
        'filesize': json['filesize'],
    };
}

export function FileSasResponseToJSON(value?: FileSasResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'uri': value.uri,
        'filename': value.filename,
        'container': value.container,
        'path': value.path,
        'filesize': value.filesize,
    };
}


