/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface UserRegistrationRequest
 */
export interface UserRegistrationRequest {
    /**
     * ユーザーID
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    id: string;
    /**
     * TOTPの共通鍵
     * @type {string}
     * @memberof UserRegistrationRequest
     */
    otpKey: string;
    /**
     * ユーザー吁E     * @type {string}
     * @memberof UserRegistrationRequest
     */
    name: string;
    /**
     * 管琁E��E��ラグ
     * @type {boolean}
     * @memberof UserRegistrationRequest
     */
    isAdmin: boolean;
}

export function UserRegistrationRequestFromJSON(json: any): UserRegistrationRequest {
    return UserRegistrationRequestFromJSONTyped(json, false);
}

export function UserRegistrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRegistrationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'otpKey': json['otpKey'],
        'name': json['name'],
        'isAdmin': json['isAdmin'],
    };
}

export function UserRegistrationRequestToJSON(value?: UserRegistrationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'otpKey': value.otpKey,
        'name': value.name,
        'isAdmin': value.isAdmin,
    };
}


