import * as React from "react";
import { mapValues } from "./api/runtime";
import { StationListChunk } from "../library/openapi/models";
import { Link, RouteComponentProps } from "react-router-dom";
import { Pagenation } from "../Components/Pagination";
import stationDetail from "../pages/StationDetail";

interface PropsTest {
  click: React.MouseEventHandler<HTMLButtonElement>;
  
  id: number;
  name: string;
  age: number;
}

interface Props {
  stationListChunk: StationListChunk | undefined;
  clickDetail: any;
  clickPager: any;
}

// const detailButton:React.FC = () =>{
//     return (
//         <button className="btn btn-sm btn-primary">
//           {/* TODO */}
//         </button>
//       );
// }

// const button:React.FC<> = () => {
//     return {detailButton}
// }

export const TableBind: React.FC<Props> = (props) => {
  if (!props.stationListChunk?.chunk) {
    return <div>データなし</div>;
  }

  const clickEvent = (stationNum: string) => {
    props.clickDetail(stationNum);
  };
  const clickPagerEvent = (page: number) => {
    props.clickPager(page);
  };

  //   const clickEvent = React.useCallback(
  //     (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //       alert("clicked!");
  //     },
  //     []
  //   );
  //   const users: PropsTest[] = [
  //     {
  //       click: clickEvent,
  //       id: 1,
  //       name: "aki",
  //       age: 20,
  //     },
  //     {
  //       click: clickEvent,
  //       id: 2,
  //       name: "tama",
  //       age: 5,
  //     },
  //   ];


  const rows = props.stationListChunk.chunk.map((station,index) => (
    <tr key={index}>
    {/* <tr> */}
      <td>
        <button
          className="btn btn-sm btn-primary shadow-sm py-0"
          onClick={() => clickEvent(station.stationNumber)}
        >
          詳細
        </button>
      </td>
      <td>{station.stationNumber}</td>
      <td>{station.stationName}</td>
      <td>{station.latitude}</td>
      <td>{station.longitude}</td>
      <td>{station.sectorId}</td>
    </tr>
  ));

  //ページャー生成
  const pager = () =>{

      const pagenation = new Pagenation(
        props.stationListChunk?.page ?? 0,
        props.stationListChunk?.pageCount ?? 0
      );

      let pagerNumbers: number[] = pagenation.pageNumbers();

      let pagerElement:JSX.Element[] = [];

      let prevPage:number = (props.stationListChunk?.page ?? 0) - 1;
      let nextPage:number = (props.stationListChunk?.page ?? 0) + 1;

      if(prevPage < 0){
        prevPage = 0;
      }

      if(nextPage >= (props.stationListChunk?.pageCount ?? 0)){
        nextPage = (props.stationListChunk?.pageCount?? 1) - 1;
      }

      pagerNumbers.forEach(page => {
          if(page == (props.stationListChunk?.page ?? 0)){
            pagerElement.push(<li className="page-item active" aria-current="page"><button type="button" className="page-link" onClick={()=>clickPagerEvent(page)}>{page + 1}</button></li>);
          }
          else{
            pagerElement.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>clickPagerEvent(page)} >{page + 1}</button></li>);
          }
        
      });

      
      return(
        <ul className="pagination">
                      <li className="page-item">
            <button type="button" className="page-link" onClick={()=>clickPagerEvent(0)} >
              最初
            </button>
          </li>
          <li className="page-item">
            <button type="button" className="page-link" onClick={()=>clickPagerEvent(prevPage)} >
              前へ
            </button>
          </li>
          {pagerElement}
          <li className="page-item">
            <button type="button" className="page-link" onClick={()=>clickPagerEvent(nextPage)}>
              次へ
            </button>
          </li>
                    <li className="page-item">
            <button type="button" className="page-link" onClick={()=>clickPagerEvent((props.stationListChunk?.pageCount?? 1) -1)}>
              最後
            </button>
          </li>
          </ul>
      )
  };


  const footerElement = pager();

  return (
      <table className="table table-sm table-hover">
        <thead className="bg-info">
          <tr>
            <th></th>
            <th>局番</th>
            <th>基地局名</th>
            <th>緯度</th>
            <th>経度</th>
            <th>セクター</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
        <tfoot>
          <tr>
            <td colSpan={5}>{footerElement}</td>
          </tr>
        </tfoot>
      </table>
  );
};
