import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { User } from "../Components/User";
import {
  StationApi,
  GetStationListRequest,
} from "../Components/api/StationApi"
import { ApiResponse } from "../Components/api/runtime";
import { TableBind } from "../Components/StationListTable";
import Loading from "../Components/Loading";
import { useApiResponse } from "Components/api/ApiWrapper";
import { StationListChunk } from "library/openapi/models/StationListChunk";

interface Props extends RouteComponentProps {}

interface stationListInfo {
  errMsg?: string;
  content?: StationListChunk;
}



const StationTop: React.FC<Props> = ({ history }: Props) => {

  const stationApi = new StationApi();

  const [stationNum, setStationNum] = React.useState("");
  const [stationName, setStationName] = React.useState("");

  const onGetListError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("データ取得失敗");
  };

  // const [nowListLoading, stationList, loader] = useApiResponse(stationApi.getStationListRaw, onGetListError);
  const [nowListLoading, stationList, loader] = useApiResponse(stationApi.getStationListRaw.bind(stationApi), onGetListError);
  

  React.useEffect(() => {
    loader({page: 0, size: 10});
  }, []);

  const reqParam: GetStationListRequest = { page: 0, size: 10 };

  //検索パラメータ追加
  if(stationNum || stationName){
    reqParam.filter = { number: stationNum, name: stationName };
  }

  const getStationList = (page: number = 0) => {
    const param: GetStationListRequest = {...reqParam};
    param.page = page;
    loader(param);
  }

  // const getStationList = (page: number = 0): stationListInfo => {
  //   let reqParam: GetStationListRequest = { page:page, size: 10 };
  //   let result: stationListInfo = {};

  //   //検索パラメータ追加
  //   if(stationNum || stationName){
  //     reqParam.filter = { number: stationNum, name:stationName }
  //   }
  //   console.log("API呼び出し時検索名：" + stationName);

  //   stationApi
  //     .getStationListRaw(reqParam)
  //     .then((res: ApiResponse<StationListChunk>) => {
  //       if (res.raw.status === 200) {
  //         res
  //           .value()
  //           .then((value: StationListChunk) => {
  //             result.content = value;
  //             setStationList(value);
  //           })
  //           .catch((err) => {
  //             result.errMsg = "データ取得失敗";
  //             alert(result.errMsg);
  //           });
  //       }
  //     })
  //     .catch((err) => {
  //       if(err.status === 401){
  //         console.log(err.Reason);
  //         console.log(err.hint);
  //         console.log(err);
  //         alert(err.Reason);
  //         return;
  //       }
  //       result.errMsg = "データ取得失敗";
  //       alert(result.errMsg);
  //     });

  //   return result;
  // };

  //ページ読み込み初回のみ実行
  // React.useEffect(() => {
  //   getStationList();
  // }, []);

  //   React.useEffect(() => {
  //     //コンポーネント初回読み込み時のみ実行
  //     if (isOnload) {
  //       let getListInfo: stationListInfo = getStationList();

  //       setIsOnload(false);
  //     }
  //   }, [isOnload]);

  //値が変わったらテーブル更新
  //   React.useEffect(() => {
  //       if(stationList){    console.log(
  //         "ページ数:" + stationList?.page + "/件数：" + stationList?.totalCount
  //       );
  //     }

  //   }, [stationList]);

  //=====検索================================
  const handleSetStationNum = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStationNum(event.target.value);
    },
    [stationNum]
  );

  const handleSetStationName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStationName(event.target.value);
      console.log("検索パラメータ格納：" + event.target.value);
      console.log("検索パラメータ格納UseState内：" + stationName);
    },
    [stationName]
  );

  const handleSearchOnClick = () => {
    loader(reqParam);
  };
  //========================================

  //詳細クリックイベント
  function onClickDetail(stationNum: string) {
    history.push("/StationDetail3/" + stationNum);
  }



  //テーブルコンポーネント ★うまくいかないのであとでテスト
  //   const renderTable = () => {
  //     if (tableLoading) {
  //       return (
  //         <TableBind stationListChunk={stationList} clickDetail={onClickDetail} />
  //       );
  //     } else {
  //       return <Loading />;
  //     }
  //   };

  //render content
  return (
    <React.Fragment>
      <h4 className="p-3 font-wight-bold">設置局一覧</h4>
      <div className="container">
        <div className="row form-inline">
          <div className="col-auto form-group">
            <label htmlFor="" className="col-auto col-form-label-sm">
              局番
            </label>
            <input
              id="input_stationNum"
              className="form-control form-control-sm"
              type="text"
              value={stationNum}
              onChange={handleSetStationNum}
            />
          </div>
          <div className="col-auto  form-group">
            <label htmlFor="" className="col-auto col-form-label-sm">
              基地局名
            </label>
            <input
              id="input_stationName"
              className="form-control form-control-sm"
              type="text"
              value={stationName}
              onChange={handleSetStationName}
            />
          </div>
          <div className="col-auto form-group">
            <button className="btn btn-sm btn-primary shadow-sm px-3" onClick={handleSearchOnClick}>
              検索
            </button>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 text-right">
            <small className="mr-3">全{stationList?.totalCount ?? 0}件</small>
            <small>ダウンロード実装部</small>
          </div>
          <div className="col-12">
            {nowListLoading ? 'now loading...' : (
              <TableBind
                stationListChunk={stationList!}
                clickDetail={onClickDetail}
                clickPager={getStationList}
              />
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StationTop;
