import * as React from "react";
import { UpsSummaryChunk } from "library/openapi/models";
import { Pagenation } from "./Pagination";
import { formatDate } from "./myClass/formatAnyDate";
import "../css/Common.css";

interface Props {
  upsSummaryChunk: UpsSummaryChunk | undefined;
  clickDetail: any;
  clickPager: any;
}

function getErrorCss(
  isErr: boolean | null | undefined,
  lastDate: Date | null | undefined
): string {
  let result = "";

  if (isErr) {
    result = "bg-danger";
  } else if (lastDate) {
    let checkDate: Date = new Date();
    checkDate.setHours(checkDate.getHours() - 25); //日本時間+9 25時間以上経過しているか -25  ※Date型同士の比較をするので日本時間を考慮しなくてよい

    if (lastDate < checkDate) {
      result = "bg-yellow";
    }
  }

  return result;
}

export const TableBind: React.FC<Props> = (props) => {
  if (!props.upsSummaryChunk?.chunk) {
    return <div>データなし</div>;
  }

  //詳細ボタンクリックイベントハンドラ
  const clickEvent = (stationID: number) => {
    props.clickDetail(stationID);
  };

  //ページングイベントハンドラ
  const clickPagerEvent = (page: number) => {
    props.clickPager(page);
  };

  //rowデータ生成
  const rows = props.upsSummaryChunk.chunk.map((ups, index) => (
    <tr
      key={index}
      className={getErrorCss(
        ups.lastStatusLog?.isError,
        ups.lastStatusLog?.receivedDate
      )}
    >
      <td>
        <button
          className="btn btn-sm btn-primary shadow-sm py-0"
          onClick={() => clickEvent(ups.station!.stationId)}
          disabled={!ups.station}
        >
          詳細
        </button>
      </td>
      <td>{formatDate(ups.lastStatusLog?.receivedDate)}</td>
      <td>{ups.station?.area}</td>
      <td>{ups.station?.stationNumber}</td>
      <td>{ups.station?.stationName}</td>
      <td>{ups.manageSerial}</td>
      <td>{ups.lastStatusLog?.statusCode}</td>
      <td>{ups.lastStatusLog?.batteryStatus}</td>
    </tr>
  ));

  //ページャー生成
  const pager = () => {

    let maxPageCount = props.upsSummaryChunk?.pageCount ?? 0;

    if(maxPageCount > 5){
      maxPageCount = 5
    }

    const pagenation = new Pagenation(
      props.upsSummaryChunk?.page ?? 0,
      maxPageCount
    );

    let pagerNumbers: number[] = pagenation.pageNumbers();

    let pagerElement: JSX.Element[] = [];

    let prevPage: number = (props.upsSummaryChunk?.page ?? 0) - 1;
    let nextPage: number = (props.upsSummaryChunk?.page ?? 0) + 1;

    if (prevPage < 0) {
      prevPage = 0;
    }

    if (nextPage >= (props.upsSummaryChunk?.pageCount ?? 0)) {
      nextPage = (props.upsSummaryChunk?.pageCount ?? 1) - 1;
    }

    pagerNumbers.forEach((page) => {
      if (page == (props.upsSummaryChunk?.page ?? 0)) {
        pagerElement.push(
          <li className="page-item active" aria-current="page">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      } else {
        pagerElement.push(
          <li className="page-item">
            <button
              type="button"
              className="page-link"
              onClick={() => clickPagerEvent(page)}
            >
              {page + 1}
            </button>
          </li>
        );
      }
    });

    return (
      <ul className="pagination">
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(0)}
          >
            最初
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(prevPage)}
          >
            前へ
          </button>
        </li>
        {pagerElement}
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() => clickPagerEvent(nextPage)}
          >
            次へ
          </button>
        </li>
        <li className="page-item">
          <button
            type="button"
            className="page-link"
            onClick={() =>
              clickPagerEvent((props.upsSummaryChunk?.pageCount ?? 1) - 1)
            }
          >
            最後
          </button>
        </li>
      </ul>
    );
  };

  const footerElement = pager();

  return (
    <table className="table table-sm table-hover">
      <thead className="bg-info">
        <tr>
          <th></th>
          <th>最新受信時刻</th>
          <th>エリア</th>
          <th>局番</th>
          <th>局名</th>
          <th>管理番号</th>
          <th>状態</th>
          <th>SIGFOX電池状態</th>
        </tr>
      </thead>
      <tbody>{rows}</tbody>
      <tfoot>
        <tr>
          <td colSpan={9}>{footerElement}</td>
        </tr>
      </tfoot>
    </table>
  );
};
