import React from "react";

export interface IProgressBarProps extends React.HTMLAttributes<HTMLDivElement> {
    percentage: number;
    barColor?: "blue" | "red";
    thickness?: string;
}

export const ProgressBar = (props: IProgressBarProps) => {
    return (
        <div style={props.style} className={props.className}>
            <div className="d-flex w-100 border" style={{height: props.thickness ?? "0.5em"}}>
                <div style={{width: `${props.percentage}%`, backgroundColor: `${props.barColor ?? "blue"}`}}></div>
            </div>
        </div>
    )
}