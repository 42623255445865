/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from './runtime';
import {
    FileResponse,
    FileResponseFromJSON,
    FileResponseToJSON,
    FileSasResponse,
    FileSasResponseFromJSON,
    FileSasResponseToJSON,
    FileSasUpload,
    FileSasUploadFromJSON,
    FileSasUploadToJSON,
    FileUpload,
    FileUploadFromJSON,
    FileUploadToJSON,
    UploadedFilePropertyListChunk,
    UploadedFilePropertyListChunkFromJSON,
    UploadedFilePropertyListChunkToJSON,
    UploadedFilePropertySearch,
    UploadedFilePropertySearchFromJSON,
    UploadedFilePropertySearchToJSON,
} from "../../library/openapi/models";

export interface ActivateRecordRequest {
    id: number;
}

export interface CreateFileSasInfoRequest {
    filename?: string;
    size?: number | null;
}

export interface DeleteFileRequest {
    id: number;
}

export interface GetFileRequest {
    id: number;
}

export interface GetSasInfoRequest {
    id: number;
}

export interface GetUploadedFileListRequest {
    page: number;
    size?: number | null;
    filter?: UploadedFilePropertySearch;
}

export interface PostFileRequest {
    fileUpload: FileUpload;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * postの結果報告としてのみ使うこと。一般の無効化解除が必要なら別途実装すること
     * ファイルレコード有効化
     */
     async activateRecordRaw(requestParameters: ActivateRecordRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling activateRecord.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/sas`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * postの結果報告としてのみ使うこと。一般の無効化解除が必要なら別途実装すること
     * ファイルレコード有効化
     */
    async activateRecord(requestParameters: ActivateRecordRequest): Promise<void> {
        await this.activateRecordRaw(requestParameters);
    }

    /**
     * 無効なレコードが確保されるのでBlobへアップロード成功したらputで有効化する
     * ファイルアップロード
     */
    async createFileSasInfoRaw(requestParameters: CreateFileSasInfoRequest): Promise<runtime.ApiResponse<FileSasUpload>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.filename !== undefined) {
            queryParameters['filename'] = requestParameters.filename;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/sas`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileSasUploadFromJSON(jsonValue));
    }

    /**
     * ファイルアップロード
     */
    async createFileSasInfo(requestParameters: CreateFileSasInfoRequest): Promise<FileSasUpload> {
        const response = await this.createFileSasInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * ファイル論理削除
     */
    async deleteFileRaw(requestParameters: DeleteFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ファイル論理削除
     */
    async deleteFile(requestParameters: DeleteFileRequest): Promise<void> {
        await this.deleteFileRaw(requestParameters);
    }

    /**
     * ファイルダウンロード
     */
    async getFileRaw(requestParameters: GetFileRequest): Promise<runtime.ApiResponse<FileResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * ファイルダウンロード
     */
    async getFile(requestParameters: GetFileRequest): Promise<FileResponse> {
        const response = await this.getFileRaw(requestParameters);
        return await response.value();
    }

    /**
     * ファイルダウンロード
     */
    async getSasInfoRaw(requestParameters: GetSasInfoRequest): Promise<runtime.ApiResponse<FileSasResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSasInfo.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/sas`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileSasResponseFromJSON(jsonValue));
    }

    /**
     * ファイルダウンロード
     */
    async getSasInfo(requestParameters: GetSasInfoRequest): Promise<FileSasResponse> {
        const response = await this.getSasInfoRaw(requestParameters);
        return await response.value();
    }

    /**
     * ファイルダウンロード
     */
    async getUploadedFileListRaw(requestParameters: GetUploadedFileListRequest): Promise<runtime.ApiResponse<UploadedFilePropertyListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getUploadedFileList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.filter !== undefined) {
            if (requestParameters.filter.filename) {
                queryParameters['filter.filename'] = requestParameters.filter.filename;
            }
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file/property/list/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadedFilePropertyListChunkFromJSON(jsonValue));
    }

    /**
     * ファイルダウンロード
     */
    async getUploadedFileList(requestParameters: GetUploadedFileListRequest): Promise<UploadedFilePropertyListChunk> {
        const response = await this.getUploadedFileListRaw(requestParameters);
        return await response.value();
    }

    /**
     * ファイルアップロード
     */
    async postFileRaw(requestParameters: PostFileRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.fileUpload === null || requestParameters.fileUpload === undefined) {
            throw new runtime.RequiredError('fileUpload','Required parameter requestParameters.fileUpload was null or undefined when calling postFile.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/file`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FileUploadToJSON(requestParameters.fileUpload),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ファイルアップロード
     */
    async postFile(requestParameters: PostFileRequest): Promise<void> {
        await this.postFileRaw(requestParameters);
    }

}
