/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface UserInfo
 */
export interface UserInfo {
    /**
     * ユーザーID
     * @type {string}
     * @memberof UserInfo
     */
    id: string;
    /**
     * ユーザー吁E     * @type {string}
     * @memberof UserInfo
     */
    name: string;
    /**
     * RBACに使用するロール
     * @type {Array<string>}
     * @memberof UserInfo
     */
    roles: Array<string>;
}

export function UserInfoFromJSON(json: any): UserInfo {
    return UserInfoFromJSONTyped(json, false);
}

export function UserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'roles': json['roles'],
    };
}

export function UserInfoToJSON(value?: UserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'roles': value.roles,
    };
}


