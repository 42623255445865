import * as React from 'react';
import { useApiResponse } from './ApiWrapper';
import { FileApi } from './FileApi';
import { BlobServiceClient } from '@azure/storage-blob';
import { FileSasUpload } from 'library/openapi/models';

const fileApi = new FileApi();

export const useSasDownloader = (onDownloaded?: (f: File) => void) => {
    const onError = React.useCallback((err: Response) => {
        // エラー種別ごとの処理はerr.statusで分岐
        if (err.status === 409) {
            err.json().then(obj => {
                if (obj.reason && obj.hint) {
                    alert(obj.reason + "\n" + obj.hint);
                }
            });
        }
        else {
            alert("データ取得失敗");
        }
    }, []);

    const [nowloading, sasInfo, loader] = useApiResponse(
        fileApi.getSasInfoRaw.bind(fileApi),
        onError
    );

    const starter = React.useCallback((id: number) => {
        loader({id});
    }, [loader]);

    const [downloading, setDownloading] = React.useState(false);
    const [progressPercentage, setProgressPrecentage] = React.useState<number>(0);

    // CORS+SASでStorageに直接アップロード
    React.useEffect(() => {
        if (nowloading || !sasInfo) {
            return;
        }

        if (!sasInfo.uri) {
            alert("アクセス権がありません");
            return;
        }
        if (!sasInfo.path) {
            alert("見つかりませんでした");
            return;
        }

        const service = new BlobServiceClient(sasInfo.uri);
        const container = service.getContainerClient(sasInfo.container!);
        const blobClient = container.getBlockBlobClient(sasInfo.path);
        
        setDownloading(true);

        blobClient.download(0, undefined, {
            onProgress: (ev) => {
                const percent = (ev.loadedBytes / sasInfo.filesize) * 100;
                console.log(`${sasInfo.filename} downloading...(${percent}%)`)
                setProgressPrecentage(percent);
            }
        }).then(parsed => {
            if (parsed.blobBody) {
                parsed.blobBody.then(b => {
                    const tmp: any = b;
                    tmp.lastModifiedDate = new Date();
                    tmp.name = sasInfo.filename;
                    if (onDownloaded) {
                        onDownloaded(tmp);
                    }
                })
            }
        })
        .catch(err => alert(`ダウンロードに失敗しました。${err}`))
        .finally(() => {
            setDownloading(false);
        });
    }, [nowloading, sasInfo]);

    return {progressing: nowloading || downloading, progressPercentage, starter};
}

export const useSasUploader = (onGetSasInfo?: (sasInfo: FileSasUpload) => void) => {
    const onError = React.useCallback((err: Response) => {
        // エラー種別ごとの処理はerr.statusで分岐
        if (err.status === 409) {
            err.json().then(obj => {
                if (obj.reason && obj.hint) {
                    alert(obj.reason + "\n" + obj.hint);
                }
            });
        }
        else {
            alert("データ取得失敗");
        }
    }, []);

    // サーバーからSASを取得
    const [nowloading, sasInfo, loader] = useApiResponse(
        fileApi.createFileSasInfoRaw.bind(fileApi),
        onError
    );

    const [file, setFile] = React.useState<File | null>(null);
    const starter = React.useCallback((f: File) => {
        setFile(f);
        loader({filename: f.name, size: f.size});
    }, [loader]);

    const [uploading, setUploading] = React.useState(false);
    const [progressPercentage, setProgressPrecentage] = React.useState<number>(0);

    // CORS+SASでStorageに直接アップロード
    React.useEffect(() => {
        if (nowloading || !sasInfo) {
            return;
        }

        if (!sasInfo.uri) {
            alert("アクセス権がありません");
            return;
        }
        if (!sasInfo.path) {
            alert("見つかりませんでした");
            return;
        }
        
        if (!file) {
            return;
        }

        const service = new BlobServiceClient(sasInfo.uri);
        const container = service.getContainerClient(sasInfo.container!);
        const blobClient = container.getBlockBlobClient(sasInfo.path);
        
        setUploading(true);

        const asyncFunc = async () => {
            try {
                await blobClient.uploadData(file, {
                    onProgress: (ev) => {
                        const percent = (ev.loadedBytes / file.size) * 100;
                        console.log(`${file.name} uploading...(${percent}%)`)
                        setProgressPrecentage(percent);
                    }
                });

                try {
                    const activateResponse = await fileApi.activateRecordRaw({id: sasInfo.id});

                    if (onGetSasInfo && activateResponse.raw.ok) {
                        onGetSasInfo(sasInfo);
                    }

                }
                catch (err) {
                    alert(`${file.name}のアップロードに失敗しました。アクティベートできませんでした。リトライしてください。`);
                }
            }
            catch (err) {
                alert(`${file.name}のアップロードに失敗しました。リトライしてください。`);
            }
            finally {
                setUploading(false);
                setFile(null);
            }
        }

        asyncFunc();
    }, [nowloading, sasInfo, file]);

    return {progressing: nowloading || uploading, progressPercentage, starter};
}