import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import Menu from "./menu";
// import Top from "../pages/Top";
import Page1 from "../pages/Page1";
import Page2 from "../pages/Page2";
import StationTop from "../pages/StationTop";
import StationDetail from "../pages/StationDetail";
import SetDevice from "../pages/SetDevice";
import UPSMonitor from "../pages/UPSMonitor";
import UPSMonitor2 from "../pages/UPSMonitor2";
import AllStation from '../pages/AllStation';
import AllDevice from '../pages/AllDevice';
import StationDetail3 from '../pages/StationDetail3';
import { FileUpDown, fileUpdownRoute } from "pages/FileUpDown";
import { UserManage, userManagePath } from "pages/UserManage";
import { UserRegister, userRegisterPath } from "pages/UserRegister";
import { PasswordUpdate, passwordUpdatePath } from "pages/PasswordUpdate";
import { StationManage, stationManagePath } from "../pages/StationManage";
import { StationImport, stationImportPath } from "pages/StationImport";

const Content = () => {
  return (
    <BrowserRouter>
      <Menu />

      <Switch>
        <Route exact={true} path="/" component={UPSMonitor} />
        <Route path="/MoniterForErrorStation" component={UPSMonitor2} />
        <Route path="/AllStationList" component={AllStation} />
        <Route path="/AllDeviceList" component={AllDevice} />
        <Route path="/StationList" component={StationTop} />
        <Route exact path={stationManagePath} component={StationManage} />
        <Route exact path={stationImportPath} component={StationImport} />
        <Route exact path={fileUpdownRoute} component={FileUpDown} />
        <Route exact path={userManagePath} component={UserManage} />
        <Route exact path={userRegisterPath} component={UserRegister} />
        <Route path={passwordUpdatePath()} component={PasswordUpdate} />
        {/* // <Route path="/Page1" component={Page1} />
        // <Route path="/Page2" component={Page2} /> */}
        <Route path="/StationDetail/:stationID" component={StationDetail} />
        <Route path="/StationDetail3/:stationID" component={StationDetail3} />
        <Route path="/SetDevice/:stationID" component={SetDevice} />
        {/* Not Found */}
        <Route component={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};

export default Content;
