import { useApiResponse } from 'Components/api/ApiWrapper';
import { PageTitle } from 'Components/api/PageTitle';
import { StationApi } from 'Components/api/StationApi';
import { UserRole } from 'Components/api/UserRole';
import { Pagenation } from 'Components/Pagenation';
import { RoleFilter } from 'Components/RoleFilter';
import { StationDetailWithChannel, StationListChunk } from 'library/openapi/models';
import * as React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { stationImportPath } from './StationImport';

const stationApi = new StationApi();
const PAGESIZE = 50;

export const stationManagePath = "/station/manage";
export const StationManage = () => {
    const history = useHistory();

    const onToStationImportPageClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        history.push(stationImportPath);
    }

    const [listLoading, stationList, stationListLoader] = useApiResponse(
        stationApi.getStationListRaw.bind(stationApi),
        React.useCallback((err: Response) => alert("取得失敗"), [])
    );

    const [page, setPage] = React.useState(0);

    React.useEffect(() => {
        stationListLoader({
            size: PAGESIZE,
            page
        });
    }, [page, stationListLoader]);

    const onRowDelete = () => {
        setPage(0);
        stationListLoader({
            size: PAGESIZE,
            page: 0
        });
    }

    const [searchText, setSearchText] = React.useState("");
    const onSearchTextChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(ev.currentTarget.value);
    }
    const onSearchClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setPage(0);
        stationListLoader({
            size: PAGESIZE,
            page: 0,
            filter: {
                number: searchText
            }
        });
    }

    return (
        <RoleFilter requirementRoles={[UserRole.ADMIN]}>
            <PageTitle title="基地局管理" />
            <div className="mx-5">
                <div className="text-right">
                    <button className="btn btn-sm btn-success" onClick={onToStationImportPageClick}>追加</button>
                </div>
                <div>
                    <input className="form-control-sm mr-2" placeholder="局番" onChange={onSearchTextChange} />
                    <button className="btn btn-sm btn-primary" onClick={onSearchClick}>局番検索</button>
                </div>
                {(listLoading || !stationList) ? <span>now loading...</span> : <StationListTable stationList={stationList} page={page} onPageChange={setPage} onRowDelete={onRowDelete} />}
            </div>
        </RoleFilter>
    );
}


const tableHeaders = [
    "局番",
    "局名",
    "CH",
    "地域",
    ""
];

interface IStationListTableProps {
    stationList: StationListChunk;
    page: number;
    onPageChange: (next: number) => void;
    onRowDelete: () => void;
}
const StationListTable = (props: IStationListTableProps) => {
    const {stationList, page, onPageChange, onRowDelete} = props;
    
    return (
        <Pagenation
            page={page}
            size={PAGESIZE}
            pageCount={stationList.pageCount}
            totalCount={stationList.totalCount}
            onPageChange={onPageChange}
        >
            <table className="table table-sm table-hover">
                <thead className="bg-info">
                    <tr>
                        {tableHeaders.map((h, idx) => <th key={`header ${h}`}>{h}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {stationList.chunk.map(data => <StationListTableRow rowData={data} onDelete={onRowDelete} />)}
                </tbody>
            </table>
        </Pagenation>
    )
}

interface IStationListTableRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    rowData: StationDetailWithChannel;
    onDelete: () => void;
}

const StationListTableRow = (props: IStationListTableRowProps) => {
    const {rowData, onDelete, ...rowProps} = props;

    const [deleting, _none, deleteStart] = useApiResponse(
        stationApi.deleteStationRaw.bind(stationApi),
        React.useCallback((err: Response) => {
            if (err.status === 404) {
                alert("対象が見つかりませんでした");
            }
            else {
                alert("削除失敗");
            }
        }, []),
        React.useCallback((data) => onDelete(), [onDelete])
    );

    const onDeleteButtonClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (!window.confirm(`${rowData.stationNumber}のすべてのチャンネルを削除します。\nよろしいですか？`)) {
            return;
        }
        deleteStart({
            stationID: rowData.stationId
        });
    }

    const [name, setName] = React.useState(rowData.stationName);
    const onNameChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setName(ev.currentTarget.value);
    }


    const [nameEditMode, setNameEditMode] = React.useState(false);

    const [nameSaving, _none2, nameSaveStart] = useApiResponse(
        stationApi.updateStationNameRaw.bind(stationApi),
        React.useCallback((err: Response) => {
            if (err.status === 404) {
                alert("対象が見つかりませんでした");
            }
            else {
                alert(`更新失敗 code=${err.status}`);
            }
            setNameEditMode(false);
        }, []),
        React.useCallback((data) => setNameEditMode(false), [])
    );

    const onNameSaveClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        nameSaveStart({
            stationID: rowData.stationId,
            stationName: {
                newName: name
            }
        });
    }
    const onToEditModeClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setNameEditMode(true);
    }
    const onEditCancelClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        setNameEditMode(false);
        setName(rowData.stationName);
    }
    let nameCell = (
        <div className="d-flex align-items-center justify-content-between">
            <span>{name}</span>
            <button className="btn btn-sm btn-light border ml-3" onClick={onToEditModeClick}>局名変更</button>
        </div>
    );
    if (nameEditMode && nameSaving) {
        nameCell = (
            <div className="d-flex align-items-center justify-content-between">
                <span>{name}</span>
                <span className="ml-3">saving...</span>
            </div>
        );
    }
    else if (nameEditMode && !nameSaving) {
        nameCell = (
            <div className="d-flex align-items-center justify-content-between">
                <input className="form-control-sm col-8" onChange={onNameChange} type="text" value={name} />
                <div className="col-4 text-right">
                    <button className="btn btn-sm btn-primary border ml-3" onClick={onNameSaveClick}>保存</button>
                    <button className="btn btn-sm btn-warning border ml-3" onClick={onEditCancelClick}>キャンセル</button>
                </div>
            </div>
        );
    }

    let deleteButton = <button className="btn btn-sm btn-danger" onClick={onDeleteButtonClick}>削除</button>
    if (deleting) {
        deleteButton = <span>deleting...</span>
    }

    return (
        <tr {...rowProps}>
            <td>
                {rowData.stationNumber}
            </td>
            <td>
                {nameCell}
            </td>
            <td>
                {rowData.channel}
            </td>
            <td>
                {rowData.area}
            </td>
            <td>
                {deleteButton}
            </td>
        </tr>
    )
}