/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface PasswordUpdateRequest
 */
export interface PasswordUpdateRequest {
    /**
     * 旧パスワーチE     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    oldPassword: string;
    /**
     * 新パスワーチE     * @type {string}
     * @memberof PasswordUpdateRequest
     */
    newPassword: string;
}

export function PasswordUpdateRequestFromJSON(json: any): PasswordUpdateRequest {
    return PasswordUpdateRequestFromJSONTyped(json, false);
}

export function PasswordUpdateRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PasswordUpdateRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'oldPassword': json['oldPassword'],
        'newPassword': json['newPassword'],
    };
}

export function PasswordUpdateRequestToJSON(value?: PasswordUpdateRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'oldPassword': value.oldPassword,
        'newPassword': value.newPassword,
    };
}


