import { Token } from "Components/Token";
// import { ApiResponse, LoginApi } from "library/openapi";
import { ApiResponse} from "./runtime";
import {LoginApi} from "./LoginApi";
import * as React from 'react';

const isResponse = (instance: any): instance is Response => {
    return instance.status !== undefined && instance.statusText !== undefined && instance.text !== undefined && instance.json !== undefined;
}
const isApiResponse = <T>(instance: any): instance is ApiResponse<T> => {
    return instance.raw !== undefined && isResponse(instance.raw) && instance.value !== undefined;
}

async function ApiRequestAsync<RequestParameter, ResponseType>(
    api: (requestParameters: RequestParameter) => Promise<ApiResponse<ResponseType>>,
    parameter: RequestParameter
): Promise<ApiResponse<ResponseType> | Response> {

    let result: ApiResponse<ResponseType> | Response;
    
    try {
        const firstTry = await api(parameter);
        result = firstTry;
    }
    catch (err) {
        console.log(err);
        const response: Response = err;

        if (response.status === 401) {
            // 一度だけ認証リトライ
            try {
                const {accessToken} = await new LoginApi().updateToken();
                Token.instance.token = accessToken;

                const secondTry = await api(parameter);
                result = secondTry;
            }
            catch (err2) {
                result = err2 as Response;
            }
        }
        else {
            result = response;
        }
    }

    return result;
}

/**
 * API経由でデータを取得するcustom hook
 * @param api 使用するAPI
 * @param onError エラーハンドリング
 */
export const useApiResponse = <RequestParameterType, ResultDataType>(
    api: (requestParameters: RequestParameterType) => Promise<ApiResponse<ResultDataType>>,
    onError?: (res: Response) => void,
    onSuccess?: (result: ResultDataType) => void
): [boolean, (ResultDataType | null), (req: RequestParameterType) => void] => {
    const [nowLoading, setNowLoading] = React.useState(false);
    const [data, setData] = React.useState<ResultDataType | null>(null);
    const [promise, setPromise] = React.useState<Promise<ApiResponse<ResultDataType> | Response> | null>(null);

    const callback = React.useCallback((param: RequestParameterType) => {
        console.log(param);
        setNowLoading(true);
        setPromise(ApiRequestAsync(api, param));
    }, []);

    React.useEffect(() => {
        if (!promise) {
            return;
        }

        let isUnmounted = false;
        const asyncFunc = async () => {
            try {
                const result = await promise;
                if (isResponse(result)) {
                    if (onError) {
                        onError(result);

                        if (result.status === 401) {
                            alert('認証期限切れです');
                            window.location.href = '/Login';
                        }
                    }
                }
                else if (!isUnmounted && isApiResponse<ResultDataType>(result)) {
                    const data = await result.value();

                    setData(data);
                    
                    if (onSuccess) {
                        onSuccess(data);
                    }
                }
            }
            finally {
                if (!isUnmounted) {
                    setNowLoading(false);
                }
            }
        }

        asyncFunc();
        return () => {isUnmounted = true;};
    }, [promise]);

    return [nowLoading, data, callback];
}