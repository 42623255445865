import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { StationApi } from "../Components/api/StationApi";
import {
  Station,
  StationDetailWithUps,
  UpsDetail,
  UpsStatusLogChunk,
} from "../library/openapi/models";
import { UpsApi } from "../Components/api/UpsApi";
import "../css/Common.css";
import { UpsLogTable } from "../Components/UpsStatusLogTable";
// import { DateFormatYYYYMMDDHHmmss } from "../Components/Date";
import { formatDate } from "../Components/myClass/formatAnyDate";

//PATHにパラメータをしているので、ここで宣言してpropに格納
interface paramDesign {
  stationID: string | undefined;
}

interface Props extends RouteComponentProps<paramDesign> {}

interface VenderProps {
  list: string[];
  selectedValue: string;
}

const StationDetail: React.FC<Props> = ({
  match,
  history,
  location,
}: Props) => {
  const [station, setStation] = React.useState<StationDetailWithUps>();
  const [vendorList, setVendorList] = React.useState<string[]>();
  const [vendorSelected, setvendorSelected] = React.useState<string>("");
  const [isSetDevice, setIsSetDevice] = React.useState<boolean>(false);
  const [upsDetail, setUpsDetail] = React.useState<UpsDetail>();
  const [
    upsStatusLogChunk,
    setUpsStatusLogChunk,
  ] = React.useState<UpsStatusLogChunk>();

  const stationID = match.params.stationID ? parseInt(match.params.stationID) : undefined;

  //#region =====API呼び出し=====

  //基地局情報取得
  const getDetail = () => {
    if (!stationID) {
      return;
    }

    let stationApi = new StationApi();

    stationApi
      .getStationRaw({ stationID })
      .then((res) => {
        if (res.raw.status === 200) {
          res.value().then((value) => {
            console.log(value);
            setStation(value);
            setvendorSelected(value.vendor ?? "");

            if (value.ups?.manageSerial) {
              setIsSetDevice(true);
            } else {
              setIsSetDevice(false);
            }
          });
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          console.log(err.reason);
          console.log(err.hint);
          alert(err.reason);
          return;
        }
        console.log(err);
        alert("局情報取得失敗");
      });
  };

  //ベンダー一覧取得
  const getVendorList = () => {
    let stationApi = new StationApi();

    stationApi
      .getVendorListRaw()
      .then((res) => {
        if (res.raw.status === 200) {
          res.value().then((value) => {
            setVendorList(value);
          });
        }
      })
      .catch((err) => {
        if (err.status === 401) {
          console.log(err.reason);
          console.log(err.hint);
          alert(err.reason);
          return;
        }
        console.log(err);
        alert("局情報取得失敗");
      });
  };

  //ベンダー更新
  const updateVendor = () => {
    let stationApi = new StationApi();

    if (stationID) {
      stationApi
        .updateStationVendorRaw({
          stationID,
          updateStationVendorRequest: { vendor: vendorSelected },
        })
        .then((res) => {
          if (res.raw.status === 204) {
            res.value().then((value) => {
              alert("ベンダー名を更新完了");
            });
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log(err.reason);
            console.log(err.hint);
            alert(err.reason);
            return;
          }
          console.log(err);
          alert("ベンダー更新失敗");
        });
    }
  };

  //UPSデバイス情報取得
  const getUpsDetail = () => {
    if (!stationID || !station?.ups?.manageSerial) {
      return;
    }

    const stationApi = new StationApi();

    if (station?.ups?.manageSerial) {
      stationApi
        .getLockedUpsRaw({
          stationID,
          manageSerial: station.ups.manageSerial,
        })
        .then((res) => {
          if (res.raw.status === 200) {
            res.value().then((value) => {
              console.log(value);
              setUpsDetail(value);
            });
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log(err.reason);
            console.log(err.hint);
            alert(err.reason);
            return;
          }
          console.log(err);
          alert("デバイス情報取得失敗");
        });
    }
  };

  //UPS状態の初期化
  const clearUPSStatus = () => {
    if (!station?.ups?.manageSerial) {
      return;
    }

    const upsApi = new UpsApi();

    if (station?.ups?.manageSerial) {
      upsApi
        .clearStatusRaw({ manageSerial: station.ups.manageSerial })
        .then((res) => {
          if (res.raw.status === 204) {
            res.value().then((value) => {
              console.log(value);
              alert("受信状態を初期化しました。");
            });
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log(err.reason);
            console.log(err.hint);
            alert(err.reason);
            return;
          }
          console.log(err);
          alert("受信状態の初期化に失敗しました");
        });
    }
  };

  //UPSの設置解除
  const unlockUpsRaw = () => {
    if (!stationID || !station?.ups?.manageSerial) {
      return;
    }

    const stationApi = new StationApi();

    if (station?.ups?.manageSerial) {
      stationApi
        .unlockUpsRaw({
          stationID,
          manageSerial: station.ups.manageSerial,
        })
        .then((res) => {
          if (res.raw.status === 204) {
            res.value().then((value) => {
              console.log(value);
              alert("設置中のデバイスを解除しました。");
            });
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log(err.reason);
            console.log(err.hint);
            alert(err.reason);
            return;
          } else if (err.status === 409) {
            alert("設置されていないデバイスです。");
            return;
          }
          console.log(err);
          alert("設置登録の解除に失敗しました");
        });
    }
  };

  //UPS受信ログ取得
  const getUpsStatusLogRaw = (page: number = 0) => {
    if (!station?.ups?.manageSerial) {
      return;
    }

    const upsApi = new UpsApi();

    if (station?.ups?.manageSerial) {
      upsApi
        .getUpsStatusLogRaw({
          manageSerial: station.ups.manageSerial,
          page: page,
        })
        .then((res) => {
          if (res.raw.status === 200) {
            res.value().then((value) => {
              console.log(value);
              setUpsStatusLogChunk(value);
            });
          }
        })
        .catch((err) => {
          if (err.status === 401) {
            console.log(err.reason);
            console.log(err.hint);
            alert(err.reason);
            return;
          } else if (err.status === 409) {
            alert("設置されていないデバイスです。");
            return;
          }
          console.log(err);
          alert("設置登録の解除に失敗しました");
        });
    }
  };

  //#endregion



  const onChangeVendor = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setvendorSelected(event.target.value);
  };

  const clickSetDevice = () => {
    history.push(`/SetDevice/${stationID}`);
  };

  const clickUpdateVendor = () => {
    updateVendor();
  };

  const handleClearUPSStatus = () => {
    if (!window.confirm("受信状態を初期化します。よろしいですか？")) {
      return;
    }

    clearUPSStatus();
    getUpsDetail();
    getUpsStatusLogRaw();
  };

  const handleUnlockUpsRaw = React.useCallback(
    () => {
      unlockUpsRaw();
    },
    [],
  );

  const handleRefreshLog = React.useCallback(() => {
    getUpsStatusLogRaw();
  },[]);

  //ベンダー一覧描写
  const Vender = () => {
    console.debug("ベンダー一覧描写");

    let items: JSX.Element[] = [];
    if (vendorList) {
      items.push(<option key={99} value=""></option>);
      vendorList.forEach((item, index) => {
        if (vendorSelected == item) {
          items.push(
            <option key={index} value={item} selected>
              {item}
            </option>
          );
        } else {
          items.push(
            <option key={index} value={item}>
              {item}
            </option>
          );
        }
      });
    } else {
      items.push(<option key={99} value="" selected></option>);
    }

    return (
      <>
        <select name="vendor" id="vendor" onChange={onChangeVendor}>
          {items}
        </select>
      </>
    );
  };


  const DisplayDeviceInfo = () => {
    if (isSetDevice) {
      return (
        <>
          <div className="d-flex flex-wrap mb-2 ">
            <label htmlFor="" className="w-Fixed-10em">
              デバイスID：
            </label>
            <label htmlFor="">{upsDetail?.deviceId}</label>
          </div>
          <div className="d-flex flex-wrap mb-2 ">
            <label htmlFor="" className="w-Fixed-10em">
              管理番号：
            </label>
            <label htmlFor="">{upsDetail?.manageSerial}</label>
          </div>
          <div className="d-flex flex-wrap mb-2 ">
            <label htmlFor="" className="w-Fixed-10em">
              初回受信日時：
            </label>
            <label htmlFor="">
              {formatDate(
                upsDetail?.firstStatusLog?.receivedDate
              )}
            </label>
          </div>
          <div className="d-flex flex-wrap mb-2 ">
            <label htmlFor="" className="w-Fixed-10em">
              最終受診日時：
            </label>
            <label htmlFor="">
              {formatDate(upsDetail?.lastStatusLog?.receivedDate)}
            </label>
          </div>
          <div className="d-flex flex-wrap mb-2 ">
            <label htmlFor="" className="w-Fixed-10em">
              最終受診状態：
            </label>
            <label htmlFor="">★取得方法確認★</label>
          </div>
          <div className="d-flex flex-row-reverse mb-2 ">
            <button
              type="button"
              className="btn btn-sm btn-danger shadow-sm"
              onClick={handleClearUPSStatus}
            >
              受診状態を初期化
            </button>
          </div>
          <div className="d-flex flex-row-reverse mb-2 ">
            <button
              type="button"
              className="btn btn-sm btn-danger shadow-sm"
              onClick={handleUnlockUpsRaw}
            >
              設置登録を解除
            </button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <button
            type="button"
            className="btn btn-sm btn-success shadow-sm"
            onClick={clickSetDevice}
          >
            <FontAwesomeIcon icon={faPlus} /> 設置登録
          </button>
        </>
      );
    }
  };

  const DisplayDeviceLog = () => {
    if (isSetDevice) {
      return (
        <div className="row mt-3">
          <div className="col">
            <button
              type="button"
              onClick={handleRefreshLog}
              className="btn btn-sm btn-outline-primary mb-1"
            >
              <FontAwesomeIcon icon={faSyncAlt} />
              &nbsp;更新
            </button>
          </div>
          <div className="col-auto ml-auto">
            全{upsStatusLogChunk?.totalCount ?? 0}件
          </div>
          <div className="col-auto ml-auto">ダウンロード実装部</div>
          <div className="col-12">
            <table className="table table-sm table-hover">
              <thead className="bg-info">
                <tr>
                  <th>受信日時</th>
                  <th>ステータス</th>
                  <th>バッテリーステータス</th>
                </tr>
              </thead>
              <tbody>
                <UpsLogTable
                  upsLogs={upsStatusLogChunk}
                  clickPager={getUpsStatusLogRaw}
                />
              </tbody>
              <tfoot>
                <tr>
                  <td>{}</td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const renderVender = Vender();

  const displayDeviceInfo = DisplayDeviceInfo();

  const displayDeviceLog = DisplayDeviceLog();




  React.useEffect(() => {
    getDetail();
    getVendorList();
  }, []);

  React.useEffect(() => {
    if (isSetDevice) {
      getUpsDetail();
    }
  }, [isSetDevice]);



  return (
    <React.Fragment>
      <h4 className="m-3 font-wight-bold d-inline-block">基地局詳細</h4>
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={history.goBack}
      >
        前ページ
      </button>
      <div className="container">
        <div className="row">
          {/* 左画面 */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">局番：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.stationNumber}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">基地局名：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.stationName}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">セクタ番号：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.sectorId}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">CH区分：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.channelType}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">端子タイプ：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.terminalType}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">緯度：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.latitude}</label>
              </div>
            </div>
            <div className="form-row">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">経度：</label>
              </div>
              <div className="col-auto">
                <label htmlFor="">{station?.longitude}</label>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="col-6 col-sm-6 col-md-6 col-lg-3">
                <label htmlFor="">UPSベンダ名：</label>
              </div>
              <div className="col-auto">{renderVender}</div>
              <div className="col-auto">
                <button
                  type="button"
                  className="btn btn-sm btn-warning shadow-sm"
                  onClick={clickUpdateVendor}
                >
                  更新
                </button>
              </div>
            </div>
          </div>
          {/* 右画面 */}
          <div className="col-12 col-sm-12 col-md-6 col-lg-6">
            <div className="card border-primary">
              <div className="card-header py-1">デバイス情報</div>
              <div className="card-body">{displayDeviceInfo}</div>
            </div>
          </div>
        </div>
        {displayDeviceLog}
      </div>
    </React.Fragment>
  );
};

export default StationDetail;
