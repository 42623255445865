/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface ErrorStation
 */
export interface ErrorStation {
    /**
     * 
     * @type {number}
     * @memberof ErrorStation
     */
    no?: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    area?: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStation
     */
    stationId: number;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    stationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    stationName: string;
    /**
     * 
     * @type {number}
     * @memberof ErrorStation
     */
    channel?: number;
    /**
     * 
     * @type {number}
     * @memberof ErrorStation
     */
    manageSerial?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    trigger?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    matter?: string;
    /**
     * 
     * @type {Date}
     * @memberof ErrorStation
     */
    occurrence?: Date;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    passedTimes?: string;
    /**
     * 
     * @type {string}
     * @memberof ErrorStation
     */
    state?: string;
    /**
     * 
     * @type {Date}
     * @memberof ErrorStation
     */
    recovered?: Date | null;
}

export function ErrorStationFromJSON(json: any): ErrorStation {
    return ErrorStationFromJSONTyped(json, false);
}

export function ErrorStationFromJSONTyped(json: any, ignoreDiscriminator: boolean): ErrorStation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'no': !exists(json, 'no') ? undefined : json['no'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'stationId': json['stationId'],
        'stationNumber': json['stationNumber'],
        'stationName': json['stationName'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'manageSerial': !exists(json, 'manageSerial') ? undefined : json['manageSerial'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'trigger': !exists(json, 'trigger') ? undefined : json['trigger'],
        'matter': !exists(json, 'matter') ? undefined : json['matter'],
        'occurrence': !exists(json, 'occurrence') ? undefined : (new Date(json['occurrence'])),
        'passedTimes': !exists(json, 'passedTimes') ? undefined : json['passedTimes'],
        'state': !exists(json, 'state') ? undefined : json['state'],
        'recovered': !exists(json, 'recovered') ? undefined : (json['recovered'] === null ? null : new Date(json['recovered'])),
    };
}

export function ErrorStationToJSON(value?: ErrorStation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'no': value.no,
        'area': value.area,
        'stationId': value.stationId,
        'stationNumber': value.stationNumber,
        'stationName': value.stationName,
        'channel': value.channel,
        'manageSerial': value.manageSerial,
        'deviceId': value.deviceId,
        'trigger': value.trigger,
        'matter': value.matter,
        'occurrence': value.occurrence === undefined ? undefined : (value.occurrence.toISOString()),
        'passedTimes': value.passedTimes,
        'state': value.state,
        'recovered': value.recovered === undefined ? undefined : (value.recovered === null ? null : value.recovered.toISOString()),
    };
}


