/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    UserInfo,
    UserInfoFromJSON,
    UserInfoFromJSONTyped,
    UserInfoToJSON,
} from './';

/**
 * 
 * @export
 * @interface LoginResult
 */
export interface LoginResult {
    /**
     * 認証用ト�Eクン(1H)
     * @type {string}
     * @memberof LoginResult
     */
    accessToken: string;
    /**
     * 認証用ト�Eクン更新API専用ト�Eクン(12H)
     * @type {string}
     * @memberof LoginResult
     */
    updateToken: string;
    /**
     * 
     * @type {UserInfo}
     * @memberof LoginResult
     */
    userInfo: UserInfo;
}

export function LoginResultFromJSON(json: any): LoginResult {
    return LoginResultFromJSONTyped(json, false);
}

export function LoginResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResult {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'accessToken': json['accessToken'],
        'updateToken': json['updateToken'],
        'userInfo': UserInfoFromJSON(json['userInfo']),
    };
}

export function LoginResultToJSON(value?: LoginResult | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'accessToken': value.accessToken,
        'updateToken': value.updateToken,
        'userInfo': UserInfoToJSON(value.userInfo),
    };
}


