import * as React from "react";
import { Pagenation } from "../Components/Pagination";
import { UpsStatusLogChunk } from "../library/openapi/models/UpsStatusLogChunk";
// import {DateFormatYYYYMMDDHHmmss} from "../Components/Date";
import { formatDate } from "../Components/myClass/formatAnyDate"

interface Props {
  upsLogs: UpsStatusLogChunk | undefined;
  clickPager: any;
}

export const UpsLogTable: React.FC<Props> = ({
  upsLogs,
  clickPager,
}: Props) => {

if(!upsLogs){
  return <div>データなし</div>;
}

const clickPagerEvent = (page: number) => {
  clickPager(page);
};

const rows = upsLogs?.chunk.map((value, index) => (
  <tr key={index} className={isErrorRowCss(value.isError)}>
    <td>{formatDate(value.receivedDate)}</td>
    <td>{value.statusCode}</td>
    <td>{value.batteryStatus}</td>
  </tr>
))

const isErrorRowCss = (isErr:boolean|null|undefined) =>{
  if(isErr){
    return "bg-danger"
  }
}

//ページャー生成
const pager = () =>{

  const pagenation = new Pagenation(
    upsLogs?.page ?? 0,
    upsLogs?.pageCount ?? 0,
  )

  let pagerNumbers: number[] = pagenation.pageNumbers();

  let pagerElement:JSX.Element[] = [];

  let prevPage:number = (upsLogs?.page ?? 0) - 1;
  let nextPage:number = (upsLogs?.page ?? 0) + 1;

  if(prevPage < 0){
    prevPage = 0;
  }

  if(nextPage >= (upsLogs?.pageCount ?? 0)){
    nextPage = (upsLogs?.pageCount?? 1) - 1;
  }

  pagerNumbers.forEach(page => {
      if(page == (upsLogs?.page ?? 0)){
        pagerElement.push(<li className="page-item active" aria-current="page"><button type="button" className="page-link" onClick={()=>clickPagerEvent(page)}>{page + 1}</button></li>);
      }
      else{
        pagerElement.push(<li className="page-item"><button type="button" className="page-link" onClick={()=>clickPagerEvent(page)} >{page + 1}</button></li>);
      }
    
  });

  const footerElement = pager();
  
  return(
    <ul className="pagination">
                  <li className="page-item">
        <button type="button" className="page-link" onClick={()=>clickPagerEvent(0)} >
          最初
        </button>
      </li>
      <li className="page-item">
        <button type="button" className="page-link" onClick={()=>clickPagerEvent(prevPage)} >
          前へ
        </button>
      </li>
      {pagerElement}
      <li className="page-item">
        <button type="button" className="page-link" onClick={()=>clickPagerEvent(nextPage)}>
          次へ
        </button>
      </li>
                <li className="page-item">
        <button type="button" className="page-link" onClick={()=>clickPagerEvent((upsLogs?.pageCount?? 1) -1)}>
          最後
        </button>
      </li>
      </ul>
  )


}

const footerElement = pager();

return (
  <table className="table table-sm table-hover">
  <thead className="bg-info">
    <tr>
      <th>受信日時</th>
      <th>ステータス</th>
      <th>バッテリーステータス</th>
    </tr>
  </thead>
  <tbody>{rows}</tbody>
  <tfoot>
    <tr>
<td>{(upsLogs?.page?? 0 + 1)}/{upsLogs?.pageCount ?? 1}</td>
      <td>{footerElement}</td>
    </tr>
  </tfoot>
</table>
)

};
