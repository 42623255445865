import * as React from "react";
import { Token } from "./Components/Token";
import { LoginRequest } from "./library/openapi/models/LoginRequest";
import { LoginResult } from "./library/openapi/models/LoginResult";
// import {
//   LoginApi,
//   LoginOperationRequest,
// } from "./library/openapi/apis/LoginApi";
import { LoginApi, LoginOperationRequest } from "./Components/api/LoginApi";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  Router,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import { User } from "./Components/User";
import { ApiResponse } from "./Components/api/runtime";
import { passwordUpdatePath } from "pages/PasswordUpdate";
import { useUserInfo } from "Components/UserInfoContext";

interface Props extends RouteComponentProps {}


const Login: React.FC<Props> = ({ match, history, location }: Props) => {
  const [userId, setUserId] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [onClick, setOnclick] = React.useState(false);
  const [isAuthorizing, setIsAuthorizing] = React.useState(false);

  const {setter: userInfoSetter} = useUserInfo();

  const handleSetUserId = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setUserId(event.target.value);
    },
    [userId]
  );

  const handleSetPassword = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    },
    [password]
  );

  //   const clickLogin = React.useCallback(async () => {
  //     const id: string = userId;
  //     const pass: string = password;
  //     await execLogin(id, pass);
  //   }, [userId, password]);

  const handleOnclick = React.useCallback(() => {
    // setOnclick(true);
    // clickLogin();
    let id: string = userId;
    let pass: string = password;
    execLogin(id, pass);
  }, [userId,password]);



  // const clickLogin = React.useCallback(() => {
  //   const id: string = userId;
  //   const pass: string = password;
  //   execLogin(id, pass);
  // },[]);

  // React.useEffect(() => {
  //   if (onClick) {
  //     clickLogin();
  //   }
  // }, [onClick]);
  

  //ログイン実行
  const execLogin = (userId: string, password: string) => {
    const call: LoginApi = new LoginApi();

    
    let loginPara: LoginOperationRequest = {
      loginRequest: {
        userId: userId ?? "",
        password: password ?? "",
      },
    };

    try {
      call
        .loginRaw(loginPara)
        .then((res: ApiResponse<LoginResult>) => {
          if (res.raw.status === 200) {
            res.value().then((value) => {
              console.log("ログイン成功");

              User.getInstance.setUserId(value.userInfo.id);
              User.getInstance.setUserName(value.userInfo.name);
              Token.instance.token = value.accessToken;
              Token.instance.updateToken = value.updateToken;

              userInfoSetter({
                id: value.userInfo.id,
                name: value.userInfo.name,
                roles: value.userInfo.roles
              });

              setIsAuthorizing(true);
            });
          } else if (res.raw.status === 205) {
            console.log("パスワード切れ");
            alert("パスワードの有効期限が切れています。再設定してください。");
            history.push(passwordUpdatePath(userId));
          } else if (res.raw.status === 403) {
            console.log("アカウントロック中");
            alert("アカウントロック中");
          } else {
            console.log("ログイン失敗");
            alert("ログイン失敗");
          }
        })
        .catch((err) => {
          console.log("ERROR:ログイン失敗");
          console.log(err);
        });
    } catch (e) {
      console.log("ERROR:ログイン失敗");
      console.log(e);
      alert("ERROR:ログイン失敗");
    }
  };

  /*
   * useEffectは、第一引数にcallbackを入れて、第二引数に依存する値の配列を入れる
   * 依存する値が変更される度にcallbackが実行される
   */
  React.useEffect(() => {
    if (isAuthorizing) {
      return history.push("/Top");
    }
  }, [isAuthorizing]);

  return (
    <React.Fragment>
      {/* <p>{userId}</p>
      <p>{password}</p> */}
      <div className="container mt-5">
        <div className="d-flex  justify-content-center h-100 mb-5">
          <h3>UPS監視システム</h3>
        </div>
        <div className="d-flex justify-content-center h-100 ">
          <div className="card">
            <div className="card-header">
              <h3> Login</h3>
            </div>
            <div className="card-body">
              <form>
                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-user"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control ime-inactive"
                    placeholder="ユーザーID"
                    value={userId}
                    onChange={handleSetUserId}
                  />
                </div>
                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fas fa-key"></i>
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="パスワード"
                    value={password}
                    onChange={handleSetPassword}
                  />
                </div>
                <div className="form-group">
                  <input
                    type="button"
                    value="ログイン"
                    onClick={handleOnclick}
                    className="btn float-right btn-primary login_btn w-100"
                  />
                </div>
              </form>
            </div>
            {/* <div className="card-footer">
              <div className="d-flex justify-content-center">
                <a href="#">パスワード再設定※準備中</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);
