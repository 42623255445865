/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    StationDetailWithChannelAllOf,
    StationDetailWithChannelAllOfFromJSON,
    StationDetailWithChannelAllOfFromJSONTyped,
    StationDetailWithChannelAllOfToJSON,
    StationWithChannel,
    StationWithChannelFromJSON,
    StationWithChannelFromJSONTyped,
    StationWithChannelToJSON,
} from './';

/**
 * 
 * @export
 * @interface StationDetailWithChannel
 */
export interface StationDetailWithChannel {
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    stationId: number;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithChannel
     */
    stationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithChannel
     */
    stationName: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    latitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    longitude?: number | null;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    sectorId: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    terminalType: number;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    channelType: number;
    /**
     * 
     * @type {string}
     * @memberof StationDetailWithChannel
     */
    area?: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    channel: number;
    /**
     * UPSベンダ
     * @type {string}
     * @memberof StationDetailWithChannel
     */
    vendor?: string;
    /**
     * 
     * @type {number}
     * @memberof StationDetailWithChannel
     */
    priority?: number | null;
    /**
     * 行動計画
     * @type {string}
     * @memberof StationDetailWithChannel
     */
    actionScedule?: string;
}

export function StationDetailWithChannelFromJSON(json: any): StationDetailWithChannel {
    return StationDetailWithChannelFromJSONTyped(json, false);
}

export function StationDetailWithChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationDetailWithChannel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'stationId': json['stationId'],
        'stationNumber': json['stationNumber'],
        'stationName': json['stationName'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'sectorId': json['sectorId'],
        'terminalType': json['terminalType'],
        'channelType': json['channelType'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'channel': json['channel'],
        'vendor': !exists(json, 'vendor') ? undefined : json['vendor'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'actionScedule': !exists(json, 'actionScedule') ? undefined : json['actionScedule'],
    };
}

export function StationDetailWithChannelToJSON(value?: StationDetailWithChannel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'stationId': value.stationId,
        'stationNumber': value.stationNumber,
        'stationName': value.stationName,
        'latitude': value.latitude,
        'longitude': value.longitude,
        'sectorId': value.sectorId,
        'terminalType': value.terminalType,
        'channelType': value.channelType,
        'area': value.area,
        'channel': value.channel,
        'vendor': value.vendor,
        'priority': value.priority,
        'actionScedule': value.actionScedule,
    };
}


