type HttpMethodType = "POST" | "GET" | "PUT" | "DELETE" | "HEAD" | "OPTIONS" | "PATCH";

export class AuthorizationHeaderInfo {
    public schema: string = "";
    public key: string = "";
}

export interface XHRResponse<BodyDataType> {
    ok: boolean;
    status: number;
    statusText: string;
    body?: BodyDataType;
}

// 本当はfetchでやりたいけど現在の仕様(2021/01/25時点)ではuploadのprogressを取得できないのでXMLHttpRequestでやる
export class XHRWrapper<ResponseType> {
    onProgress?: (ev: ProgressEvent<EventTarget>) => void;
    onAbort?: (ev: ProgressEvent<EventTarget>) => void;
    onLoadstart?: (ev: ProgressEvent<EventTarget>) => void;
    onLoadend?: (ev: ProgressEvent<EventTarget>) => void;

    private isUpload: boolean;
    constructor(upload = false) {
        this.isUpload = upload;
    }

    private xhr = new XMLHttpRequest();
    public get response(): XHRResponse<ResponseType> {
        const res: XHRResponse<ResponseType> = {
            ok: (this.xhr.status >= 200 && this.xhr.status < 300),
            status: this.xhr.status,
            statusText: this.xhr.statusText,
            body: this.xhr.response
        }

        return res;
    }

    public authorizeHeader: AuthorizationHeaderInfo | undefined = undefined;

    send<ParameterType>(url: string, method: HttpMethodType, data?: ParameterType, timeout = 60000): Promise<ResponseType> {
        return new Promise<ResponseType>((resolve, reject) => {
            this.xhr.open(method, url);

            this.xhr.timeout = timeout;
            this.xhr.responseType = "json";
            this.xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");

            if (this.authorizeHeader) {
                this.xhr.setRequestHeader("Authorization", `${this.authorizeHeader.schema} ${this.authorizeHeader.key}`);
            }

            const eventTargetObj = this.isUpload ? this.xhr.upload : this.xhr;

            this.xhr.onload = (evt) => {
                if (this.xhr.readyState === 4 && (this.xhr.status >= 200 && this.xhr.status < 300)) {
                    resolve(this.xhr.response);  // jsonで指定しておくと自動的にパースされる
                } else {
                    reject(new Error(this.xhr.statusText));
                }
            };
            this.xhr.onerror = (evt) => {
                reject(new Error(this.xhr.statusText));
            }
            eventTargetObj.onprogress = this.onProgress ?? null;
            this.xhr.onabort = this.onAbort ?? null;
            this.xhr.onloadstart = this.onLoadstart ?? null;
            this.xhr.onloadend = this.onLoadend ?? null;

            if (data) {
                this.xhr.send(JSON.stringify(data));
            }
            else {
                this.xhr.send();
            }
        });
    }

    abort() {
        this.xhr.abort();
    }
}