/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
import {
    ChunkedListBase,
    ChunkedListBaseFromJSON,
    ChunkedListBaseFromJSONTyped,
    ChunkedListBaseToJSON,
    StationDetailWithChannel,
    StationDetailWithChannelFromJSON,
    StationDetailWithChannelFromJSONTyped,
    StationDetailWithChannelToJSON,
    StationListChunkAllOf,
    StationListChunkAllOfFromJSON,
    StationListChunkAllOfFromJSONTyped,
    StationListChunkAllOfToJSON,
} from './';

/**
 * 
 * @export
 * @interface StationListChunk
 */
export interface StationListChunk {
    /**
     * 
     * @type {number}
     * @memberof StationListChunk
     */
    page: number;
    /**
     * 
     * @type {number}
     * @memberof StationListChunk
     */
    size: number;
    /**
     * 
     * @type {number}
     * @memberof StationListChunk
     */
    totalCount: number;
    /**
     * 
     * @type {number}
     * @memberof StationListChunk
     */
    pageCount: number;
    /**
     * 
     * @type {Array<StationDetailWithChannel>}
     * @memberof StationListChunk
     */
    chunk: Array<StationDetailWithChannel>;
}

export function StationListChunkFromJSON(json: any): StationListChunk {
    return StationListChunkFromJSONTyped(json, false);
}

export function StationListChunkFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationListChunk {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'page': json['page'],
        'size': json['size'],
        'totalCount': json['totalCount'],
        'pageCount': json['pageCount'],
        'chunk': ((json['chunk'] as Array<any>).map(StationDetailWithChannelFromJSON)),
    };
}

export function StationListChunkToJSON(value?: StationListChunk | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'page': value.page,
        'size': value.size,
        'totalCount': value.totalCount,
        'pageCount': value.pageCount,
        'chunk': ((value.chunk as Array<any>).map(StationDetailWithChannelToJSON)),
    };
}


