/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from './runtime';
import {
    FailedResponse,
    FailedResponseFromJSON,
    FailedResponseToJSON,
    FileResponse,
    FileResponseFromJSON,
    FileResponseToJSON,
    LoginResult,
    LoginResultFromJSON,
    LoginResultToJSON,
    PasswordResetResult,
    PasswordResetResultFromJSON,
    PasswordResetResultToJSON,
    PasswordUpdateRequest,
    PasswordUpdateRequestFromJSON,
    PasswordUpdateRequestToJSON,
    UserInfoListChunk,
    UserInfoListChunkFromJSON,
    UserInfoListChunkToJSON,
    UserPreData,
    UserPreDataFromJSON,
    UserPreDataToJSON,
    UserRegistrationRequest,
    UserRegistrationRequestFromJSON,
    UserRegistrationRequestToJSON,
} from '../../library/openapi/models'

export interface DeleteUserRequest {
    id: string;
}

export interface GetUserListRequest {
    page: number;
    size?: number;
}

export interface RegisterUserRequest {
    userRegistrationRequest: UserRegistrationRequest;
}

export interface ResetPasswordRequest {
    id: string;
}

export interface UpdatePasswordRequest {
    id: string;
    passwordUpdateRequest: PasswordUpdateRequest;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * ユーザー論理削除
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザー論理削除
     */
    async deleteUser(requestParameters: DeleteUserRequest): Promise<void> {
        await this.deleteUserRaw(requestParameters);
    }

    /**
     * ユーザーIDと仮パスワードの発行
     */
    async getPreDataRaw(): Promise<runtime.ApiResponse<UserPreData>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserPreDataFromJSON(jsonValue));
    }

    /**
     * ユーザーIDと仮パスワードの発行
     */
    async getPreData(): Promise<UserPreData> {
        const response = await this.getPreDataRaw();
        return await response.value();
    }

    /**
     * ユーザー一覧
     */
    async getUserListRaw(requestParameters: GetUserListRequest): Promise<runtime.ApiResponse<UserInfoListChunk>> {
        if (requestParameters.page === null || requestParameters.page === undefined) {
            throw new runtime.RequiredError('page','Required parameter requestParameters.page was null or undefined when calling getUserList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/list/{page}`.replace(`{${"page"}}`, encodeURIComponent(String(requestParameters.page))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserInfoListChunkFromJSON(jsonValue));
    }

    /**
     * ユーザー一覧
     */
    async getUserList(requestParameters: GetUserListRequest): Promise<UserInfoListChunk> {
        const response = await this.getUserListRaw(requestParameters);
        return await response.value();
    }

    /**
     * ユーザー一覧
     */
    async getUserListFileRaw(): Promise<runtime.ApiResponse<FileResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/list/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => FileResponseFromJSON(jsonValue));
    }

    /**
     * ユーザー一覧
     */
    async getUserListFile(): Promise<FileResponse> {
        const response = await this.getUserListFileRaw();
        return await response.value();
    }

    /**
     * ユーザー登録
     */
    async registerUserRaw(requestParameters: RegisterUserRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userRegistrationRequest === null || requestParameters.userRegistrationRequest === undefined) {
            throw new runtime.RequiredError('userRegistrationRequest','Required parameter requestParameters.userRegistrationRequest was null or undefined when calling registerUser.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationRequestToJSON(requestParameters.userRegistrationRequest),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     * ユーザー登録
     */
    async registerUser(requestParameters: RegisterUserRequest): Promise<void> {
        await this.registerUserRaw(requestParameters);
    }

    /**
     * パスワードリセット
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest): Promise<runtime.ApiResponse<PasswordResetResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling resetPassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = typeof token === 'function' ? token("JWT", []) : token;

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/user/password/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => PasswordResetResultFromJSON(jsonValue));
    }

    /**
     * パスワードリセット
     */
    async resetPassword(requestParameters: ResetPasswordRequest): Promise<PasswordResetResult> {
        const response = await this.resetPasswordRaw(requestParameters);
        return await response.value();
    }

    /**
     * パスワード更新
     */
    async updatePasswordRaw(requestParameters: UpdatePasswordRequest): Promise<runtime.ApiResponse<LoginResult>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updatePassword.');
        }

        if (requestParameters.passwordUpdateRequest === null || requestParameters.passwordUpdateRequest === undefined) {
            throw new runtime.RequiredError('passwordUpdateRequest','Required parameter requestParameters.passwordUpdateRequest was null or undefined when calling updatePassword.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/user/password/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PasswordUpdateRequestToJSON(requestParameters.passwordUpdateRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResultFromJSON(jsonValue));
    }

    /**
     * パスワード更新
     */
    async updatePassword(requestParameters: UpdatePasswordRequest): Promise<LoginResult> {
        const response = await this.updatePasswordRaw(requestParameters);
        return await response.value();
    }

}
