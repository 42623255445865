import { UserInfo } from 'library/openapi/models';
import * as React from 'react';
import { User } from './User';

const initial: UserInfo = {id: "", name: "", roles: []};
const userContext = React.createContext<{info: UserInfo, setter: ((info: UserInfo) => void)}>({info: initial, setter: ((info) => {})});

export const UserInfoProvider = ({children}) => {
    const [userInfo, setUserInfo] = React.useState<UserInfo>(initial);

    const setter = (info: UserInfo) => {
        sessionStorage.setItem("UserInfo", JSON.stringify(info));
        setUserInfo({...info});
    }

    const stringifiedUserInfo = sessionStorage.getItem("UserInfo");
    React.useEffect(() => {
        if (stringifiedUserInfo) {
            setUserInfo(JSON.parse(stringifiedUserInfo));
        }
    }, [stringifiedUserInfo]);

    return (
        <userContext.Provider value={{info: userInfo, setter}}>
            {children}
        </userContext.Provider>
    )
}

export const useUserInfo = () => React.useContext(userContext);