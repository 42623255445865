import * as React from "react";

interface Props {}

export class Pagenation {
  private _page: number;
  private _pageCount: number;

  constructor(page: number, pageCount: number) {
    this._page = page;
    this._pageCount = pageCount;
  }

  get page() {
    return this._page;
  }

  get pageCount() {
    return this._pageCount;
  }

  set page(value: number) {
    this._page = value;
  }

  set pageCount(value: number) {
    this._pageCount = value;
  }

  pageNumbers(): number[] {
    let numbers: number[] = [];

    let pageNumbers = [...Array(this._pageCount)].map((_, i) => i);

    //5ページ以内の場合
    if (pageNumbers.length <= 5) {
      numbers = pageNumbers;
    }
    //5ページよりある場合
    else {
      if (this._page < 3) {
        numbers = pageNumbers.slice(0, 5);
      } else if (this._page > this._pageCount - 3) {
        numbers = pageNumbers.slice(-5);
    } else {
        numbers = pageNumbers.slice(this._page -2,this._page +3);
      }
    }

    // //ページサイズが5よりある場合
    // if (this._pageCount > 5) {
    //   if (this._page > this._pageCount - 2) {
    //     for (let i = this._pageCount; i < 5; i++) {
    //       //現在ページの前後5ページ分取得
    //       numbers.push(i);
    //     }
    //   }
    //   if (this._page > 2) {
    //     for (let i = 0; i < 5; i++) {
    //       //現在ページの前後5ページ分取得
    //       numbers.push(this._page - 2 + i);
    //     }
    //   } else {
    //     for (let i = 0; i < 5; i++) {
    //       //現在ページの前後5ページ分取得
    //       numbers.push(i);
    //     }
    //   }
    //   //ページサイズが5以下
    // } else {
    //   for (let i = 0; i < 5; i++) {
    //     //現在ページの前後5ページ分取得
    //     numbers.push(i);
    //   }
    // }

    return numbers;
  }
}
