/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface StationInfo
 */
export interface StationInfo {
    /**
     * 
     * @type {number}
     * @memberof StationInfo
     */
    no?: number;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    area?: string;
    /**
     * 
     * @type {number}
     * @memberof StationInfo
     */
    stationId: number;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    stationNumber: string;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    stationName: string;
    /**
     * 
     * @type {number}
     * @memberof StationInfo
     */
    channel?: number;
    /**
     * 
     * @type {number}
     * @memberof StationInfo
     */
    manageSerial?: number | null;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    trigger?: string;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    commercialPower?: string;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    upsError?: string;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    batteryStatus?: string;
    /**
     * 
     * @type {Date}
     * @memberof StationInfo
     */
    lastReceived?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    upsVendor?: string;
    /**
     * 
     * @type {string}
     * @memberof StationInfo
     */
    lqi?: string;
}

export function StationInfoFromJSON(json: any): StationInfo {
    return StationInfoFromJSONTyped(json, false);
}

export function StationInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StationInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'no': !exists(json, 'no') ? undefined : json['no'],
        'area': !exists(json, 'area') ? undefined : json['area'],
        'stationId': json['stationId'],
        'stationNumber': json['stationNumber'],
        'stationName': json['stationName'],
        'channel': !exists(json, 'channel') ? undefined : json['channel'],
        'manageSerial': !exists(json, 'manageSerial') ? undefined : json['manageSerial'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'trigger': !exists(json, 'trigger') ? undefined : json['trigger'],
        'commercialPower': !exists(json, 'commercialPower') ? undefined : json['commercialPower'],
        'upsError': !exists(json, 'upsError') ? undefined : json['upsError'],
        'batteryStatus': !exists(json, 'batteryStatus') ? undefined : json['batteryStatus'],
        'lastReceived': !exists(json, 'lastReceived') ? undefined : (json['lastReceived'] === null ? null : new Date(json['lastReceived'])),
        'upsVendor': !exists(json, 'upsVendor') ? undefined : json['upsVendor'],
        'lqi': !exists(json, 'lqi') ? undefined : json['lqi'],
    };
}

export function StationInfoToJSON(value?: StationInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'no': value.no,
        'area': value.area,
        'stationId': value.stationId,
        'stationNumber': value.stationNumber,
        'stationName': value.stationName,
        'channel': value.channel,
        'manageSerial': value.manageSerial,
        'deviceId': value.deviceId,
        'trigger': value.trigger,
        'commercialPower': value.commercialPower,
        'upsError': value.upsError,
        'batteryStatus': value.batteryStatus,
        'lastReceived': value.lastReceived === undefined ? undefined : (value.lastReceived === null ? null : value.lastReceived.toISOString()),
        'upsVendor': value.upsVendor,
        'lqi': value.lqi,
    };
}


