import * as React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { UpsApi, GetUpsListRequest } from "../Components/api/UpsApi";
import { useApiResponse } from "../Components/api/ApiWrapper";
import { UpsSummaryChunk } from "library/openapi/models";
import { TableBind } from "../Components/UPSListTable";
import { DownloadExcel } from "../Components/ExcelExport";

import "../css/Common.css";

interface Props extends RouteComponentProps {}

const timer = 60000;
let autoReloader: NodeJS.Timeout;
let intervalId:NodeJS.Timeout[] = new Array<NodeJS.Timeout>();

//最大500件表示
const pageCout = (count: number | undefined): number => {
  if (count) {
    if (count > 500) {
      return 500;
    } else {
      return count;
    }
  } else {
    return 0;
  }
};

const UPSMonitor: React.FC<Props> = ({ history }: Props) => {
  //自動更新用
  const [isAutoReload, ToggleisAutoReload] = React.useState<boolean>(true);
  const [searchClickCount, setSearchClickCount] = React.useState(0);

  const upsApi = new UpsApi();

  const [stationNum, setStationNum] = React.useState("");
  const [manageNum, setMangeNum] = React.useState("");
  const [stationName, setStationName] = React.useState("");
  const [checkOnlyError, ToggleCkeckOnlyerror] = React.useState(false);
  const [checkOnlyNotReceived, ToggleOnlyNotReceived] = React.useState(false);

  const onGetListError = (err: Response) => {
    // エラー種別ごとの処理はerr.statusで分岐
    alert("データ取得失敗");
  };

  const [nowListLoading, upsList, loader] = useApiResponse(
    upsApi.getUpsListRaw.bind(upsApi),
    onGetListError
  );

  const [nowDownload, upsDownload, downloadLoader] = useApiResponse(
    upsApi.getUpsListFileRaw.bind(upsApi),
    onGetListError
  );

  React.useEffect(() => {
    loader({ page: 0, size: 100 });
  }, []);

  const reqParam: GetUpsListRequest = { page: 0, size: 100 };

  //検索パラメータ追加：局番・局名・状態異常のみ・未受信のみ
  //todo:パラメータ追加依頼：管理番号
  if (
    stationName ||
    stationNum ||
    manageNum ||
    checkOnlyError ||
    checkOnlyNotReceived
  ) {
    reqParam.filter = {
      stationName: stationName,
      stationNumber: stationNum,
      upsManageSerial: Number(manageNum),
      onlyError: checkOnlyError,
      onlyNotReceived: checkOnlyNotReceived,
    };
  }

  React.useEffect(() => {
    loader(reqParam);
  }, [checkOnlyError, checkOnlyNotReceived]);

  const getUpsList = (page: number = 0) => {
    const param: GetUpsListRequest = { ...reqParam };
    param.page = page;
    loader(param);
  };

  //====handle===================
  const handleSetStationNum = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStationNum(event.target.value);
    },
    [stationNum]
  );

  const handleSetStationName = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setStationName(event.target.value);
    },
    [stationName]
  );

  const handleSetManageNum = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setMangeNum(event.target.value);
    },
    [manageNum]
  );

  const handleToggleCheckOnlyErrror = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      ToggleCkeckOnlyerror(event.target.checked);
      // loader(reqParam);
    },
    [checkOnlyError]
  );

  const handleToggleCheckOnlyNotReceived = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      ToggleOnlyNotReceived(event.target.checked);
      // loader(reqParam);
    },
    [checkOnlyNotReceived]
  );

  //詳細クリックイベント
  function onClickDetail(stationID: number) {
    history.push(`/StationDetail3/${stationID}`);
  }

  const handleSearchOnClick = () => {
    if (manageNum != "" && !Number(manageNum)) {
      alert("管理番号は数値で入力してください");
      return;
    }
    loader(reqParam);
  };

  //#region 自動更新機能 =========================
  const [reloader, setReloader] = React.useState<NodeJS.Timeout>();
  const refReloader = React.useRef(reloader);

  function startAutoreload() {
    autoReloader = setInterval(() => {
      loader(reqParam);
    }, timer);
    setReloader(autoReloader);
    intervalId.push(autoReloader);
  }

  function stopAutoReload() {
    if (reloader) {
      clearInterval(reloader);
    }
  }

  //自動更新機能
  React.useEffect(() => {
    if (isAutoReload) {
      stopAutoReload();
      startAutoreload();
    } else {
      stopAutoReload();
    }
  }, [isAutoReload, searchClickCount]);

  //アンマウント時に自動更新機能をOFF
  React.useEffect(() => {
    return () => {
      for(var item of intervalId){
        clearInterval(item);
      }
    }
  }, []);

  const handleToggleisAutoReload = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      ToggleisAutoReload(event.target.checked);
      // loader(reqParam);
    },
    [isAutoReload]
  );

  //#endregion =========================

  //excel DL
  const DownLoadExcel = React.useCallback(
    () => {
      downloadLoader({limit:500});
    },
    [upsDownload]
  );

  React.useEffect(() => {
    if(upsDownload){
      if(upsDownload.filename && upsDownload.binary){
        DownloadExcel({fileName:upsDownload.filename,binary:upsDownload.binary })
      }
    }
  }, [upsDownload]);

  return (
    <React.Fragment>
      <h4 className="p-3 font-wight-bold">
        受信一覧
        <div className="form-check form-check-inline ml-4 small">
          <input
            type="checkbox"
            className="form-check-input"
            id="checkAlertOnly"
            checked={isAutoReload}
            onChange={handleToggleisAutoReload}
          />
          <label className="form-check-label" htmlFor="checkAlertOnly">
            自動更新
          </label>
        </div>
      </h4>
      <div className="container-flex mx-5">
        {/*

        <div className="d-flex flex-column">
          <div className="d-flex">
            <div className="d-flex">
              <label htmlFor="" className="col-form-label-sm search-width1">
                局番
              </label>
              <input
                id="input_stationNum"
                className="form-control form-control-sm input-width1"
                type="text"
                value={stationNum}
                onChange={handleSetStationNum}
              />
            </div>
            <div className="d-flex ml-3">
              <label htmlFor="" className="col-form-label-sm search-width1">
                管理番号
              </label>
              <input
                id="input_ManageNum"
                className="form-control form-control-sm input-width1"
                type="text"
                value={manageNum}
                onChange={handleSetManageNum}
              />
            </div>
            <div className="d-flex ml-3">
              <div className="search-width1"></div>
            </div>
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex">
              <label htmlFor="" className="col-form-label-sm search-width1">
                基地局名
              </label>
              <input
                id="input_stationName"
                className="form-control form-control-sm input-width2"
                type="text"
                value={stationName}
                onChange={handleSetStationName}
              />
            </div>
            <button className="btn btn-sm btn-primary shadow-sm px-3 ml-3 search-width1"
            onClick={handleSearchOnClick}
            >
              検索
            </button>
          </div>
        </div>
              */}
        <div className="mt-4 d-flex justify-content-end">
          {/*
          <div className="d-flex flex-row">
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                className="form-check-input"
                id="checkAlertOnly"
                checked={checkOnlyError}
                onChange={handleToggleCheckOnlyErrror}
              />
              <label className="form-check-label" htmlFor="checkAlertOnly"
              
              >
                状態異常のみ
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="checkbox"
                className="form-check-input"
                id="checkUnreceivedOnly"
                checked={checkOnlyNotReceived}
                onChange={handleToggleCheckOnlyNotReceived}
              />
              <label className="form-check-label" htmlFor="checkUnreceivedOnly">
                未受信のみ
              </label>
            </div>
          </div>
      */}
          <div className="d-flex flex-row">
            <small className="mr-3">全{pageCout(upsList?.totalCount)}件</small>
            <small>
            <button
            className="btn btn-sm btn-success shadow-sm py-0"
            onClick={DownLoadExcel}
            >
            {nowDownload ? "ダウンロード中･･･" :"EXCEL出力"}
            </button>
            </small>
          </div>
        </div>
        <div className="d-flex flex-row">
          {nowListLoading ? (
            "now loading..."
          ) : (
            <TableBind
              upsSummaryChunk={upsList!}
              clickDetail={onClickDetail}
              clickPager={getUpsList}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default UPSMonitor;
