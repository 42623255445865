import * as React from 'react';
import { useUserInfo } from './UserInfoContext';

export interface IRoleFilterProps {
    requirementRoles: string[];
    divElementInsert?: boolean;
    divElementProps?: React.HTMLAttributes<HTMLDivElement>;
}

export const RoleFilter: React.FC<IRoleFilterProps> = (props) => {
    const {requirementRoles, divElementInsert, divElementProps, children} = props;
    const {info: userInfo} = useUserInfo();


    let showChildren: React.ReactNode | undefined = undefined;
    if (requirementRoles.every(r => userInfo.roles.includes(r))) {
        showChildren = children;
    }

    if (divElementInsert) {
        return (
            <div {...divElementProps}>
                {showChildren}
            </div>
        );
    }
    else {
        return (
            <React.Fragment>
                {showChildren}
            </React.Fragment>
        );
    }
}