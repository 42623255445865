import { Buffer } from "buffer";
// import {} from "text-encoding";

export interface fileInfoForExcel {
  fileName: string;
  binary: string;
}

export const DownloadExcel = (fileInfo: fileInfoForExcel) => {
  let binary = Buffer.from(fileInfo.binary, 'base64');
  let file = new File([binary], fileInfo.fileName, {
    type: "application/octet-stream",
  });
  
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.download = fileInfo.fileName;
  a.href = url;
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
};
