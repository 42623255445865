/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface UpdateStationVendorRequest
 */
export interface UpdateStationVendorRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateStationVendorRequest
     */
    vendor: string;
}

export function UpdateStationVendorRequestFromJSON(json: any): UpdateStationVendorRequest {
    return UpdateStationVendorRequestFromJSONTyped(json, false);
}

export function UpdateStationVendorRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateStationVendorRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'vendor': json['vendor'],
    };
}

export function UpdateStationVendorRequestToJSON(value?: UpdateStationVendorRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'vendor': value.vendor,
    };
}


