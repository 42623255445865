/* tslint:disable */
/* eslint-disable */
/**
 * wicky_ups
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../../../Components/api/runtime';
/**
 * 
 * @export
 * @interface UpsStatus
 */
export interface UpsStatus {
    /**
     * 
     * @type {string}
     * @memberof UpsStatus
     */
    statusCode: string;
    /**
     * 
     * @type {string}
     * @memberof UpsStatus
     */
    batteryStatus: string;
    /**
     * 
     * @type {Date}
     * @memberof UpsStatus
     */
    receivedDate: Date;
    /**
     * 
     * @type {boolean}
     * @memberof UpsStatus
     */
    isError?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpsStatus
     */
    passedDays?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpsStatus
     */
    lqi?: string | null;
}

export function UpsStatusFromJSON(json: any): UpsStatus {
    return UpsStatusFromJSONTyped(json, false);
}

export function UpsStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpsStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'statusCode': json['statusCode'],
        'batteryStatus': json['batteryStatus'],
        'receivedDate': (new Date(json['receivedDate'])),
        'isError': !exists(json, 'isError') ? undefined : json['isError'],
        'passedDays': !exists(json, 'passedDays') ? undefined : json['passedDays'],
        'lqi': !exists(json, 'lqi') ? undefined : json['lqi'],
    };
}

export function UpsStatusToJSON(value?: UpsStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'statusCode': value.statusCode,
        'batteryStatus': value.batteryStatus,
        'receivedDate': (value.receivedDate.toISOString()),
        'isError': value.isError,
        'passedDays': value.passedDays,
        'lqi': value.lqi,
    };
}


