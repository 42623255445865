import * as React from 'react';

export interface IPageTitleProps {
    title: string;
    className?: string;
}
export const PageTitle = (props: IPageTitleProps) => (
    <h4 className={`p-3 font-wight-bold ${props.className}`}>
        {props.title}
    </h4>
);