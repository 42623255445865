export class User{

    //データ管理用クラスなので自クラスで1度だけインスタンスして使いまわし
    private static _instance:User;

    public static get getInstance():User
    {
        // インスタンスが存在しない場合、new演算子でインスタンスを作成
        // 引数に渡すのは、Hoge.getInstanceというFunction
        if (!this._instance)
            this._instance = new User();

        // 生成済みのインスタンスを返す
        return this._instance;
    }

    public setUserId(userId:string){
        sessionStorage.setItem("userId", userId??'');
    } 

    public getUserId():string | null {
        return sessionStorage.getItem("userId");
    }

    public setUserName(userName:string){
        sessionStorage.setItem("userName", userName);
    } 

    public getUserName():string | null{
        return sessionStorage.getItem("UserName");
    }

    public deleteUserInfo() {
         sessionStorage.removeItem("userId");
         sessionStorage.removeItem("UserName");
    }

}